import { FC, useState } from 'react';
import Button from 'components/atom/button';
import Textarea from 'components/atom/textarea';
import { checkIcon, closeIcon } from 'images/svg-icons';
import './styles.scss';

const CancelFeedbackModal: FC<{
  handleCancelMyPlanClick: any;
  onClose: any;
  productName: string;
  loading: boolean;
}> = ({ handleCancelMyPlanClick, onClose, productName, loading }) => {
  const problemsPerProduct = {
    Hexomatic: 'scraping a website',
    Hexometer: '',
    Hexofy: 'capturing data from a website',
    Hexowatch: 'monitoring a page',
    Hexospark: '',
    'premium credits': 'using a premium automation',
  };
  const subtitles = {
    Hexomatic:
      'We are sorry to see you go, in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
    Hexometer:
      'We are sorry to see you go; in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
    Hexowatch:
      'We are sorry to see you go; in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
    Hexospark:
      'We are sorry to see you go; in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
    'premium credits':
      'We are sorry to see you cancel your premium credit add-on; in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
    Hexofy:
      'We are sorry to see you go; in order to process your cancellation, we need to ask you a few simple questions to help us improve.',
  };
  const options = [
    {
      //@ts-ignore
      label: `I encountered a problem ${problemsPerProduct[productName]}`,
      value: productName === 'Hexospark' ? '' : '1',
    },
    {
      //@ts-ignore
      label: `I encountered a problem creating my own scraping recipe`,
      value: productName === 'Hexofy' ? '9' : '',
    },
    { label: 'I am missing a feature', value: '2' },
    { label: 'Too complicated', value: '3' },
    { label: `My project has finished, and I no longer need ${productName}`, value: '4' },
    { label: 'Too expensive', value: '5' },
    { label: 'Going with a different provider', value: '6' },
    { label: `${productName} didn’t meet our expectations`, value: productName === 'premium credits' ? '' : '7' },
    { label: 'Other', value: '8' },
  ];
  const questions = [
    'Why have you decided to cancel your subscription?',
    productName === 'premium credits'
      ? 'How could we improve our premium credit add-on and premium automations?'
      : `What could we do to improve?${
          productName === 'Hexomatic'
            ? ' Which websites or automations did you have difficulties with?'
            : productName === 'Hexofy'
            ? ' Which websites did you have difficulties with?'
            : ''
        }`,
  ];

  const [questionOneAnswers, setQuestionOneAnswers] = useState<{ label: string; value: string }[]>([]);
  const [questionOneOtherMessage, setQuestionOneOtherMessage] = useState('');
  const [questionTwoAnswer, setQuestionTwoAnswer] = useState('');

  const handleQuestionOneSelect = (item: { label: string; value: string }) => {
    const existingItem = questionOneAnswers.find(
      (answer: { label: string; value: string }) => answer.value === item.value,
    );
    if (existingItem) {
      setQuestionOneAnswers(
        questionOneAnswers.filter((answer: { label: string; value: string }) => answer.value !== item.value),
      );
    } else {
      setQuestionOneAnswers([...questionOneAnswers, item]);
    }
  };

  const handleCancelClick = () => {
    const questionOne = questionOneAnswers.map((item: any) =>
      item.label === 'Other' ? questionOneOtherMessage : item.label,
    );
    handleCancelMyPlanClick([
      { question: questions[0], answer: questionOne },
      { question: questions[1], answer: questionTwoAnswer },
    ]);
  };

  return (
    <>
      <button className="panel__btn" onClick={onClose} type="button">
        {closeIcon}
      </button>

      <div className="feedback-modal-title">Cancel subscription</div>

      <div className="feedback-modal-subtitle">
        {
          //@ts-ignore
          subtitles[productName]
        }
      </div>

      <div className="px-3">
        <div className="feedback-modal-question">
          <div className="feedback-modal-question-title">{questions[0]}</div>
          <div className="feedback-options">
            {options
              .filter((item: any) => item.value)
              .map(item => (
                <>
                  <div
                    className={`feedback-option ${
                      questionOneAnswers.find((answer: { label: string; value: string }) => answer.label === item.label)
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => handleQuestionOneSelect(item)}
                  >
                    <div className="mr-2">{item.label}</div>
                    <div className="checkmark">{checkIcon}</div>
                  </div>

                  {questionOneAnswers.find((answer: { label: string; value: string }) => answer.label === 'Other') &&
                    item.label === 'Other' && (
                      <Textarea
                        value={questionOneOtherMessage}
                        onChange={(e: any) => {
                          setQuestionOneOtherMessage(e.target.value);
                        }}
                        placeholder="Type your answer here"
                        rows={4}
                        disableResize
                        maxLength={2500}
                      />
                    )}
                </>
              ))}
          </div>
          <div className="feedback-modal-question">
            <div className="feedback-modal-question-title">{questions[1]}</div>
            <Textarea
              value={questionTwoAnswer}
              onChange={(e: any) => {
                setQuestionTwoAnswer(e.target.value);
              }}
              placeholder="Type your answer here"
              rows={4}
              disableResize
              maxLength={2500}
            />
          </div>
        </div>
        <div className="mt-2">
          <Button
            name="Cancel my plan"
            onClick={handleCancelClick}
            className="w-100 justify-content-center"
            disabled={
              !questionOneAnswers.length ||
              (questionOneAnswers.find((item: any) => item.label === 'Other') && !questionOneOtherMessage.trim()) ||
              !questionTwoAnswer.trim() ||
              loading
            }
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default CancelFeedbackModal;
