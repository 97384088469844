import { eventStop } from 'helpers';
import { FC, memo } from 'react';
import Checkbox from '../checkbox';
import { IOption } from './types';

const Option: FC<IOption> = ({
  textDefault,
  textUppercase,
  item,
  value,
  manySelect,
  onChange,
  isEventStop,
  disabled,
  setShowOptions,
}) => {
  const handleClick = (e: any) => {
    if (manySelect) {
      handleCheckboxClick('');
      return;
    }
    setShowOptions(false);
    isEventStop && eventStop(e);
    if (item && value && item.value === value.value) return;
    onChange(item);
  };

  const handleCheckboxClick = (bool: any) => {
    if (!manySelect) return;
    if (typeof bool !== 'boolean') {
      const isChecked = !value.find((el: any) => el.value === item.value);
      if (isChecked) onChange([...value, item]);
      else onChange(value.filter((el: any) => el.value !== item.value));
      return;
    }
    if (bool) onChange([...value, item]);
    else onChange(value.filter((el: any) => el.value !== item.value));
  };

  return (
    <p
      title={item.title || ''}
      className={`option ignore ${textDefault ? 'text-transform-default' : ''} ${
        textUppercase ? 'text-transform-upperCase' : ''
      } ${item.value} ${
        value === item.label ||
        (manySelect && value && value.find((v: any) => v === item.value)) ||
        (value === 'all' && item.value === '') ||
        ((typeof value !== 'string' || typeof value !== 'number') && value && value.value === item.value)
          ? !manySelect
            ? ''
            : 'selected'
          : ''
      } ${disabled ? 'disabled' : ''}`}
      key={item.value}
      onClick={handleClick}
    >
      <span className="option-value d-flex align-items-center ignore">
        {manySelect && (
          <div className="mr-2">
            <Checkbox checked={!!value.find((el: any) => el.value === item.value)} click={handleCheckboxClick} />
          </div>
        )}
        {!item.element ? (
          <div className="text-truncate ignore">
            {item.icon && item.icon} {item.label}
          </div>
        ) : (
          item.element
        )}
      </span>
    </p>
  );
};

export default memo(Option);
