import XRegExp from 'xregexp';

const notAllowedCharacters = ['?', '<', '>', '@', '#', '/', '%', '^', '*'];

export function isValidEmail(mail: string): boolean {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
}

export function isValidPhoneNumber(val: string): boolean {
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(val);
}

export const isValidIP = (IP: string): boolean =>
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i.test(IP);

export function isValidPassword(password: string): boolean {
  return password.length >= 8;
}

export const URL_RE =
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g;
export const DOMAIN_RE =
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const KEYWORD_REGEXP = XRegExp(`^[\\p{L}\\p{N}\\-_\\+\\=\\?\\.\\:\\%\\/\\~\\#\\@\\&\\s]+$`);

export const isValidUrl = (url: string) =>
  !url.includes(' ') &&
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}(\/[\w\-._~:/?#[\]@!$&'()*+,;=%]*)?$/.test(
    url,
  );
  
export const isValidDomain = (domain: string) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );
export const isValidWorkflowName = (name: string) => {
  const invalid = [...name].find((item: string) => notAllowedCharacters.includes(item));
  return !invalid;
};

export const isValidKeyword = (name: string) =>
  XRegExp(`^[\\p{L}\\p{N}\\-_\\+\\,\\=\\?\\.\\:\\%\\/\\~\\#\\@\\&\\)\\(\\s]+$`).test(name);
export const isValidFirstLetter = (str: string) => /^[A-Za-z 0-9]/.test(str);
export const isValidPath = (val: string) => {
  //return val ? !!(!val.indexOf('html') || !val.indexOf('body') || !val.indexOf('div')) : false;
  return val ? true : false;
};
export const getDomain = (url: string) => {
  var prefix = /^https?:\/\//i;
  var domain = /^[^\/:]+/;
  // remove any prefix
  url = url.replace(prefix, '');
  // assume any URL that starts with a / is on the current page's domain
  if (url.charAt(0) === '/') {
    url = window.location.hostname + url;
  }
  // now extract just the domain
  var match = url.match(domain);
  if (match) {
    return match[0].replace('www.', '');
  }
  return null;
};
export const getDomains = (url: string) => {
  var prefix = /^https?:\/\//i;
  var domain = /^[^\/:]+/;
  // remove any prefix
  url = url.replace(prefix, '');
  // assume any URL that starts with a / is on the current page's domain
  if (url.charAt(0) === '/') {
    url = window.location.hostname + url;
  }
  // now extract just the domain
  var match = url.match(domain);
  if (match) {
    return match[0];
  }
  return null;
};
