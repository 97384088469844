import { FC, memo, useContext } from 'react';
import { ThemeContext } from '../../../contexts/theme-context';
import './styles.scss';

interface ILoader {
  size?: string;
  className?: string;
}

const Loader: FC<ILoader> = ({ size, className }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`custom-loader ${className ? className : ''}`}>
      <div className={`loader ${theme} ${size ? size : ''}`}></div>
    </div>
  );
};

export default Loader;
