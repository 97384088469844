import { FC, forwardRef, memo, useCallback } from 'react';
import { eventStop } from '../../../helpers';
import { InputTypes, IRef } from './types';
import { closeIcon } from 'images/svg-icons';
import './style.scss';

const Input: FC<InputTypes> = forwardRef<IRef, InputTypes>(
  (
    {
      type,
      value,
      placeholder,
      disabled,
      required,
      error,
      label,
      className,
      customInputclassName,
      onChange,
      onClick,
      onBlur,
      onFocus,
      onKeyUp,
      onKeyDown,
      onEnterPress,
      id,
      name,
      defaultValue,
      dataUrl,
      noBorders,
      autocomplete,
      maxLength,
      min,
      max,
      autoFocus,
      style,
      leftIcon,
      rightIcon,
      tabIndex,
      isClearable,
      rightIconClick,
      step,
    },
    ref,
  ) => {
    const handleKeyDown = useCallback(
      (e: any) => {
        if (type === 'number' && (e.key === 'e' || e.key === 'E')) eventStop(e);
        onKeyDown && onKeyDown(e);
        e.key === 'Enter' && onEnterPress && onEnterPress(e);
      },
      [onKeyDown, onEnterPress],
    );
    const handleChange = useCallback(
      (e: any) => {
        onChange && onChange(e);
      },
      [onChange],
    );
    const handleClick = useCallback(
      (e: any) => {
        onClick && onClick(e);
      },
      [onClick],
    );
    const handleBlur = useCallback(
      (e: any) => {
        onBlur && onBlur(e);
      },
      [onBlur],
    );
    const handleFocus = useCallback(
      (e: any) => {
        onFocus && onFocus(e);
      },
      [onFocus],
    );
    const handleKeyUp = useCallback(
      (e: any) => {
        onKeyUp && onKeyUp(e);
      },
      [onFocus],
    );
    const handleDeleteText = useCallback(() => {
      onChange && onChange({ target: { value: '' }, deleteAll: true });
    }, [onChange]);

    const handleRightIconClick = useCallback(
      (e: any) => {
        rightIconClick && rightIconClick(e);
      },
      [rightIconClick],
    );
    return (
      <div className={`custom-input ${noBorders ? 'no-borders' : ''} ${customInputclassName ? customInputclassName : ''}`}>
        {label && <label className="label">{label}</label>}
        <div className="input-wrapper">
          {leftIcon && <span className="icon left">{leftIcon}</span>}
          {rightIcon && (
            <span className={`icon right ${rightIconClick ? 'cursor-pointer' : ''}`} onClick={handleRightIconClick}>
              {rightIcon}
            </span>
          )}
          {isClearable && value && (
            <button onClick={handleDeleteText} className="closeIcon icon right">
              {closeIcon}
            </button>
          )}

          <input
            data-url={dataUrl}
            id={id}
            ref={ref ? ref : null}
            type={type ? type : 'text'}
            placeholder={placeholder || ''}
            value={value}
            disabled={!!disabled}
            required={!!required}
            className={`input-field ${error ? 'error-border' : ''} ${leftIcon ? 'left-icon' : ''} ${
              rightIcon ? 'right-icon' : ''
            } ${className || ''} ${isClearable && value ? 'pr-5' : ''}`}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            name={name}
            defaultValue={defaultValue}
            autoComplete={autocomplete ? 'on' : 'off'}
            maxLength={maxLength || 2048}
            min={min}
            max={max}
            autoFocus={autoFocus}
            style={style}
            tabIndex={tabIndex}
            onClick={handleClick}
            step={step}
          />
        </div>
      </div>
    );
  },
);

export default memo(Input);
