import {useContext, useEffect, useState} from 'react';
import axios from 'axios';

import {useNavigate, useParams} from 'react-router-dom';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import {addScope, getScopeById, startScan, updateScope} from '../../../requests';
import {socialOptions} from 'constants/index';
import {scopeCountries} from 'constants/scope-countires/index';
import {ScopesContext} from 'contexts/scopes-context';
import Modal from 'components/molecule/modal';
import {UserContext} from 'contexts/user-context';
import {sanitizeInput} from 'helpers';
import {isValidDomain, isValidEmail, isValidUrl} from 'constants/validations';
import {listOfCategories} from 'constants/categories/index';
import VerificationEmail from 'components/organism/verifcationEmail';

const AddOrEditScope = () => {
  const navigate = useNavigate();
  const {scope_id} = useParams();
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [showStep1, setShowStep1] = useState(false);
  const [type, setType] = useState('business');
  const [categories, setCategories] = useState(listOfCategories);
  const [error, setError] = useState('');
  const [states, setStates] = useState<any>(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeEdit, setUpgradeEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState<any>({
    company_name: '',
    full_name: '',
    website: '',
    main_category: [],
    second_category: '',
    address: '',
    city: '',
    state: null,
    zip_code: '',
    country: scopeCountries[0],
    email: '',
    phone: '',
    social_links: [{label: socialOptions[0], value: ''}],
    tripadvisor_url: '',
  });
  const [loading, setLoading] = useState(false);
  const {scopes, setScopes, selectedScope, setSelectedScope} = useContext(ScopesContext);
  const {hexoscopeUser, user} = useContext(UserContext);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  // useEffect(() => {
  //   const getData = async () => {
  //     const data = await getCategories();
  //     //@ts-ignore
  //     if (data?.data) {
  //       // const categories = data?.data;
  //       // const sortedCategories = categories?.filter((item: any) => item.parent_id === '0') || [];
  //       // const subCategories = categories?.filter((item: any) => item.parent_id !== '0') || [];
  //       // for (let i = 0; i < sortedCategories.length; i++) {
  //       //   const subItems = subCategories?.filter((item: any) => item.parent_id === sortedCategories[i].id);
  //       //   subItems && sortedCategories.splice(i + 1, 0, ...subItems);
  //       // }
  //       //@ts-ignore
  //       console.log(data?.data)
  //        //@ts-ignore
  //       setCategories(data?.data);
  //     }
  //   };
  //   getData();
  // }, []);

  const validateFields = () => {
    const readyToSubmit: any = {};
    formData?.social_links?.map(
      (item: any) => !!item.value.trim() && (readyToSubmit[item.label.value] = sanitizeInput(item.value)?.trim()),
    );
    if (
      !type ||
      (type === 'business'
        ? !sanitizeInput(formData?.company_name).trim()
        : !sanitizeInput(formData?.full_name).trim()) ||
      !sanitizeInput(formData?.website).trim() ||
      !formData?.main_category?.length ||
      !formData.country
    ) {
      setError('Please fill in all the required fields');
      return;
    } else if (!isValidUrl(formData?.website)) {
      setError('Please provide a valid website');
      return;
    } else if (formData?.email?.trim() && !isValidEmail(formData?.email) && !isValidDomain(formData?.email)) {
      setError('Please provide a valid email address');
      return;
    } else if (Object.values(readyToSubmit).find((item: any) => !isValidUrl(item))) {
      setError('Please provide a valid social profile link(s)');
      return;
    }
    setError('');
    setConfirmSubmit(true);
  };

  const handleSubmit = async () => {
    if (!scope_id) {
      if (scopes?.length >= 1 && hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
        setUpgrade(true);
        setConfirmSubmit(false);
        return;
      } else if (
        hexoscopeUser?.hsUser?.pricing_package_count &&
        scopes?.length >= hexoscopeUser?.hsUser?.pricing_package_count
      ) {
        setUpgrade(true);
        setConfirmSubmit(false);
        return;
      }
    } else if (scope_id && hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
      setUpgradeEdit(true);
      setConfirmSubmit(false);
      return;
    }
    setLoading(true);
    const readyToSubmit: any = {};
    formData.social_links.map(
      (item: any) => !!item.value.trim() && (readyToSubmit[item.label.value] = sanitizeInput(item.value)?.trim()),
    );
    const finData = {
      type: type,
      company_name: sanitizeInput(formData?.company_name)?.trim() || null,
      full_name: sanitizeInput(formData?.full_name)?.trim() || null,
      website: sanitizeInput(formData?.website)?.trim() || null,
      main_category: formData?.main_category?.length ? formData.main_category.map((item: any) => item.value) : null,
      second_category: formData?.second_category?.length ? formData.second_category : null,
      address: sanitizeInput(formData?.address)?.trim() || null,
      city: sanitizeInput(formData?.city)?.trim() || null,
      state: sanitizeInput(formData?.state?.label) || null,
      zip_code: sanitizeInput(formData?.zip_code)?.trim() || null,
      country: sanitizeInput(formData?.country?.label) || null,
      email: sanitizeInput(formData?.email)?.trim() || null,
      phone: sanitizeInput(formData?.phone)?.trim() || null,
      social_links: readyToSubmit,
      tripadvisor_url: sanitizeInput(formData.tripadvisor_url)?.trim() || null,
    };

    if (scope_id) {
      const response = await updateScope(finData, scope_id);
      //@ts-ignore
      if (!response?.data?.status && response?.data?.message) {
        //@ts-ignore
        setError(response?.data?.message);
        setLoading(false);
        setConfirmSubmit(false);
        return;
      }
      //@ts-ignore
      if (response?.data?.data) {
        //@ts-ignore
        const startScanResp = await startScan(response.data.data.id);
        //@ts-ignore
        if (startScanResp?.data?.data?.scan_id) {
          //@ts-ignore
          setScopes(scopes.map((item: any) => (scopes.id === response.data.data.id ? response.data.data : item)));
          //@ts-ignore
          setSelectedScope({...response.data.data, last_scan_id: startScanResp.data.data.scan_id});
          //@ts-ignore
          navigate(`/scope/${scope_id}/${startScanResp.data.data.scan_id}`);
        }
      }
    } else {
      const response = await addScope(finData);
      //@ts-ignore
      if (!response?.data?.status && response?.data?.message) {
        //@ts-ignore
        setError(response?.data?.message);
        setLoading(false);
        setConfirmSubmit(false);
        return;
      }
      //@ts-ignore
      if (response?.data?.data?.id) {
        //@ts-ignore
        setScopes([...scopes, response.data.data]);
        //@ts-ignore
        const scanResp = await startScan(response.data.data.id);
        //@ts-ignore
        if (scanResp.data.data.scan_id) {
          //@ts-ignore
          setSelectedScope({...response.data.data, last_scan_id: scanResp.data.data.scan_id});
          //@ts-ignore
          navigate(`/scope/${response.data.data.id}/${scanResp.data.data.scan_id}`);
        }
      }
    }
    setSubmitted(true);
    setLoading(false);
    setConfirmSubmit(false);
  };

  useEffect(() => {
    if (hexoscopeUser?.hsUser?.id && !hexoscopeUser?.hsUser?.business_type) {
      setShowStep1(true);
      setStep(1);
    } else {
      setShowStep1(false);
      setStep(3);
    }
  }, [hexoscopeUser]);

  const closeModal = () => setShowVerificationModal(false);

  useEffect(() => {
    if (scope_id && categories?.length) {
      const getScope = async () => {
        const resp = await getScopeById(scope_id);
        //@ts-ignore
        const scopeData = resp?.data;
        setSelectedScope(scopeData);
        if (scopeData) {
          setType(scopeData.type);
          const socialsEntries = Object.entries(scopeData.social_links);
          const socials = socialsEntries.map((item: any) => {
            return {label: socialOptions.find((sub: any) => sub.value === item[0]), value: item[1]};
          });
          const country = scopeCountries.find((item: any) => item.label === scopeData.country);
          let statesList: any = [];
          if (country) {
            const {data} = await axios({
              method: 'post',
              url: 'https://api.hexoscope.com/v2/ql',
              data: {
                operationName: 'GET_STATE_BY_COUNTRY',
                variables: {
                  settings: {
                    key: country.value,
                  },
                },
                query:
                  'query GET_STATE_BY_COUNTRY($settings: GetStateByCountryInput) {\n  HexosparkUser {\n    getStateByCountry(settings: $settings) {\n      error_code\n      result {\n        label\n        value\n      }\n    }\n  }\n}',
              },
            });
            statesList = data?.data.HexosparkUser?.getStateByCountry?.result || [];
          }
          setStates(statesList);

          const state = statesList.find((item: any) => item.label === scopeData.state) || '';
          const mainCat = categories?.filter((item: any) =>
            scopeData.main_category.find((sub: any) => sub === item.value),
          );
          const secCat = null;
          setFormData({
            company_name: scopeData.company_name || '',
            full_name: scopeData.full_name || '',
            website: scopeData.website || '',
            main_category: mainCat,
            second_category: secCat,
            address: scopeData.address || '',
            city: scopeData.city || '',
            state: state,
            zip_code: scopeData.zip_code || '',
            country: country,
            email: scopeData.email || '',
            phone: scopeData.phone || '',
            social_links: socials,
            tripadvisor_url: scopeData.tripadvisor_url || '',
          });
        }
      };
      getScope();
    }
  }, [scope_id]);

  useEffect(() => {
    if (!scope_id && !submitted) {
      if (scopes?.length >= 1 && hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
        setUpgrade(true);
      } else if (
        hexoscopeUser?.hsUser?.pricing_package_count &&
        scopes?.length >= hexoscopeUser?.hsUser?.pricing_package_count
      ) {
        setUpgrade(true);
      }
    }
  }, [scope_id, scopes, submitted]);

  return (
    <div>
      {step === 1 && showStep1 ? (
        <Step1 setStep={setStep} />
      ) : step === 2 ? (
        <Step2 setStep={setStep} setType={setType} type={type} />
      ) : (
        <Step3
          setStep={setStep}
          categories={categories}
          setFormData={setFormData}
          formData={formData}
          handleSubmit={() => {
            if (user && !user?.verified) {
              setShowVerificationModal(true);
              return
            }
            validateFields();
          }}
          type={type}
          error={error}
          statesList={states}
        />
      )}
      <Modal
        isModalOpen={confirmSubmit && !error}
        text="Before submitting make sure all the field are filled correctly"
        actionBtnText="Submit"
        cancelBtnText="Go back"
        cancelBtnColor="secondary"
        actionBtnColor="warning"
        onActionClick={() => handleSubmit()}
        onCancelClick={() => setConfirmSubmit(false)}
        escButtonClick={() => setConfirmSubmit(false)}
        loading={loading}
      />
      {upgrade || upgradeEdit ? (
        <Modal
          isModalOpen={upgrade || upgradeEdit}
          title={upgradeEdit ? 'Upgrade to enable this feature' : 'Upgrade to add more scopes'}
          text={
            upgradeEdit
              ? 'Upgrade to Standard or above plan to edit the scopes'
              : 'You have reached the maximum limit for adding scopes'
          }
          actionBtnText={'Upgrade'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'warning'}
          cancelBtnColor={'secondary'}
          onActionClick={() => (window.location.href = 'https://hexoscope.com/#pricing')}
          onCancelClick={() => {
            setUpgradeEdit(false);
            setUpgrade(false);
          }}
          escButtonClick={() => {
            setUpgradeEdit(false);
            setUpgrade(false);
          }}
        />
      ) : null}
      {showVerificationModal ? (
        <Modal
          isModalOpen={showVerificationModal}
          actionBtnText=""
          actionBtnColor="transparent"
          text={<VerificationEmail />}
          onCloseModal={closeModal}
          onlyBtns
          className="p-0"
          escButtonClick={closeModal}
        />
      ) : null}
    </div>
  );
};

export default AddOrEditScope;
