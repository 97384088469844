import { UserContext } from 'contexts/user-context';
import { useContext, useState } from 'react';
import Message from 'components/atom/alert';
import Input from 'components/atom/input';
import RadioButton from 'components/atom/radio-button';
import ToggleBtn from 'components/atom/toggle';
import VerifyCode from 'components/molecule/verify-code';
import ModalComponent from 'components/molecule/modal';
import { LOG_OUT_TWO_FACTOR, TWO_FACTOR_APP, TWO_FACTOR_SETTINGS, USER_LOGIN_TWO_FACTOR } from 'graphql/mutations';
import { eyeIcon, lockClosedIcon, lockOpenIcon } from 'images/svg-icons';
import { useEffect } from 'react';
import Loader from 'components/atom/loader';
import { ThemeContext } from 'contexts/theme-context';
import { getDateAndTime, getRawCookie, setBase32 } from 'helpers';
import VerificationEmail from 'components/organism/verifcationEmail';
import { useMutation } from '@apollo/client';

const TwoFactor = () => {
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const [password, setPassword] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState(false);
  const [enableTwoFactorModal, setEnableTwoFactorModal] = useState<boolean>(false);
  const [twofaStatus, setTwofaStatus] = useState(false);
  const [type, setType] = useState(true);
  const [hash, setHash] = useState('');

  const [typePassword, setTypePassword] = useState(false);
  const [codeStep, setCodeStep] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isChange, setIsChange] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [resendStatus, setResendStatus] = useState('');

  //------------------------Query---------------------
  const [logOutTwoFact] = useMutation(LOG_OUT_TWO_FACTOR);

  const [twoFactorSettings, { loading: enableLoading }] = useMutation(TWO_FACTOR_SETTINGS, {
    variables: {
      status: isChange ? true : !twofaStatus,
      type: type ? 'email' : 'app',
      password,
    },
  });
  const [twoFactApp, { loading: tworegloading }] = useMutation(TWO_FACTOR_APP, {
    variables: {
      productType: 'hexoscope',
      password: password || '',
      type: type ? 'email' : 'app',
    },
  });
  const [verifyCode, { loading: verifyLoading }] = useMutation(USER_LOGIN_TWO_FACTOR);
  //------------------UseEffect-------------------
  useEffect(() => {
    setTwofaStatus(user?.twofa?.status || false);
  }, [user]);
  useEffect(() => {
    setCodeError('');
    setPasswordError('');
  }, [enableTwoFactorModal]);

  useEffect(() => {
    resendStatus && setTimeout(() => setResendStatus(''), 5000);
  }, [resendStatus]);

  //------------------Functions------------------

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleChangeTwofactorModal = () => {
    if (user && !user.verified) {
      setShowVerificationModal(true);
      return
    }
    setEnableTwoFactorModal(true);
  };
  const handleChangeTwoFactorType = () => {
    setIsChange(true);
    setType(user?.twofa?.type === 'email');
    setEnableTwoFactorModal(true);
  };
  const handleChangeTwoFactor = async (val?: string) => {
    if (!codeStep && !typePassword) {
      setTypePassword(true);
    }
    //--------disabled--------------------
    if (twofaStatus && !isChange) {
      const res = await twoFactorSettings();
      if (res?.data?.UserOps?.twofaSettings) {
        if (!res.data.UserOps.twofaSettings.error) {
          const res = await logOutTwoFact();
          res?.data?.UserOps?.twofaLogoutFromAllDevices && (window.location.href = 'https://hexoscope.com/#login/');
        } else {
          if (res.data.UserOps.twofaSettings.message === 'INVALID_EMAIL_OR_PASSWORD') {
            setPasswordError('Invalid password');
            setPassword('');
            setTypePassword(true);
          }
        }
      }
      return;
    }
    //-------------send verification code -------------------
    if (codeStep) {
      if (!code && !val) {
        setCodeError('Invalid code');
        return;
      }
      const { data } = await verifyCode({
        variables: {
          code: code || val,
          email: user?.email,
          productType: 'hexoscope',
          base32: getRawCookie('base32'),
        },
      });
      if (data.UserOps.twofaVerify.error) {
        setCodeError(
          data.UserOps.twofaVerify.message === 'INVALID_TWOFA_RESEND' ||
            data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT'
            ? 'You have maxed out your attempts with your code. New generated code has been sent to your email.'
            : 'Invalid code',
        );
        setCode('');
      } else {
        const resp = await twoFactorSettings();
        if (resp?.data?.UserOps?.twofaSettings) {
          if (!resp.data.UserOps.twofaSettings.error) {
            const res = await logOutTwoFact();
            res?.data?.UserOps?.twofaLogoutFromAllDevices && (window.location.href = 'https://hexoscope.com');
            return;
          } else {
            if (resp.data.UserOps.twofaSettings.message === 'INVALID_EMAIL_OR_PASSWORD') {
              setPasswordError('Invalid password');
              setTypePassword(true);
              setPassword('');
            }
          }
        }
      }
      return;
    }
    //---------------
    if (!password || !typePassword) return;
    //------------
    const app = await twoFactApp();
    if (app?.data?.UserOps?.twofaRegister) {
      if (app.data.UserOps.twofaRegister.error) {
        setPasswordError('Invalid password');
        setPassword('');
        setTypePassword(true);
        return;
      }
      !type && setHash(app.data.UserOps.twofaRegister.hash);
      setCodeStep(true);
      setTypePassword(false);
      app.data.UserOps.twofaRegister.base32 && setBase32(app.data.UserOps.twofaRegister.base32);
    }
    return;
  };

  const handleCancelTwoFactor = async () => {
    setPasswordError('');
    if (typePassword && !twofaStatus) {
      setTypePassword(false);
      return;
    }
    setIsChange(false);
    setEnableTwoFactorModal(false);
    setPassword(undefined);
    setPasswordType(false);
    setTypePassword(false);
    setCodeStep(false);
    setType(true);
    setHash('');
  };

  const resendCode = async () => {
    setCodeError('');
    const { data } = await verifyCode({
      variables: {
        code: 'send_to_email',
        email: user?.email,
        productType: 'hexoscope',
      },
    });
    if (data?.UserOps?.twofaVerify) {
      setResendStatus(data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT' ? 'success' : 'error');
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') handleChangeTwoFactor();
  };

  return (
    <>
      <div className="package-info-container mb-4">
        {!user?.id ? (
          <div className="mx-auto my-5">
            <Loader />
          </div>
        ) : (
          <>
            <div className="plans-header">
              <h5 className="plans-title">2-FACTOR AUTHENTICATION</h5>
            </div>
            <div className="plans-body two-factor">
              <div className="d-flex justify-content-between mb-4">
                <div className="title">
                  2-Factor Authentication is
                  {user?.twofa?.status ? ' ON ' + 'since ' + getDateAndTime(user.twofa.date) : ' OFF'}
                </div>
                <ToggleBtn
                  className={`wizard-toggle-text`}
                  onChange={handleChangeTwofactorModal}
                  checked={
                    isChange
                      ? twofaStatus
                      : (enableTwoFactorModal && !twofaStatus) || (!enableTwoFactorModal && twofaStatus)
                  }
                  tooltipText={`Click to ${user?.twofa?.status ? 'disable' : 'enable'} 2-Factor Authentication`}
                />
              </div>
              {user?.twofa?.status && (
                <div className="d-flex justify-content-between">
                  <div className="title">Authentication method</div>
                  <div className="info">{user?.twofa?.type === 'email' ? 'Email' : 'Google Authentication app'}</div>
                </div>
              )}
            </div>
            <div className={`plans-footer justify-content-end`}>
              <span
                className={`action-link cursor-pointer ${user?.twofa?.status ? 'visible' : 'invisible'}`}
                onClick={handleChangeTwoFactorType}
              >
                Change method
              </span>
            </div>
          </>
        )}
      </div>
      <ModalComponent
        isModalOpen={enableTwoFactorModal}
        text={
          <>
            {hash ? (
              <>
                <img src={hash}></img>
                <div className="info">
                  To enable two-factor authentication, you will need the Google Authenticator app. Once you have
                  installed the app, scan the QR code below into the app for iOS or Android.
                </div>
                <>
                  <h3 className="text-center">Enter verification code</h3>
                  <VerifyCode
                    length={6}
                    setCode={setCode}
                    setError={setCodeError}
                    error={!!codeError}
                    action={handleChangeTwoFactor}
                  />
                  <div className="text-center">
                    Please check and enter the six-digit verification code from the Google Authenticator app into the
                    field to access your account.
                  </div>
                  <div className="mt-1" style={{ fontSize: '12px' }}>
                    You will be signed out from all devices
                  </div>
                  <div className={`text-center ${codeError ? 'visible' : 'invisible'}`}>
                    <Message text={codeError} type='danger' />
                  </div>
                </>
              </>
            ) : typePassword || (twofaStatus && !isChange) ? (
              <>
                <div className="position-relative">
                  <div className="mb-2">
                    {twofaStatus && !isChange
                      ? 'You will no longer receive a verification code on login'
                      : 'Enter password to continue'}
                  </div>

                  <Input
                    type={!passwordType ? 'password' : 'text'}
                    value={password}
                    onChange={handleChangePassword}
                    error={password !== undefined && !password && passwordError ? 'error' : ''}
                    placeholder="password"
                    className="pr-4"
                    autocomplete={false}
                    onKeyUp={handleKeyUp}
                  />
                  <span
                    className={`eye-icon position-absolute ${passwordType ? 'active' : ''}`}
                    onClick={() => setPasswordType(!passwordType)}
                  >
                    {eyeIcon}
                  </span>
                </div>
                {twofaStatus && (
                  <div className="mt-1" style={{ fontSize: '12px' }}>
                    You will be signed out from all devices
                  </div>
                )}
                <Message
                  type="danger"
                  text={passwordError}
                  className={`px-2 mt-1 ${passwordError ? 'visible' : 'invisible'}`}
                />
              </>
            ) : codeStep ? (
              <>
                <h3 className="text-center">Enter verification code</h3>
                <VerifyCode
                  length={6}
                  setCode={setCode}
                  setError={setCodeError}
                  error={!!codeError}
                  action={handleChangeTwoFactor}
                />
                <div className="text-center">
                  A code has been sent to your {type ? 'email' : 'Google Authentication app'}, please check and enter
                  the six-digit verification code into the field to access your account.
                </div>
                <div className="mt-1" style={{ fontSize: '12px' }}>
                  You will be signed out from all devices
                </div>
                <div className={`text-center ${codeError ? 'visible' : 'invisible'}`}>
                  <Message text={codeError} type='danger' />
                </div>
              </>
            ) : (
              <>
                <span className="bold">Select an authentication method</span>
                {(!twofaStatus || isChange) && (
                  <div className="d-flex justify-content-center two-factor-type pt-3 pb-5">
                    <RadioButton enabled={type} onChange={() => setType(true)} label="Email" />
                    <RadioButton enabled={!type} onChange={() => setType(false)} label="Google Authentication" />
                  </div>
                )}
              </>
            )}
          </>
        }
        icon={codeStep ? (code.length === 6 && !codeError ? lockOpenIcon : lockClosedIcon) : null}
        actionBtnText={twofaStatus ? 'Confirm' : 'Continue'}
        cancelBtnText={typePassword && !hash && !twofaStatus ? 'BACK' : 'CANCEL'}
        actionBtnColor={(!twofaStatus || isChange) && !typePassword ? 'warning' : 'danger'}
        cancelBtnColor={'danger'}
        onActionClick={handleChangeTwoFactor}
        onCancelClick={handleCancelTwoFactor}
        loading={enableLoading || tworegloading || verifyLoading}
        parentClass={`${hash ? 'qr-code-modal' : 'pass-modal'}`}
        escButtonClick={handleCancelTwoFactor}
      >
        {codeStep && type && !enableLoading && !tworegloading && !verifyLoading && (
          <>
            <div id="resend-code-text" className="clickable-text text-center" onClick={resendCode}>
              Resend Code
            </div>
            <div
              className={`clickable-text text-center pt-1 f_w_500 ${resendStatus ? 'visible' : 'invisible'} ${resendStatus === 'success' ? 'text-success' : 'text-danger'
                }`}
            >
              {resendStatus === 'success' ? 'Successfully sent' : 'Something went wrong'}
            </div>
          </>
        )}
      </ModalComponent>
      <ModalComponent
        isModalOpen={showVerificationModal}
        actionBtnText=""
        onActionClick={() => { }}
        actionBtnColor="transparent"
        text={<VerificationEmail />}
        onCloseModal={() => setShowVerificationModal(false)}
        onlyBtns
        className="p-0"
        escButtonClick={() => setShowVerificationModal(false)}
      />
    </>
  );
};
export default TwoFactor;
