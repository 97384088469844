import {FC, useContext, useReducer, useState} from 'react';
import {useMutation} from '@apollo/client';
import {UPDATE_BUSINESS_TYPE} from 'graphql/mutations';
import {UserContext} from 'contexts/user-context';
import Button from 'components/atom/button';
import Modal from 'components/molecule/modal';
import {ownBusinessIcon, agencyIcon} from 'images/svg-icons';
import '../styles.scss';

const Step1: FC<{setStep: any}> = ({setStep}) => {
  const [type, setType] = useState(null as any);
  const [isSuccessfullySent, setIsSuccessfullySent] = useReducer((val: boolean) => !val, false);
  const [updateBusinessType, {loading: updateLoading}] = useMutation(UPDATE_BUSINESS_TYPE);
  const {hexoscopeUser, setHexoscopeUser} = useContext(UserContext);

  const types = [
    {
      name: 'For my own business',
      value: 'business',
      icon: ownBusinessIcon,
    },
    {
      name: 'Primarily to serve other clients (agency account)',
      value: 'agency',
      icon: agencyIcon,
    },
  ];

  const handleSubmit = async () => {
    const {data} = await updateBusinessType({
      variables: {
        settings: {
          business_type: type,
        },
      },
    });
    if (data?.HexoscopeUserOps?.updateHexoscopeType) {
      setIsSuccessfullySent();
    }
  };

  const handleModalAction = () => {
    setIsSuccessfullySent();
    if (hexoscopeUser?.hsUser?.id) {
      const updatedHsUser = {...hexoscopeUser, hsUser: {...hexoscopeUser?.hsUser, business_type: type}};
      setHexoscopeUser(updatedHsUser);
    }
    setIsSuccessfullySent();
    setStep(3);
  };

  return (
    <div className="create-scope-container step-1">
      <div className="create-scope-inner-box">
        <div className="scope-step-title sm">
          Will this account be used for your own business, or do you plan to primarily use it to serve other clients as
          an agency account?
        </div>
        <div className="d-block d-md-flex">
          {types.map(item => (
            <div
              className={`scope-type sm ${item.value === type ? 'active' : ''}`}
              onClick={() => {
                setType(item.value);
              }}
            >
              <div className="scope-icon">{item.icon}</div>
              <div className="scope-title sm">{item.name}</div>
            </div>
          ))}
        </div>
        <div className="mx-4 d-flex justify-content-end">
          <Button name="Submit" onClick={handleSubmit} disabled={!type} loading={updateLoading} />
        </div>
      </div>
      {isSuccessfullySent && (
        <Modal
          isModalOpen={isSuccessfullySent}
          text={
            <>
              <p>
                Your response has been successfully sent. <p>You can now move forward with creating a scope.</p>
              </p>
            </>
          }
          actionBtnText="OK"
          actionBtnColor="success"
          cancelBtnColor="primary"
          onActionClick={handleModalAction}
          escButtonClick={handleModalAction}
        />
      )}
    </div>
  );
};

export default Step1;
