import { FC, memo, useCallback, useEffect, useState } from 'react';
import { checkedCircleIcon, removeCircleIcon } from 'images/svg-icons';
import Loader from '../../atom/loader';
import './styles.scss';

interface IProps {
  errorMessage?: string;
  loadingMessage?: string;
  successMessage?: string;
  setToasterInfo: (val: any) => void;
}

const ToastLoader: FC<IProps> = ({ errorMessage, loadingMessage, successMessage, setToasterInfo }) => {
  //!-------------------State---------------------
  const [hideElement, setHide] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  //!-----------------useEffects------------------

  useEffect(() => {
    if (errorMessage || successMessage) hide();
  }, [errorMessage, successMessage]);

  useEffect(() => {
    if (hideElement) setToasterInfo({ show: false });
  }, [hideElement]);
  //!------------------Function--------------------
  const hide = useCallback(() => {
    setTimer(clearInterval(timer));
    setTimer(
      setTimeout(() => {
        setHide(true);
      }, 7000),
    );
    return () => {
      setTimer(clearInterval(timer));
    };
  }, []);
  //!-----------------------------------------------
  return (
    <div
      className={`toast-loader position-fixed alert alert-${
        loadingMessage ? 'secondary' : successMessage ? 'success' : errorMessage ? 'danger' : ''
      }`}
      role="alert"
    >
      <span className="pr-2">
        {loadingMessage ? <Loader /> : errorMessage ? removeCircleIcon : successMessage ? checkedCircleIcon : ''}
      </span>
      {loadingMessage || successMessage || errorMessage}
    </div>
  );
};

export default memo(ToastLoader);
