import Button from 'components/atom/button';
import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Input from 'components/atom/input';
import Select from 'components/atom/select';
import {scopeCountries} from 'constants/scope-countires';
import {useLazyQuery} from '@apollo/client';
import {GET_STATE_BY_COUNTRY} from 'graphql/queries';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import MultiSelect from 'components/molecule/multiselect';
import SocialsInput from './socials-input';
import {socialOptions} from 'constants/index';
import {isValidDomain, isValidEmail, isValidUrl} from 'constants/validations';
import OptimizedSelect from 'components/molecule/optimized-select';
import '../styles.scss';

const Step3: FC<{
  setStep: any;
  categories: any;
  formData: any;
  setFormData: any;
  handleSubmit: any;
  type: string;
  error: string;
  statesList: any;
}> = ({setStep, formData, categories, setFormData, handleSubmit, type, error, statesList}) => {
  const {scope_id} = useParams();
  const [states, setStates] = useState<any>(statesList);
  const [loading, setLoading] = useState(!!scope_id);
  // const [getStateByCountry] = useLazyQuery(GET_STATE_BY_COUNTRY, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted(statsData) {
  //     if (statsData?.HexosparkUser?.getStateByCountry?.result) {
  //       setStates(statsData.HexosparkUser.getStateByCountry.result);
  //     }
  //   },
  // });

  const handleInputChange = (e: any) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleChangeCountry = (val: any) => {
    if (val.label) {
      setFormData({...formData, country: val.value ? val : '', state: ''});

      // if (val.value) {
      //   getStateByCountry({
      //     variables: {
      //       settings: {
      //         key: val.value,
      //       },
      //     },
      //   });
      // } else {
      //   setStates([]);
      // }
    }
  };

  // const handleChangeState = (val: any) => {
  //   if (val.label) {
  //     setFormData({...formData, state: val.value ? val : ''});
  //   }
  // };

  // const handleChangePhone = (val: any) => {
  //   setFormData({...formData, phone: val});
  // };

  const handleChangeCategory = (val: any) => {
    setFormData({...formData, main_category: [val]});
  };

  // const handleAddSocial = () => {
  //   setFormData({...formData, social_links: [...formData.social_links, {label: socialOptions[0], value: ''}]});
  // };

  // const handleRemoveSocial = (ind: number) => {
  //   setFormData({...formData, social_links: formData.social_links.filter((item: any, index: number) => ind !== index)});
  // };

  // const handleSocialChangeSelect = (val: any, ind: number) => {
  //   const updatedSocials = formData.social_links.map((item: any, index: number) => {
  //     if (index === ind) {
  //       return {...item, label: val};
  //     } else {
  //       return item;
  //     }
  //   });
  //   setFormData({...formData, social_links: updatedSocials});
  // };

  // const handleSocialInputChange = (val: any, ind: number) => {
  //   const updatedSocials = formData.social_links.map((item: any, index: number) => {
  //     if (index === ind) {
  //       return {...item, value: val};
  //     } else {
  //       return item;
  //     }
  //   });
  //   setFormData({...formData, social_links: updatedSocials});
  // };

  useEffect(() => {
    if (scope_id && !(formData?.company_name || formData?.full_name)) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [scope_id, formData]);

  return (
    <div className="create-scope-container mb-4">
      <div className="create-scope-inner-box">
        <div className={`scope-step-title ${loading ? 'loading' : ''}`}>Fill in scope details</div>
        <form>
          <div className="form-group m-4">
            {loading ? (
              <div className="mb-2">
                <div className="form-data-banner-label"></div>
                <div className="form-data-banner"></div>
              </div>
            ) : (
              <Input
                type="text"
                name={type === 'business' ? 'company_name' : 'full_name'}
                value={type === 'business' ? formData.company_name : formData.full_name}
                placeholder={type === 'business' ? 'e.g., Tech Solutions Inc.' : 'e.g., John Smith'}
                required
                error={error && !(type === 'business' ? formData.company_name.trim() : formData.full_name.trim())}
                label={type === 'business' ? 'Company Name*' : 'Full Name*'}
                onChange={handleInputChange}
                customInputclassName="mb-2"
                maxLength={2000}
              />
            )}
            {loading ? (
              <div className="mb-2">
                <div className="form-data-banner-label"></div>
                <div className="form-data-banner"></div>
              </div>
            ) : (
              <Input
                type="website"
                id="website"
                name="website"
                value={formData.website}
                placeholder={type === 'business' ? 'e.g., https://techsolutions.com' : 'e.g., https://johnsmith.com'}
                required
                error={error && !isValidUrl(formData.website)}
                label="Website*"
                onChange={handleInputChange}
                customInputclassName="mb-2"
                maxLength={2000}
              />
            )}
            {/* <div className="mb-2">
              <label className="label select-label">Category*</label>
              <MultiSelect
                options={categories}
                onChange={handleChangeCategory}
                selectedCategories={formData.main_category}
                danger={error && !formData?.main_category?.length}
              />
            </div> */}
            {loading ? (
              <div className="mb-2">
                <div className="form-data-banner-label"></div>
                <div className="form-data-banner"></div>
              </div>
            ) : (
              <OptimizedSelect
                label="Category*"
                placeholder="Category"
                name={'category'}
                value={formData.main_category[0]}
                options={categories}
                onChange={handleChangeCategory}
                w100
                searchable
                bottom
                isEventStop
                // addNoSelectOption
                selectWrapperClassName="mb-2 mr-md-2"
                danger={!!error && !formData?.main_category[0]}
              />
            )}
            {loading ? (
              <div className="mb-2">
                <div className="form-data-banner-label"></div>
                <div className="form-data-banner"></div>
              </div>
            ) : (
              <Select
                label="Country*"
                placeholder="Country"
                name={'country'}
                value={formData.country}
                options={scopeCountries}
                onChange={handleChangeCountry}
                w100
                searchable
                bottom
                isEventStop
                // addNoSelectOption
                selectWrapperClassName="mb-2 mr-md-2"
                danger={!!error && !formData?.country}
              />
            )}
            {loading ? (
              <div>
                <div className="form-data-banner-label"></div>
                <div className="form-data-banner"></div>
              </div>
            ) : (
              <Input
                type="text"
                name="tripadvisor_url"
                value={formData.tripadvisor_url}
                placeholder="https://www.tripadvisor.com/Attraction_Review..."
                error={error && formData.tripadvisor_url && !isValidUrl(formData.tripadvisor_url)}
                label="Tripadvisor Review URL"
                onChange={handleInputChange}
                customInputclassName="mb-2"
                maxLength={2000}
              />
            )}
            {/* <Input
              type="text"
              name="address"
              value={formData.address}
              placeholder="1234 Main St"
              error={false}
              label="Address"
              onChange={handleInputChange}
              customInputclassName="mb-2"
              maxLength={2000}
            />
            <div className="d-block d-md-flex">
              <Select
                label="Country*"
                placeholder="Country"
                name={'country'}
                value={formData.country}
                options={scopeCountries}
                onChange={handleChangeCountry}
                w100
                searchable
                bottom
                isEventStop
                addNoSelectOption
                selectWrapperClassName="mb-2 mr-md-2"
                danger={!!error && !formData?.country}
              />
              <Select
                label="State"
                placeholder="State"
                name="state"
                disabled={!states?.length}
                value={formData.state}
                options={states || []}
                onChange={handleChangeState}
                w100
                searchable
                bottom
                isEventStop
                addNoSelectOption
                selectWrapperClassName="mb-2"
              />
            </div>
            <div className="d-block d-md-flex">
              <Input
                type="text"
                name="city"
                value={formData.city}
                placeholder="City"
                error={false}
                label="City"
                onChange={handleInputChange}
                customInputclassName="mb-2 mr-md-2"
                maxLength={2000}
              />
              <Input
                type="text"
                name="zip_code"
                value={formData.zip_code}
                placeholder="Zip Code"
                error={false}
                label="Zip Code"
                onChange={handleInputChange}
                customInputclassName="mb-2"
                maxLength={100}
              />
            </div>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              label="Email"
              onChange={handleInputChange}
              customInputclassName="mb-2"
              maxLength={254}
              error={error && !!formData.email.trim() && !isValidDomain(formData.email) && !isValidEmail(formData.email)}
            />
            <div className="w-100 mb-2">
              <label className="label select-label">Phone Number</label>
              <ReactPhoneInput country="us" value={formData.phone} onChange={handleChangePhone} />
            </div>
            <div className="w-100">
              <label className="label select-label">Social profile</label>
              <SocialsInput
                socials={formData.social_links}
                handleAddSocial={handleAddSocial}
                handleChangeSelect={handleSocialChangeSelect}
                handleInputChange={handleSocialInputChange}
                handleRemoveSocial={handleRemoveSocial}
                error={error}
              />
            </div> */}
          </div>
        </form>
        {error ? <div className="alert alert-danger p-2 mx-4 mb-4">{error}</div> : null}
        <div className="mx-4 d-flex justify-content-end">
          {/* <Button name="Back" onClick={() => setStep(2)} outline /> */}
          {loading ? (
            <div className="form-data-banner"></div>
          ) : (
            <Button name="Submit" onClick={handleSubmit} className="w-100" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Step3;
