import {FC} from 'react';
import { businessIcon, personIcon } from 'images/svg-icons';
import '../styles.scss';

const Step2: FC<{setStep: any; type: string; setType: any}> = ({setStep, type, setType}) => {
  const types = [
    {
      name: 'Business',
      value: 'business',
      icon: businessIcon,
    },
    {
      name: 'Person',
      value: 'personal',
      icon: personIcon,
    },
  ];
  return (
    <div className="create-scope-container step-1">
      <div className="create-scope-inner-box">
        <div className='scope-step-title'>Select Business or Person?</div>
        <div className="d-block d-md-flex">
          {types.map(item => (
            <div className={`scope-type ${item.value === type ? 'active' : ''}`} onClick={() => {setType(item.value); setStep(3)}}>
              <div className='scope-icon'>{item.icon}</div>
              <div className="scope-title">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step2;
