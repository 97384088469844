import { nothingToShow, reloadIcon } from 'images/svg-icons';
import { FC, memo } from 'react';
import './styles.scss';

interface INothingToShow {
  text?: string;
  noIcon?: boolean;
  reload?: boolean;
  onReload?: any;
  className?: string;
}

const NothingToShow: FC<INothingToShow> = ({ text, noIcon, reload, onReload, className }) => (
  <div className={`with-center ${className ? className : ''}`}>
    {!noIcon && <span className='no-result mb-2'>{nothingToShow}</span>}
    {!noIcon && <span className="empty">Nothing to show!</span>}
    <span className="empty-list">{text || 'Your list is empty.'}</span>
    {reload && onReload ? <span className='clickable-text mt-2' onClick={onReload}>{reloadIcon} Reload</span> : null}
  </div>
);

export default memo(NothingToShow);
