import {ScopesContext} from 'contexts/scopes-context';
import {useContext, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {useNavigate, useParams} from 'react-router-dom';
import {getScanById, getScopeById} from 'requests';
import ResultCard from './components/result-card';
import Tooltip from 'components/molecule/tooltip';

import {reportResultIcon, reviewResultIcon, searchResultIcon, backlinkResultIcon} from 'images/svg-icons';
import {getDate, getDateAndTime, getDatePlusWeek} from 'helpers';
import {editIconScope, historyIcon} from 'images/svg-icons';
import './styles.scss';

const Scope = () => {
  const {scope_id, scan_id} = useParams();
  const {scopes, selectedScope, setSelectedScope} = useContext(ScopesContext);
  const navigate = useNavigate();
  const [result, setResult] = useState(null as any);
  const [firstScan, setFirstScan] = useState(false);
  const [lastScanIdDate, setLastScanIdDate] = useState('');
  const [tab, setTab] = useState<1 | 2 | 3 | 4>(1);
  const isDesktop = useMediaQuery({minWidth: 1100});
  const [reviewTab, setReviewTab] = useState<1 | 2 | 3>(1);
  const [disabledTabs, setDisabledTabs] = useState<any>([]);

  useEffect(() => {
    const getData = async () => {
      const resp = await getScanById(selectedScope?.last_scan_id);
      //@ts-ignore
      const data = resp?.data;

      if (data?.created_at) {
        setLastScanIdDate(data.created_at);
      }
    };

    if (selectedScope?.last_scan_id) {
      getData();
    }
  }, [selectedScope]);

  useEffect(() => {
    const getData = async () => {
      const resp = await getScanById(scan_id);
      //@ts-ignore
      const data = resp?.data;

      if (data) {
        setResult(data);
        const disabled = [];
        if (!data?.google_review_data) {
          disabled.push(1);
        }
        if (!data?.trustpilot_data) {
          disabled.push(2);
        }
        if (!data?.tripadvisor_data) {
          disabled.push(3);
        }
        setDisabledTabs(disabled);
      }
      if (data && data?.status !== 'completed') {
        setFirstScan(true);
      }
      if (data?.status === 'completed') {
        setFirstScan(false);
        clearInterval(interval);
      }
    };
    const interval = setInterval(async () => {
      await getData();
    }, 10000);

    if (scope_id && scan_id) {
      getData();
    }
    return () => clearInterval(interval);
  }, [scan_id, scope_id]);

  useEffect(() => {
    const getData = async (id: any) => {
      const resp = await getScopeById(id);
      //@ts-ignore
      if (resp?.data?.message === 'User not found' || resp?.data?.message === 'Error') {
        navigate('/scope/create');
        //@ts-ignore
      } else if (resp?.data) {
        //@ts-ignore
        setSelectedScope(resp?.data);
      }
    };
    if (scope_id && scopes?.find((item: any) => item.id === scope_id)) {
      getData(scope_id);
    } else if (scope_id && !scopes?.find((item: any) => item.id === scope_id) && scopes && scopes[0]) {
      navigate(`/scope/${scopes[0].id}/${scopes[0].last_scan_id}`);
    } else if (scope_id && scopes && !scopes?.length) {
      navigate('/scope/create');
    }
  }, [scope_id, scopes]);

  return (
    <div>
      <div className="d-md-flex justify-content-between d-block">
        <div className="page-title d-flex">
          <div className="my-auto">{selectedScope?.full_name || selectedScope?.company_name}</div>{' '}
          <div
            className="ml-2 my-auto d-flex clickable-text-with-icon"
            onClick={() => navigate(`/scope/${scope_id}/edit`)}
          >
            {editIconScope}
          </div>
          <div
            className="ml-2 my-auto d-flex clickable-text-with-icon"
            onClick={() => navigate(`/scope/${scope_id}/history`)}
          >
            <div className="my-auto mr-1">{historyIcon}</div>{' '}
            <div className="my-auto" style={{lineHeight: '20px'}}>
              History
            </div>
          </div>
        </div>
        <div className="secondary-color my-auto d-flex flex-wrap">
          {selectedScope?.next_scan_date ? (
            <div className="ml-2 secondary-color my-auto mr-2">
              Gathering data. Next analysis and summary: {getDate(selectedScope.next_scan_date)}
            </div>
          ) : null}
          <div className="ml-2">Report generated: {getDateAndTime(result?.created_at)}</div>
        </div>
      </div>
      <div className="result-tabs">
        <div className={`result-tab-container ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
          <Tooltip
            text="Provides an analysis by comparing data with other businesses in the same category on Google Business."
            placement={isDesktop ? 'bottom' : 'right'}
            trigger={['hover']}
            isSmall
            className="m-auto"
          >
            <div className="d-flex">
              <div className="tab-icon">{reportResultIcon}</div>
              <div className="tab-title">General Summary (beta)</div>
            </div>
          </Tooltip>
        </div>

        {result?.google_review_data === 'upgrade_plan' ? null : (
          <div className={`result-tab-container ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>
            <Tooltip
              text="Displays a summary and insights of customer reviews. You can also download the raw data of reviews in CSV format."
              placement={isDesktop ? 'bottom' : 'right'}
              trigger={['hover']}
              isSmall
              className="m-auto"
            >
              <div className="d-flex">
                <div className="tab-icon">{reviewResultIcon}</div>
                <div className="tab-title">Customer Reviews</div>
              </div>
            </Tooltip>
          </div>
        )}
        <div className={`result-tab-container ${tab === 3 ? 'active' : ''}`} onClick={() => setTab(3)}>
          <Tooltip
            text="Displays a summary and insights from Google search results for the business name. You can also download the raw data of results in CSV format."
            placement="bottom"
            trigger={['hover']}
            isSmall
            className="m-auto"
          >
            <div className="d-flex">
              <div className="tab-icon">{searchResultIcon}</div>
              <div className="tab-title">Google Search</div>
            </div>
          </Tooltip>
        </div>

        <div className={`result-tab-container ${tab === 4 ? 'active' : ''}`} onClick={() => setTab(4)}>
          <Tooltip
            text="Shows a summary of all found backlinks. You can also download the raw data in CSV format."
            placement={isDesktop ? 'bottom' : 'left'}
            trigger={['hover']}
            isSmall
            className="m-auto"
          >
            <div className="d-flex">
              <div className="tab-icon">{backlinkResultIcon}</div>
              <div className="tab-title">Backlinks</div>
            </div>
          </Tooltip>
        </div>
      </div>
      {tab === 1 ? (
        <ResultCard
          icon={reportResultIcon}
          title="General Summary (beta)"
          result={result?.scrape_data}
          status={result?.status}
          firstScan={firstScan}
          module="scrape"
          csvName="general-summary"
        />
      ) : null}
      {result?.google_review_data === 'upgrade_plan' || tab !== 2 ? null : (
        <ResultCard
          icon={reviewResultIcon}
          title="Customer Reviews"
          result={
            reviewTab === 1
              ? result?.google_review_data
              : reviewTab === 2
              ? result?.trustpilot_data
              : result?.tripadvisor_data
          }
          status={result?.status}
          firstScan={firstScan}
          module={reviewTab === 1 ? 'google_review' : reviewTab === 2 ? 'trustpilot' : 'tripadvisor'}
          csvName={reviewTab === 1 ? 'google-reviews' : reviewTab === 2 ? 'trustpilot-reviews' : 'tripadvisor-reviews'}
          reviewTab={reviewTab}
          setReviewTab={setReviewTab}
          disabledTabs={disabledTabs}
        />
      )}
      {/* {result?.trustpilot_data === 'upgrade_plan' || tab !== 3 ? null : (
        <ResultCard
          icon={trustpilotReviewResultIcon}
          title="Trustpilot Reviews"
          result={result?.trustpilot_data}
          status={result?.status}
          firstScan={firstScan}
          module="trustpilot"
          csvName="trustpilot-reviews"
        />
      )} */}
      {tab === 3 ? (
        <ResultCard
          icon={searchResultIcon}
          title="Google Search"
          result={result?.google_name_data}
          status={result?.status}
          firstScan={firstScan}
          module="google_search"
          csvName="google-search"
        />
      ) : null}
      {tab === 4 ? (
        <ResultCard
          icon={backlinkResultIcon}
          title="Backlinks"
          result={result?.backlink_data}
          status={result?.status}
          firstScan={firstScan}
          module="back_link"
          csvName="backlinks"
        />
      ) : null}
    </div>
  );
};

export default Scope;
