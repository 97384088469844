import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tooltip from 'components/molecule/tooltip';
import Modal from 'components/molecule/modal';
import { ThemeContext } from '../../contexts/theme-context';
import HeaderAlert from './header-alert';
import ModeSwitcher from './mode-switcher';
import ProfilesList from './profiles-list';
import ProductsList from './products-list';
import { IHeaderProps } from '../types';
import { useMutation } from '@apollo/client';
import VerificationEmail from 'components/organism/verifcationEmail';

import { burgerButton, questionIcon } from 'images/svg-icons';
import HexoscopeLogoRect from 'images/logos/hexoscope-logo-rectangle.svg';
import HexoscopeLogoRectWhite from 'images/logos/hexoscope-logo-rectangle-white.svg';
import HexoscopeLogoIcon from 'images/logos/hexoscope-icon.svg';

import { PAYPAL_UPGRADE } from 'graphql/mutations';
import '../layout.scss';
import ScopesList from 'components/scopes-list';

const Header: FC<IHeaderProps> = ({ toggle, setToggle, user, logo }, props) => {
  //!-----------------Params----------------
  const { pathname } = useLocation();
  //!----------------Context----------------
  const { theme } = useContext(ThemeContext);
  //!-----------------State-----------------
  //Boolean
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  //String
  const [userPhoto, setUserPhoto] = useState('');

  const [paypalToken] = useMutation(PAYPAL_UPGRADE);

  //!-----------------useEffect-------------

  const sendToken = useCallback(
    async (token: string, productType: string) => {
      const { data } = await paypalToken({ variables: { settings: { baToken: token, productType } } });
      if (
        data &&
        data.BillingOps &&
        data.BillingOps.executePaypalPostAction &&
        !data.BillingOps.executePaypalPostAction.error_code
      ) {
        window.close();
      }
    },
    [paypalToken],
  );

  useEffect(() => {
    if (window.location.href.includes('&ba_token=')) {
      const token = window.location.href.match('(?<=&ba_token=)(.*)(?=&token=)');
      token &&
        token[0] &&
        sendToken(token[0], window.location.search?.includes('premium_credit') ? 'premium_credits' : 'hexospark');
    }
  }, []);

  useEffect(() => {
    if (user?.photo) {
      setUserPhoto(user?.photo);
    }
  }, [user]);

  //!---------------Function-----------------

  const handleChangeToggleOnBlur = () => setTimeout(() => setToggle(false), 0);

  const handleChangeToggle = useCallback(() => {
    // window && window.innerWidth < 1025 && setToggle((prev: boolean) => !prev);
  }, [setToggle, window.innerWidth]);

  const closeModal = () => setShowVerificationModal(false);

  return (
    <>
      <header id="header" className={`header ${toggle ? 'header-pd' : ''} ${logo ? 'with-logo' : ''}`}>
        <HeaderAlert />
        <div className="main-header">
          <Link to="/" onClick={handleChangeToggle} className="logo-wrapper">
              <img src={theme === 'light' ? HexoscopeLogoRect : HexoscopeLogoRectWhite} alt="Hexoscope Logo" />
          </Link>
          {(pathname.includes('/profile') || pathname.includes('/create')) && (
            <Link to="/" onClick={handleChangeToggle} className="logo-wrapper-sm">
              <span className="d-flex">
                <img src={HexoscopeLogoIcon} alt="Hexoscope Logo" />
              </span>
            </Link>
          )}
          {!pathname.includes('/scope/create') && !pathname.includes('/profile') && (
            <div className="header__toggle">
              <button
                className="burger-button mr-2"
                onClick={() => setToggle((prev: boolean) => !prev)}
                onBlur={handleChangeToggleOnBlur}
              >
                {burgerButton}
              </button>
              {user && logo && (
                <img
                  src={toggle || (!toggle && theme === 'light') ? HexoscopeLogoRectWhite : HexoscopeLogoRect}
                  alt="Hexoscope Logo"
                  className="mr-auto"
                />
              )}
            </div>
          )}
          <ScopesList />

          <div className="d-flex navigation-wrapper">
            <div className="d-flex align-items-center profiles-list">
              <a className="svg-theme c-default pr-2" href="https://hexoscope.com/#faq" target="_blank">
                <Tooltip placement="left" text="FAQ">
                  {questionIcon}
                </Tooltip>
              </a>
              <div className="mr-1 header__img">
                <Link to="/profile">
                  {user?.email ? (
                    <img src={userPhoto || 'https://hexoscope.com/avatar-hexoscope.svg'} alt={user.firstName} />
                  ) : (
                    <div className="pulse">
                      <div className="circle-placeholder"></div>
                    </div>
                  )}
                </Link>
              </div>
              <ProfilesList />
            </div>
            <div className="d-flex align-items-center">
              <ProductsList hexometerPlan={'FREE'} />
              <ModeSwitcher />
            </div>
          </div>
        </div>
      </header>
      
      {showVerificationModal && (
        <Modal
          isModalOpen={showVerificationModal}
          actionBtnText=""
          actionBtnColor="transparent"
          text={<VerificationEmail />}
          onCloseModal={closeModal}
          onlyBtns
          className="p-0"
          escButtonClick={closeModal}
        />
      )}
    </>
  );
};

export default Header;
