import {FC, useState, useEffect, useRef, useMemo, useContext, useCallback} from 'react';
import {FixedSizeList as List} from 'react-window';
import useClickOutside from '../../../hooks/useClickOutside';
import {arrowDownIcon} from '../../../images/svg-icons';
import {ISelect} from 'components/atom/select/types';
import Input from 'components/atom/input';
import Option from 'components/atom/select/option';
import 'components/atom/select/styles.scss';

const OptimizedSelect: FC<ISelect> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  className,
  selectWrapperClassName,
  placeholder,
  bottom,
  icon,
  showLeft,
  showRight,
  showBottom,
  fullWidth,
  noBorder,
  width,
  height,
  label,
  danger,
  w100,
  textDefault,
  textUppercase,
  searchable,
  addNewOption,
  isEventStop,
  leftIcon,
  openMenuOnTop,
  addNoSelectOption,
  placeholderLeft,
  query,
  ws_id,
  saveText,
  handleTransformData,
  createNew,
  handleNew,
  setDataNotFound,
  typeValue,
  tooltipClassName,
  tooltipText,
  onClick,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside();
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const selectWidth = useMemo(() => {
    return {
      width: width ? width : '',
      height: height ? height : '',
    };
  }, [width, height]);

  useEffect(() => {
    setFilteredOptions(
      searchText ? options.filter(({label}: any) => label.toLowerCase().includes(searchText.toLowerCase())) : options,
    );
  }, [options, searchText]);

  const handleShowOptions = () => {
    if (!disabled) {
      setShowOptions(!showOptions);
    }
  };

  const handleChangeInput = useCallback(
    (e: any) => {
      if (typeValue) {
        const val = typeValue === 'int-number' ? e.target.value.replace(/\D/g, '') : e.target.value;
        const new_val = Math.abs(+val);
        onChange(String(new_val > 500 && typeValue === 'int-number' ? 500 : new_val || ''));
        return;
      }
      setSearchText(e.target.value);
      if (query) return;
      const newOpt = [...options];
      const fil = newOpt.filter((op: {label: string}) =>
        op.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
      );
      setFilteredOptions(e.target.value && e.target.value.trim() ? fil : options);
      saveText && onChange(e.target.value);
    },
    [options, query, saveText, onChange],
  );

  const handleChange = (val: any) => {
    onChange(val);
    setShowOptions(false);
  };

  useEffect(() => {
    !showOptions && setSearchText('');
  }, [showOptions]);
  return (
    <>
      <div
        id="select-wrapper"
        className={`d-block ${w100 ? 'w-100' : ''} ${selectWrapperClassName ? selectWrapperClassName : ''}`}
        onClick={onClick}
      >
        {label && <div className="select-label primary-color">{label}</div>}
        <div
          className={`custom-universal-select noselect ${className ? className : ''} ${
            showLeft ? 'show-left' : showRight ? 'show-right' : showBottom ? 'show-bottom' : ''
          } ${fullWidth ? 'full-width' : ''} ${noBorder ? 'no-border' : ''} ${disabled ? 'disabled-select' : ''}`}
          ref={selectRef}
          //onClick={handleSetIsShow}
        >
          {showLeft || showRight || showBottom ? (
            <span id="icon" onClick={handleShowOptions}>
              {icon}
            </span>
          ) : (
            <div
              className={`select d-flex ${showOptions ? 'border-color' : ''} ${danger ? 'border-danger' : ''} `}
              style={selectWidth}
              onClick={handleShowOptions}
            >
              {leftIcon && <span className="mr-2">{leftIcon ? leftIcon : ''}</span>}
              {(searchable || typeValue) && (
                <div className={`${showOptions ? 'show' : 'hide'}`}>
                  <Input
                    value={searchText}
                    onChange={handleChangeInput}
                    className="no-border-input"
                    placeholder={
                      typeof value === 'object' ? value?.label || placeholder : value || String(placeholder) || ''
                    }
                    // onFocus={handleShowInput}
                    // onBlur={handleHideInput}
                    // onKeyDown={handleShowInput}
                    type={typeValue || 'text'}
                  />
                </div>
              )}

              {(!searchable || !showOptions) && placeholder && !value ? (
                <span className={`select-placeholder ${placeholderLeft ? 'ml-auto' : 'mr-auto'}`}>{placeholder}</span>
              ) : (
                !showOptions && (
                  <span
                    className={`mr-auto  ${textDefault ? 'text-transform-default' : ''}${
                      textUppercase ? 'text_upperCase' : ''
                    } selected-value noselect position-relative`}
                  >
                    {false
                      ? value?.length < 1
                        ? placeholder
                        : ''
                      : typeof value === 'object'
                      ? value && value.label
                      : options.find((option: any) => option.label === value || option.value === value)?.label || value}
                  </span>
                )
              )}

              {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
            </div>
          )}
          {showOptions && (
            <div className="options">
              {filteredOptions?.length ? (
                <List height={filteredOptions.length > 6 ? 250 : filteredOptions.length * 40} itemCount={filteredOptions.length} itemSize={40} width="100%">
                  {({index, style}) => (
                    <div style={style}>
                      {filteredOptions.map(
                        (item: any, ind: number) =>
                          index === ind && (
                            <Option
                              onChange={handleChange}
                              key={(item.name || item) + index}
                              value={value}
                              textDefault={textDefault}
                              textUppercase={textUppercase}
                              item={item}
                              isEventStop={isEventStop}
                              disabled={item.disabled}
                              setShowOptions={() => {}}
                            />
                          ),
                      )}
                    </div>
                  )}
                </List>
              ) : (
                <div className="secondary-color text-center py-4">Nothing to show</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OptimizedSelect;
