import { FC, memo, useEffect, useState } from 'react';
import Select from '../../components/atom/select';
import { checkedIcon, menuNavbarIcon } from 'images/svg-icons';
import '../layout.scss';
import hexometerLogo from '../../images/logos/hexometer-logo.svg';
import hexowatchLogo from '../../images/logos/hexowatch-logo.svg';
import hexomaticLogo from '../../images/logos/hexomatic-logo.svg';
import hexosparkLogo from '../../images/logos/hexospark-icon.svg';
import hexoscopeLogo from '../../images/logos/hexoscope-icon.svg';

const ProductsList: FC<{ hexometerPlan: string }> = ({ hexometerPlan }) => {
  const [productsList, setProductsList] = useState([
    {
      title: 'Hexoscope',
      icon: hexoscopeLogo,
      path: '/',
    },
    {
      title: 'Hexomatic',
      icon: hexomaticLogo,
      path: 'https://dash.hexomatic.com/',
    },
    {
      title: 'Hexowatch',
      icon: hexowatchLogo,
      path: 'https://dash.hexowatch.com/hexowatch',
    },

    {
      title: 'Hexospark',
      icon: hexosparkLogo,
      path: 'https://dash.hexospark.com/',
    },
    {
      title: 'Hexometer',
      icon: hexometerLogo,
      path: 'https://dash.hexometer.com/',
    },
  ]);
  useEffect(() => {
    if (!hexometerPlan || hexometerPlan === 'FREE') {
      setProductsList([
        {
          title: 'Hexoscope',
          icon: hexoscopeLogo,
          path: '/',
        },
        {
          title: 'Hexomatic',
          icon: hexomaticLogo,
          path: 'https://dash.hexomatic.com/',
        },
        {
          title: 'Hexowatch',
          icon: hexowatchLogo,
          path: 'https://dash.hexowatch.com/hexowatch',
        },

        {
          title: 'Hexospark',
          icon: hexosparkLogo,
          path: 'https://dash.hexospark.com/',
        },
        {
          title: 'Hexometer',
          icon: hexometerLogo,
          path: 'https://hexometer.com/pricing/',
        },
      ]);
    } else {
      setProductsList([
        {
          title: 'Hexoscope',
          icon: hexoscopeLogo,
          path: '/',
        },
        {
          title: 'Hexomatic',
          icon: hexomaticLogo,
          path: 'https://dash.hexomatic.com/',
        },
        {
          title: 'Hexowatch',
          icon: hexowatchLogo,
          path: 'https://dash.hexowatch.com/hexowatch',
        },

        {
          title: 'Hexospark',
          icon: hexosparkLogo,
          path: 'https://dash.hexoscope.com/',
        },
        {
          title: 'Hexometer',
          icon: hexometerLogo,
          path: 'https://dash.hexometer.com/',
        },
      ]);
    }
  }, [hexometerPlan]);

  return (
    <div className="svg-theme">
      <Select showLeft icon={menuNavbarIcon} noBorder>
        <div className="option-title">
          <h5 className="text-center pl-1 pr-1">Hexact Solutions</h5>
          <hr />
        </div>
        {productsList &&
          productsList.map((item: any) => (
            <a
              className="option nav-link-component"
              key={item.title}
              href={item.path}
              target={item.path.includes('https') ? '_blank' : ''}
            >
              <img src={item.icon} className="product-image" />
              <span className="ml-3">{item.title}</span>
              {item.title === 'Hexoscope' && <span className="ml-auto current-product">{checkedIcon}</span>}
            </a>
          ))}
      </Select>
    </div>
  );
};

export default memo(ProductsList);
