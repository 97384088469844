export const usaStates = [
  { label: 'Alabama', value: 'AL', code: '01' },
  { label: 'Alaska', value: 'AK', code: '02' },
  { label: 'Arizona', value: 'AZ', code: '04' },
  { label: 'Arkansas', value: 'AR', code: '05' },
  { label: 'California', value: 'CA', code: '06' },
  { label: 'Colorado', value: 'CO', code: '08' },
  { label: 'Connecticut', value: 'CT', code: '09' },
  { label: 'Delaware', value: 'DE', code: '10' },
  { label: 'District of Columbia', value: 'DC', code: '11' },
  { label: 'Florida', value: 'FL', code: '12' },
  { label: 'Georgia', value: 'GA', code: '13' },
  { label: 'Hawaii', value: 'HI', code: '15' },
  { label: 'Idaho', value: 'ID', code: '16' },
  { label: 'Illinois', value: 'IL', code: '17' },
  { label: 'Indiana', value: 'IN', code: '18' },
  { label: 'Iowa', value: 'IA', code: '19' },
  { label: 'Kansas', value: 'KS', code: '20' },
  { label: 'Kentucky', value: 'KY', code: '21' },
  { label: 'Louisiana', value: 'LA', code: '22' },
  { label: 'Maine', value: 'ME', code: '23' },
  { label: 'Maryland', value: 'MD', code: '24' },
  { label: 'Massachusetts', value: 'MA', code: '25' },
  { label: 'Michigan', value: 'MI', code: '26' },
  { label: 'Minnesota', value: 'MN', code: '27' },
  { label: 'Mississippi', value: 'MS', code: '28' },
  { label: 'Missouri', value: 'MO', code: '29' },
  { label: 'Montana', value: 'MT', code: '30' },
  { label: 'Nebraska', value: 'NE', code: '31' },
  { label: 'Nevada', value: 'NV', code: '32' },
  { label: 'New Hampshire', value: 'NH', code: '33' },
  { label: 'New Jersey', value: 'NJ', code: '34' },
  { label: 'New Mexico', value: 'NM', code: '35' },
  { label: 'New York', value: 'NY', code: '36' },
  { label: 'North Carolina', value: 'NC', code: '37' },
  { label: 'North Dakota', value: 'ND', code: '38' },
  { label: 'Ohio', value: 'OH', code: '39' },
  { label: 'Oklahoma', value: 'OK', code: '40' },
  { label: 'Oregon', value: 'OR', code: '41' },
  { label: 'Pennsylvania', value: 'PA', code: '42' },
  { label: 'Puerto Rico', value: 'PR', code: '72' },
  { label: 'Rhode Island', value: 'RI', code: '44' },
  { label: 'South Carolina', value: 'SC', code: '45' },
  { label: 'South Dakota', value: 'SD', code: '46' },
  { label: 'Tennessee', value: 'TN', code: '47' },
  { label: 'Texas', value: 'TX', code: '48' },
  { label: 'Utah', value: 'UT', code: '49' },
  { label: 'Vermont', value: 'VT', code: '50' },
  { label: 'Virginia', value: 'VA', code: '51' },
  { label: 'Virgin Islands', value: 'VI', code: '78' },
  { label: 'Washington', value: 'WA', code: '53' },
  { label: 'West Virginia', value: 'WV', code: '54' },
  { label: 'Wisconsin', value: 'WI', code: '55' },
  { label: 'Wyoming', value: 'WY', code: '56' },
];

export const AustraliaStates = [
  {
    label: 'New South Wales',
    value: 'NSW',
  },
  {
    label: 'Victoria',
    value: 'VIC',
  },
  {
    label: 'Queensland',
    value: 'QLD',
  },
  {
    label: 'Tasmania',
    value: 'TAS',
  },
  {
    label: 'South Australia',
    value: 'SA',
  },
  {
    label: 'Western Australia',
    value: 'WA',
  },
  {
    label: 'Northern Territory',
    value: 'NT',
  },
  {
    label: 'Australian Capital Territory',
    value: 'ACT',
  },
];

export const states = { 'United States': usaStates, Australia: AustraliaStates };

export const taxIds = {
  australia: [
    {
      label: 'au_abn',
      value: 'au_abn',
      description: 'Australian Business Number (AU ABN)',
      example: '12345678912',
    },
    {
      label: 'au_arn',
      value: 'au_arn',
      description: 'Australian Taxation Office Reference Number',
      example: '123456789123',
    },
  ],
  austria: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'ATU12345678',
    },
  ],
  belgium: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'BE0123456789',
    },
  ],
  brazil: [
    {
      label: 'br_cnpj',
      value: 'br_cnpj',
      description: 'Brazilian CNPJ number',
      example: '01.234.456/5432-10',
    },
    {
      label: 'br_cpf',
      value: 'br_cpf',
      description: 'Brazilian CPF number',
      example: '123.456.789-87',
    },
  ],
  bulgaria: [
    {
      label: 'bg_uic',
      value: 'bg_uic',
      description: 'Bulgaria Unified Identification Code',
      example: '123456789',
    },
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'BG0123456789',
    },
  ],
  canada: [
    {
      label: 'ca_bn',
      value: 'ca_bn',
      description: 'Canadian BN',
      example: '123456789',
    },
    {
      label: 'ca_gst_hst',
      value: 'ca_gst_hst',
      description: 'Canadian GST/HST number',
      example: '123456789RT0002',
    },
    {
      label: 'ca_pst_bc',
      value: 'ca_pst_bc',
      description: 'Canadian PST number (British Columbia)',
      example: 'PST-1234-5678',
    },
    {
      label: 'ca_pst_mb',
      value: 'ca_pst_mb',
      description: 'Canadian PST number (Manitoba)',
      example: '123456-7',
    },
    {
      label: 'ca_pst_sk',
      value: 'ca_pst_sk',
      description: 'Canadian PST number (Saskatchewan)',
      example: '1234567',
    },
    {
      label: 'ca_qst',
      value: 'ca_qst',
      description: 'Canadian QST number (Québec)',
      example: '1234567890TQ1234',
    },
  ],
  chile: [
    {
      label: 'cl_tin',
      value: 'cl_tin',
      description: 'Chilean TIN',
      example: '12.345.678-K',
    },
  ],
  croatia: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'HR12345678912',
    },
  ],
  cyprus: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'CY12345678Z',
    },
  ],
  czechia: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'CZ1234567890',
    },
  ],
  denmark: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'DK12345678',
    },
  ],
  estonia: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'EE123456789',
    },
  ],
  finland: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'FI12345678',
    },
  ],
  france: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'FRAB123456789',
    },
  ],
  georgia: [
    {
      label: 'ge_vat',
      value: 'ge_vat',
      description: 'Georgian VAT',
      example: '123456789',
    },
  ],
  germany: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'DE123456789',
    },
  ],
  greece: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'EL123456789',
    },
  ],
  hong_kong: [
    {
      label: 'hk_br',
      value: 'hk_br',
      description: 'Hong Kong BR number',
      example: '12345678',
    },
  ],
  hungary: [
    {
      label: 'hu_tin',
      value: 'hu_tin',
      description: 'Hungary tax number (adószám)',
      example: '12345678-1-23',
    },
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'HU12345678912',
    },
  ],
  iceland: [
    {
      label: 'is_vat',
      value: 'is_vat',
      description: 'Icelandic VAT',
      example: '123456',
    },
  ],
  india: [
    {
      label: 'in_gst',
      value: 'in_gst',
      description: 'Indian GST number',
      example: '12ABCDE3456FGZH',
    },
  ],
  indonesia: [
    {
      label: 'id_npwp',
      value: 'id_npwp',
      description: 'Indonesian NPWP number',
      example: '12.345.678.9-012.345',
    },
  ],
  ireland: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'IE1234567AB',
    },
  ],
  israel: [
    {
      label: 'il_vat',
      value: 'il_vat',
      description: 'Israel VAT',
      example: '000012345',
    },
  ],
  italy: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'IT12345678912',
    },
  ],
  japan: [
    {
      label: 'jp_cn',
      value: 'jp_cn',
      description: 'Japanese Corporate Number (*Hōjin Bangō*)',
      example: '1234567891234',
    },
    {
      label: 'jp_rn',
      value: 'jp_rn',
      description:
        "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
      example: '12345',
    },
  ],
  latvia: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'LV12345678912',
    },
  ],
  liechtenstein: [
    {
      label: 'li_uid',
      value: 'li_uid',
      description: 'Liechtensteinian UID number',
      example: 'CHE123456789',
    },
  ],
  lithuania: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'LT123456789123',
    },
  ],
  luxembourg: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'LU12345678',
    },
  ],
  malaysia: [
    {
      label: 'my_frp',
      value: 'my_frp',
      description: 'Malaysian FRP number',
      example: '12345678',
    },
    {
      label: 'my_itn',
      value: 'my_itn',
      description: 'Malaysian ITN',
      example: 'C 1234567890',
    },
    {
      label: 'my_sst',
      value: 'my_sst',
      description: 'Malaysian SST number',
      example: 'A12-3456-78912345',
    },
  ],
  malta: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'MT12345678',
    },
  ],
  mexico: [
    {
      label: 'mx_rfc',
      value: 'mx_rfc',
      description: 'Mexican RFC number',
      example: 'ABC010203AB9',
    },
  ],
  netherlands: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'NL123456789B12',
    },
  ],
  new_zealand: [
    {
      label: 'nz_gst',
      value: 'nz_gst',
      description: 'New Zealand GST number',
      example: '123456789',
    },
  ],
  norway: [
    {
      label: 'no_vat',
      value: 'no_vat',
      description: 'Norwegian VAT number',
      example: '123456789MVA',
    },
  ],
  poland: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'PL1234567890',
    },
  ],
  portugal: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'PT123456789',
    },
  ],
  romania: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'RO1234567891',
    },
  ],
  russia: [
    {
      label: 'ru_inn',
      value: 'ru_inn',
      description: 'Russian INN',
      example: '1234567891',
    },
    {
      label: 'ru_kpp',
      value: 'ru_kpp',
      description: 'Russian KPP',
      example: '123456789',
    },
  ],
  saudi_arabia: [
    {
      label: 'sa_vat',
      value: 'sa_vat',
      description: 'Saudi Arabia VAT',
      example: '123456789012345',
    },
  ],
  singapore: [
    {
      label: 'sg_gst',
      value: 'sg_gst',
      description: 'Singaporean GST',
      example: 'M12345678X',
    },
    {
      label: 'sg_uen',
      value: 'sg_uen',
      description: 'Singaporean UEN',
      example: '123456789F',
    },
  ],
  slovakia: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'SK1234567891',
    },
  ],
  slovenia: [
    {
      label: 'si_tin',
      value: 'si_tin',
      description: 'Slovenia tax number (davčna številka)',
      example: '12345678',
    },
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'SI12345678',
    },
  ],
  south_africa: [
    {
      label: 'za_vat',
      value: 'za_vat',
      description: 'South African VAT number',
      example: '4123456789',
    },
  ],
  south_korea: [
    {
      label: 'kr_brn',
      value: 'kr_brn',
      description: 'Korean BRN',
      example: '123-45-67890',
    },
  ],
  spain: [
    {
      label: 'es_cif',
      value: 'es_cif',
      description: 'Spanish CIF number',
      example: 'A12345678',
    },
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'ESA1234567Z',
    },
  ],
  sweden: [
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'European VAT number',
      example: 'SE123456789123',
    },
  ],
  switzerland: [
    {
      label: 'ch_vat',
      value: 'ch_vat',
      description: 'Switzerland VAT number',
      example: 'CHE-123.456.789 MWST',
    },
  ],
  taiwan: [
    {
      label: 'tw_vat',
      value: 'tw_vat',
      description: 'Taiwanese VAT',
      example: '12345678',
    },
  ],
  thailand: [
    {
      label: 'th_vat',
      value: 'th_vat',
      description: 'Thai VAT',
      example: '1234567891234',
    },
  ],
  ukraine: [
    {
      label: 'ua_vat',
      value: 'ua_vat',
      description: 'Ukrainian VAT',
      example: '123456789',
    },
  ],
  united_arab_emirates: [
    {
      label: 'ae_trn',
      value: 'ae_trn',
      description: 'United Arab Emirates TRN',
      example: '123456789012345',
    },
  ],
  united_kingdom: [
    {
      label: 'gb_vat',
      value: 'gb_vat',
      description: 'United Kingdom VAT number',
      example: 'GB123456789',
    },
    {
      label: 'eu_vat',
      value: 'eu_vat',
      description: 'Northern Ireland VAT number',
      example: 'XI123456789',
    },
  ],
  united_states: [
    {
      label: 'us_ein',
      value: 'us_ein',
      description: 'United States EIN',
      example: '12-3456789',
    },
  ],
};

//export const countryListNew =
