import { FC, ReactElement } from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { uploadPlusIcon } from 'images/svg-icons';
import './styles.scss';

interface DropZoneProps {
  name: string;
  onChange: (file: any) => void;
  value: any;
  customHeight: boolean;
  agencyGrowth?: boolean;
  loading?: boolean;
  hideInfoText?: boolean;
  text?: ReactElement | string;
  icon?: ReactElement;
  returnInitialFile?: boolean;
}

const DropZoneField: FC<DropZoneProps> = ({
  name,
  onChange,
  value,
  customHeight,
  agencyGrowth,
  loading,
  hideInfoText,
  text,
  icon,
  returnInitialFile,
}) => {
  const onDrop = (file: any) => {
    if (returnInitialFile) {
      file?.[0] && onChange(file[0]);
      return;
    }
    new Promise((resolve, reject) => {
      const image = new Image();
      file && file[0] && (image.src = URL.createObjectURL(file[0]));
      image.onload = () => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        if (image.width) {
          reader.onload = () =>
            resolve(
              onChange(
                Object.assign(file, {
                  preview: URL.createObjectURL(file[0]),
                  url: reader.result,
                  mimeType: file[0].type,
                  size: file[0].size,
                  name: file[0].name,
                }),
              ),
            );
          reader.onerror = reject;
        }
      };
    });
  };

  const removeFile = (index: any, e: any) => {
    e.preventDefault();
    onChange(typeof value === 'string' ? '' : value.filter((val: any, i: number) => i !== index));
  };

  return (
    <div className="d-flex align-items-center dropzone-wrapper">
      <div className="dropzone-block">
        <div
          className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''} ${
            loading ? 'pulse' : ''
          }`}
        >
          <Dropzone
            accept={{
              'image/jpeg': ['.jpeg'],
              'image/png': ['.png'],
            }}
            multiple={false}
            onDrop={onDrop}
          >
            {({ getRootProps, getInputProps }: any) => (
              <label
                htmlFor="upload-file-input"
                {...getRootProps()}
                className={classnames('dropzone__input dropzone-input', { 'ag-dropzone__input': agencyGrowth })}
              >
                {(!value || value.length === 0) && (
                  <div className={classnames(agencyGrowth ? 'ag-dropzone__drop-here' : 'dropzone__drop-here')}>
                    {icon ? <span className="mb-2">{icon}</span> : <span className="lnr mb-2">{uploadPlusIcon}</span>}
                    {text ? text : 'Browse file'}
                  </div>
                )}

                <input
                  accept="image/jpeg, image/png"
                  type="file"
                  autoComplete="off"
                  tabIndex={-1}
                  name="files"
                  style={{ display: 'none' }}
                  {...getInputProps()}
                  id="upload-file-input"
                />
              </label>
            )}
          </Dropzone>
          {value && (
            <div className="m-auto">
              <aside className={classnames('dropzone__img', { 'agency-growth-pdf': agencyGrowth })}>
                {
                  //@ts-ignore
                  <img src={typeof value === 'string' ? value : value.preview} alt="drop-img" />
                }
                <button className="dropzone__img-delete" type="button" onClick={e => removeFile(0, e)}>
                  Remove
                </button>
              </aside>
            </div>
          )}
        </div>
      </div>
      {hideInfoText ? (
        <div></div>
      ) : (
        <div className="form__form-group-label dr-text">
          <p className="mb-1 logo-text"> Upload your logo to use in PDF reports (PNG or JPEG)</p>
          <p className="mb-1 mt-1 logo-text">Max size 300KB</p>
          <p className="mb-1 mt-1 logo-text">Recommended 200x150px</p>
        </div>
      )}
    </div>
  );
};

export default DropZoneField;
