import {gql} from '@apollo/client';

export const HEXOFY_USER = gql`
  query HEXOFY_USER {
    HexofyUser {
      getHexofyUser {
        hfUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const HEXOSCOPE_USER = gql`
  query HEXOSCOPE_USER {
    HexoscopeUser {
      getHexoscopeUser {
        hsUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          business_type
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const USER_PLANS_QUERY = gql`
  query USER_PLANS_QUERY {
    User {
      getUserPlans {
        hexomatic {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexowatch {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexometer {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexospark {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexoscope {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        error_code
      }
    }
  }
`;

export const LIST_CUSTOMER_CARD = gql`
  query LIST_CUSTOMER_CARD($settings: ListCustomerCardsInput) {
    Billing {
      listCustomerCards(settings: $settings) {
        cards {
          id
          createdAt
          updatedAt
          cardId
          customerId
          brand
          lastDigits
          isDefault
        }
        error_code
      }
    }
  }
`;
export const GET_ACCESS_TOKEN = gql`
  query GET_ACCESS_TOKEN($settings: GetAccessTokenInput) {
    User {
      getAccessToken(settings: $settings) {
        error_code
        token
      }
    }
  }
`;

export const GET_RENEWAL_DATE = gql`
  query ($settings: GetPackageRenewalDateInput) {
    User {
      getPackageRenewalDate(settings: $settings) {
        hexomatic
        hexowatch
        hexometer
        premium_credits
        error_code
      }
    }
  }
`;

export const BILING_DATES = gql`
  query ($settings: RetrieveUpcomingInvoiceInput) {
    Billing {
      retrieveUpcomingInvoice(settings: $settings) {
        packagesBillingInfo {
          hexowatch {
            last_billing_date
            next_billing_date
          }
          hexofy {
            last_billing_date
            next_billing_date
          }
          hexomatic {
            last_billing_date
            next_billing_date
          }
          hexometer {
            last_billing_date
            next_billing_date
          }
          hexospark {
            last_billing_date
            next_billing_date
          }
          # hexoscope {
          #   last_billing_date
          #   next_billing_date
          # }
          premium_credits {
            last_billing_date
            next_billing_date
          }
        }

        error_code
      }
    }
  }
`;

export const GET_HEXOMATIC_USER = gql`
  query GET_HEXOMATIC_USER {
    HexomaticUser {
      getByLegacyId {
        isPerformer
        id
        automation_credit
        premium_automation_credit
        user_legacy_id
        storage
        pricing_package
        pricing_package_interval
        pricing_package_count
        stripe_subscription_id
        left_automation_credit
        left_premium_automation_credit
        error_code
        promo_codes_count
        subscription_status
        billing_info {
          next_billing_date
        }
        paypal_subscription_id
      }
    }
  }
`;

export const GET_HEXOMETER_USER_SETTINGS = gql`
  query GET_HEXOMETER_USER_SETTINGS($user_id: Int!) {
    HexometerUserSettings {
      get(user_id: $user_id) {
        pricing_package
        pricing_package_interval
        pricing_package_count
        billing_info {
          last_billing_date
          next_billing_date
        }
        paypal_subscription_id
        subscription_status
      }
    }
  }
`;

export const GET_USER_WATCH_SETTINGS_QUERY = gql`
  query GET_USER_WATCH_SETTINGS_QUERY {
    UserWatchSettings {
      get {
        id
        phoneNumber
        phoneNumberVerified
        phoneEnabled
        monthly_limit
        emails {
          email
          enabled
          is_user_email
          verified
          verify_email_send_at
        }
        emailEnabled
        daily_digest_alert_email_time
        browsers {
          name
          enabled
        }
        slackIntegration
        telegramChatId
        pricing_package_interval
        pricing_package
        left_monthly
        zapierIntegration
        watchApiKey
        webhooks {
          webhook
          subscriptionId
        }
        billing_info {
          next_billing_date
          last_billing_date
        }
        config {
          additional_scans_count
          daily_email_alert_limit
        }
        activated_promo_codes_count
        subscription_status
        billing_info {
          next_billing_date
        }
        paypal_subscription_id
      }
    }
  }
`;

export const LIST_PREMIUM_CREDITS = gql`
  query {
    Billing {
      listPremiumCredits {
        premiumCredits {
          name
          free
          limits {
            premium_credits_count
          }
          enable
          interval
          productId
          amount
          id
          productType
        }
        error_code
      }
    }
  }
`;

export const HEXOSPARK_USER = gql`
  query HEXOSPARK_USER {
    HexosparkUser {
      getHexosparkUser {
        hsUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          additional_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }
          pending_payment
          db_location
          uploadHash
          isExUser

          settings {
            storage
            emails_sent
            emails_limit
            renewal_date
            teamAccess
            workspaces
            emailAccounts
            totalPromptsCount
            usedPromptsCount
          }
          error_code
        }
        can_use_trial
        trial_packages
        promo_codes_count
        invitationInfo {
          invited_at
          invites_count
          paid_invites_count
        }
      }
    }
  }
`;

export const USER_QUERY = gql`
  query USER_QUERY {
    User {
      get {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        companyWebsite
        industry
        countryUser
        title
        company
        phone
        error
        verified
        shouldBeDeletedAt
        logo
        type
        hexometer_pricing_package
        hexometer_pricing_package_interval
        hexometer_pricing_package_count
        hexomatic_pricing_package
        hexomatic_pricing_package_interval
        hexomatic_pricing_package_count
        premium_credit_subscription_status
        premium_credit_subscription_cancel_at
        premium_credits_paypal_subscription_id
        premium_credits_stripe_subscription_id
        premium_credits_pricing_package
        premium_credits_pricing_package_interval
        left_premium_credits
        left_one_time_premium_credits
        referral_code
        photo
        uploadHash
        twofa {
          status
          type
          date
        }
        info {
          address1
          address2
          city
          state
          zip
          tax_type
          tax_value
        }
        promoData {
          hexowatch_ltd
        }
      }
    }
  }
`;

export const PAYMENT_METHOD_QUERY = gql`
  query PAYMENT_METHOD_QUERY {
    User {
      creditCardInfo {
        number
        name
        expiry
      }
    }
  }
`;

export const LIST_CUSTOMER_INVOICES = gql`
  query ($settings: ListCustomerInvoicesInput) {
    Billing {
      listCustomerInvoices(settings: $settings) {
        invoices {
          invoiceId
          amountDue
          amountPaid
          total
          createdAt
          status
          invoicePdf
        }
        error_code
      }
    }
  }
`;

export const PROMO_CODE_BALANCE_QUERY = gql`
  query {
    User {
      promoBalance {
        count
      }
    }
  }
`;

export const LEFT_PACKAGES_COUNT = gql`
  query {
    User {
      getHexomateLeftLTDPackagesCount {
        count
        error
        message
      }
    }
  }
`;

export const GET_STATE_BY_COUNTRY = gql`
  query GET_STATE_BY_COUNTRY($settings: GetStateByCountryInput) {
    HexosparkUser {
      getStateByCountry(settings: $settings) {
        error_code
        result {
          label
          value
        }
      }
    }
  }
`;

export const GET_CITY_BY_COUNTRY = gql`
  query GET_CITY_BY_COUNTRY($settings: GetStateByCountryInput) {
    HexosparkUser {
      getCityByCountry(settings: $settings) {
        error_code
        result {
          label
          state: value
          value: label
        }
      }
    }
  }
`;
export const ACTIVATE_PROMO_CODE = gql`
  mutation ($code: String!) {
    PromoCodeOps {
      activate(code: $code) {
        error
        message
      }
    }
  }
`;