import {FC, useContext, useEffect, useState} from 'react';
import ToastLoader from 'components/molecule/toastLoader';
import {ScopesContext} from 'contexts/scopes-context';
import DefaultSettings from './default-settings';
import {useParams} from 'react-router-dom';
import {getScopeById} from 'requests';
import './styles.scss';

const IntegrationsPage: FC<{}> = () => {
  const {scope_id} = useParams();
  const {setSelectedScope} = useContext(ScopesContext);
  const [toasterInfo, setToasterInfo] = useState<{
    show: boolean;
    errorMessage?: string;
    loadingMessage?: string;
    successMessage?: string;
  }>({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  useEffect(() => {
    const getScope = async () => {
      const resp = await getScopeById(scope_id);
      //@ts-ignore
      setSelectedScope(resp?.data);
    };
    if (scope_id) {
      getScope();
    }
  }, [scope_id]);

  return (
    <>
      <h4 className="page-title mb-2">Settings</h4>
      <div className="row d-flex pl-3">
        {/* 1st column */}
        <div className="col-xl-6" style={{paddingRight: '15px'}}>
          <div className="row wrapper">
            <div className="col-12 col-lg-12 px-3">
              <DefaultSettings />
            </div>
          </div>
        </div>
      </div>
      {toasterInfo.show && (
        <ToastLoader
          successMessage={toasterInfo.successMessage}
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          setToasterInfo={setToasterInfo}
        />
      )}
    </>
  );
};

export default IntegrationsPage;
