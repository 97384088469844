import React from 'react';

// import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';

// );
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
//@ts-ignore
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <App />
);