import {memo, Suspense, useContext} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import AddOrEditScope from 'pages/scope/add-or-edit-scope';
import Scope from 'pages/scope';
import SignOut from 'pages/sign-out';
import Profile from 'pages/profile';
import Settings from 'pages/settings';
import History from 'pages/scope/history';
import AllScans from 'pages/scope/history';
import {ScopesContext} from 'contexts/scopes-context';

const AppRoutes = () => {
  const scope_id = localStorage.getItem('scope_id');
  const scan_id = localStorage.getItem('scan_id');
  const {selectedScope} = useContext(ScopesContext);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                selectedScope?.id || scope_id
                  ? `/scope/${selectedScope?.id || scope_id}/${selectedScope?.last_scan_id || scan_id}`
                  : '/scope/create'
              }
            />
          }
        />
        <Route
          path="/scope"
          element={
            <Navigate
              to={
                selectedScope?.id || scope_id
                  ? `/scope/${selectedScope?.id || scope_id}/${selectedScope?.last_scan_id || scan_id}`
                  : '/scope/create'
              }
            />
          }
        />
        <Route
          path="/scope/"
          element={
            <Navigate
              to={
                selectedScope?.id || scope_id
                  ? `/scope/${selectedScope?.id || scope_id}/${selectedScope?.last_scan_id || scan_id}`
                  : '/scope/create'
              }
            />
          }
        />
        <Route
          path="/scope/:scope_id"
          element={
            <Navigate
              to={
                selectedScope?.id || scope_id
                  ? `/scope/${selectedScope?.id || scope_id}/${selectedScope?.last_scan_id || scan_id}`
                  : '/scope/create'
              }
            />
          }
        />
        <Route
          path="/scope/:scope_id/"
          element={
            <Navigate
              to={
                selectedScope?.id || scope_id
                  ? `/scope/${selectedScope?.id || scope_id}/${selectedScope?.last_scan_id || scan_id}`
                  : '/scope/create'
              }
            />
          }
        />
        <Route path="/scope/create" element={<AddOrEditScope />} />
        <Route path="/scope/:scope_id/:scan_id" element={<Scope />} />
        <Route path="/scope/:scope_id/edit" element={<AddOrEditScope />} />
        <Route path="/scope/:scope_id/history" element={<History />} />
        <Route path="/scope/:scope_id/settings" element={<Settings />} />
        <Route path="/scope/:scope_id/scans" element={<AllScans />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/sign-out" element={<SignOut />} />
      </Routes>
    </Suspense>
  );
};

export default memo(AppRoutes);
