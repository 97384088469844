import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { USER_QUERY, HEXOSCOPE_USER } from 'graphql/queries';
import { UserContext } from 'contexts/user-context';
// import { setFreshChatUserData } from 'helpers';

const useSetUser = (only?: 'user' | 'hexoscope_user') => {
  const { hexoscopeUser, setUser, setHexoscopeUser } = useContext(UserContext);

  const [refetchUser, { data: hexoscopeUserData }] = useLazyQuery(HEXOSCOPE_USER, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'v2' },
  });

  const [refetch, { data: userData }] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'v2' },
  });

  useEffect(() => {
    if (only !== 'hexoscope_user' && userData && userData.User && userData.User.get) {
      setUser(userData.User.get.id ? { ...userData.User.get } : {});
    }
  }, [userData]);

  useEffect(() => {
    if (
      only !== 'user' &&
      hexoscopeUserData &&
      hexoscopeUserData.HexoscopeUser &&
      hexoscopeUserData.HexoscopeUser.getHexoscopeUser &&
      hexoscopeUserData.HexoscopeUser.getHexoscopeUser.hsUser
    ) {
      const {
        settings,
        pricing_package,
        pricing_package_count,
        pricing_package_interval,
        stripe_subscription_id,
        paypal_subscription_id,
      } = hexoscopeUserData.HexoscopeUser.getHexoscopeUser.hsUser;
      const hsUser: any = { ...hexoscopeUser?.hsUser };
      //SET DEFAULT_SCOPE in case current default is not available or empty !!!!!
      setHexoscopeUser(
        hexoscopeUserData.HexoscopeUser.getHexoscopeUser.hsUser.id
          ? only === 'hexoscope_user'
            ? {
                ...hexoscopeUser,
                hsUser: {
                  ...hsUser,
                  settings,
                  pricing_package,
                  pricing_package_count,
                  pricing_package_interval,
                  stripe_subscription_id,
                  paypal_subscription_id,
                },
              }
            : { ...hexoscopeUserData.HexoscopeUser.getHexoscopeUser }
          : {},
      );
    }
  }, [hexoscopeUserData]);

  const getUser = useCallback(() => {
    only !== 'hexoscope_user' && refetch();
    only !== 'user' && refetchUser();
  }, [only, refetch, refetchUser]);

  return [getUser];
};

export default useSetUser;
