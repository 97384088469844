import { FC, memo } from 'react';
import { ISwitch } from './types';
import Tooltip from '../../molecule/tooltip';
import './styles.scss';

const Switch: FC<ISwitch> = ({ className = '', checked, setChecked, tooltip = '', placement = 'left' }) => (
  <Tooltip text={tooltip} placement={placement}>
    <div id="switch" onClick={setChecked} className={`${className} ${checked ? 'checked' : ''}`}>
      <div className="circle rounded-circle"></div>
    </div>
  </Tooltip>
);

export default memo(Switch);
