import { FC } from 'react';
import ws from 'isomorphic-ws';
import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, ApolloLink, split, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloProvider } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getAccessToken, getRawCookie } from '../helpers';
import { HOST } from '../constants/host';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

const { URL, WS } = HOST.APP_API;

const AuthMiddleware = new ApolloLink((operation: any, forward: any) => {
  // add the authorization to the headers
  operation.setContext(({ headers }: { headers: {} }) => ({
    headers: {
      ...headers,
      authorization: getAccessToken(),
      device_key: getRawCookie('device_key') || '',
      hxroot: getRawCookie('root'),
    },
  }));
  return forward ? forward(operation) : null;
});
const wsLink = new WebSocketLink({
  uri: WS,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: getAccessToken(),
    }),
  },
  webSocketImpl: ws,
});
const wsLink2 = new WebSocketLink({
  uri: WS,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: getAccessToken(),
    }),
  },
  webSocketImpl: ws,
});
const httpLink = new HttpLink({
  uri: URL,
  fetch,
  credentials: 'include',
});
const httpLinkV2 = new HttpLink({
  uri: URL,
  fetch,
  credentials: 'include',
});
const link_http = split(
  operation => operation.getContext().clientName === 'v2', // Routes the query to the proper client
  httpLinkV2,
  httpLink,
);
const link_ws = split(
  operation => operation.getContext().clientName === 'w2', // Routes the query to the proper client
  wsLink2,
  wsLink,
);
const link = split(
  ({ query }: { query: any }) => {
    let definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  link_http,
);
export const GraphQLClient: any = new ApolloClient({
  link: ApolloLink.from([AuthMiddleware, link]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
export const ApolloWrapper: FC<any> = ({ children }) => {
  return <ApolloProvider client={GraphQLClient}>{children}</ApolloProvider>;
};


if (true) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}