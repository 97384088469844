import {FC, useContext, useEffect, useState, useRef} from 'react';
import {USER_RESET_PASSWORD, RESEND_VERIFICATION_EMAIL} from 'graphql/mutations';
import {Routes, Route, NavLink} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {UserContext} from 'contexts/user-context';
import {ThemeContext} from 'contexts/theme-context';

import MyPlans from './components/my-plans';
import PaymentMethods from './components/payment-methods';
import PromoCodeForm from './components/promo-codes';
import Security from './components/security';
import UserAvatar from './components/user-avatar';

import Tooltip from 'components/molecule/tooltip';
import ModalComponent from 'components/molecule/modal';
import Button from 'components/atom/button';

import ProfileSettings from './components/profile-settings';
import Loader from 'components/atom/loader';

import './styles.scss';
import 'styles/placeholderAnimation.scss';

interface IProfileProps {}

const menuLinks = [
  {
    name: 'Profile',
    path: '/profile',
  },
  {
    name: 'My Plans',
    path: '/profile/my-plans',
  },
  {
    name: 'Payment Method',
    path: '/profile/payment-methods',
  },
  {
    name: 'Code',
    path: '/profile/promo-codes',
  },
  {
    name: 'Security',
    path: '/profile/security',
  },
];

const Profile: FC<IProfileProps> = props => {
  const {theme} = useContext(ThemeContext);
  const {user} = useContext(UserContext);

  const ref = useRef<HTMLParagraphElement | null>(null);
  const refName = useRef<HTMLParagraphElement | null>(null);
  //-----------------State----------------------------
  const [formData, setFormData] = useState({
    email: '',
    error: false,
    errorMessage: '',
    successMessage: '',
  });

  const [message, setMessage] = useState('');
  const [verificationSentModal, setVerificationSentModal] = useState(false);
  const [checkWidth, setCheckWidth] = useState({email: false, name: false});

  //------------------------Query---------------------
  const [forgotMutation, {loading: passwordLoading}] = useMutation(USER_RESET_PASSWORD);

  const [resendVerification, {loading}] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleResendEmail(data),
  });
  //--------------------functions--------------------------
  const handleResetPassword = async () => {
    if (!passwordLoading) {
      const {data} = await forgotMutation({variables: {email: user?.email, type: 'hexoscope'}});
      if (data) {
        const {
          UserOps: {
            forgotPassword: {error, message},
          },
        } = data;
        if (error) {
          if (message === 'Please try few minutes later.') {
            setFormData({
              ...formData,
              error: true,
              errorMessage: message,
              successMessage: '',
            });
          } else {
            setFormData({
              ...formData,
              error: true,
              errorMessage: 'Unable to send email.',
              successMessage: '',
            });
          }
        } else {
          setFormData({
            ...formData,
            error: false,
            errorMessage: '',
            successMessage: 'Password reset link has been sent to your email address, please check your email.',
          });
        }
      }
    }
  };
  const handleResendEmail = async (data: any): Promise<void> => {
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      setMessage('success');
      setVerificationSentModal(true);
    } else if (
      data.UserOps.resendVerification &&
      data.UserOps.resendVerification.message === 'Please try few minutes later.'
    ) {
      setMessage(data.UserOps.resendVerification.message);
      setVerificationSentModal(true);
    } else {
      setMessage('error');
      setVerificationSentModal(true);
    }
  };

  useEffect(() => {
    setCheckWidth({
      email: ref?.current?.scrollWidth !== ref?.current?.offsetWidth,
      name: refName?.current?.scrollWidth !== refName?.current?.offsetWidth,
    });
  }, [user]);

  return (
    <div>
      <h4 className="page-title mb-2">My Profile</h4>
      <div className="profile">
        <div className="profile-info-block">
          <div className="avatar-block ">
            {user && user.email ? (
              <UserAvatar userId={user.id} userEmail={user.email} userFirstName={user.firstName} />
            ) : (
              <div className="user-avatar">
                <div className="pulse">
                  <div className="circle"></div>
                  <div className="completed-fields"></div>
                </div>
              </div>
            )}
          </div>
          {/* <a href="https://en.gravatar.com/" target="_blank" className="hexomatic-external-link" rel='noopener'>
            Click to change Gravatar
          </a> */}
          {user && user.firstName ? (
            <>
              <Tooltip
                placement="bottom"
                text={checkWidth.name ? user?.firstName + ' ' + user?.lastName : ''}
                className="left-more"
                isSmall
              >
                <p className="user-name text-truncate text-center" ref={refName}>
                  {user?.firstName + ' ' + user?.lastName}
                </p>
              </Tooltip>
            </>
          ) : (
            <div className="pulse">
              <p className="line-name"></p>
            </div>
          )}

          {user ? (
            <>
              <Tooltip placement="bottom" text={checkWidth.email ? user.email : ''} className="left-more" isSmall>
                <p className="user-email text-truncate text-center" ref={ref}>
                  {user.email}
                </p>
              </Tooltip>
            </>
          ) : (
            <div className="pulse">
              <p className="line-email"></p>
            </div>
          )}

          <div className="reset-block">
            <Button
              name="Reset Password"
              className="mt-3 mx-auto"
              outline
              loading={passwordLoading}
              onClick={handleResetPassword}
            />
          </div>

          <div>
            {user && user.verified === false && (
              <div className="text-center">
                <div style={{color: '#fb6f6f', fontWeight: 500}}>Not Verified</div>
                {message !== 'success' ? (
                  <div onClick={() => resendVerification()} className="hexoscope-external-link d-flex">
                    Resend verification email {loading && <Loader />}
                  </div>
                ) : (
                  <div className="text-hexoscope mb-4">Verification sent</div>
                )}
              </div>
            )}
          </div>
          <>
            <ModalComponent
              isModalOpen={verificationSentModal}
              text={
                message === 'error'
                  ? 'Verification Failed!'
                  : message === 'Please try few minutes later.'
                  ? 'Please try few minutes later.'
                  : 'Please check the last verification email received for your account confirmation link.'
              }
              actionBtnText={'OK'}
              actionBtnColor={message === 'error' || message === 'Please try few minutes later.' ? 'danger' : 'success'}
              onActionClick={() => setVerificationSentModal(false)}
              escButtonClick={() => setVerificationSentModal(false)}
            />
            <ModalComponent
              isModalOpen={!!formData.errorMessage.length || !!formData.successMessage.length}
              text={formData.error ? formData.errorMessage : formData.successMessage}
              actionBtnText={'OK'}
              actionBtnColor={formData.error ? 'danger' : 'success'}
              onActionClick={() =>
                setFormData({
                  email: '',
                  error: false,
                  errorMessage: '',
                  successMessage: '',
                })
              }
              escButtonClick={() =>
                setFormData({
                  email: '',
                  error: false,
                  errorMessage: '',
                  successMessage: '',
                })
              }
            />
          </>
        </div>
        <div className="profile_content position-relative">
          <div className="profile-header">
            {menuLinks.map(item => (
              <NavLink to={item.path} key={item.name} className="links" end>
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr />
          <div className="content row" style={{height: 'calc(100% - 100px)'}}>
            <Routes>
              <Route path="/" element={<ProfileSettings />} />
              <Route path="/my-plans" element={<MyPlans />} />
              <Route path="/payment-methods" element={<PaymentMethods />} />
              <Route path="/promo-codes" element={<PromoCodeForm />} />
              <Route path="/security" element={<Security />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
