import { memo, FC } from 'react';
import { checkedCircleIcon, removeCircleIcon } from 'images/svg-icons';
import './styles.scss';
interface IAlert {
  type?: 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  children?: any;
  className?: string;
  text?: string;
  showIcon?: boolean;
}
const Alert: FC<IAlert> = ({ type = 'success', children, className, text, showIcon }) => (
  <div id="alert-wrapper" className={`alert alert-${type} ${className ? className : ''}`} role="alert">
    {showIcon && (
      <span className="icon-wrapper">
        {type === 'success' ? checkedCircleIcon : type === 'danger' && removeCircleIcon}
      </span>
    )}
    <span>{text}</span>

    {children}
  </div>
);

export default memo(Alert);
