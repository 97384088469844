import { FC, useEffect, useState, useContext, memo } from 'react';
import { useMutation } from '@apollo/client';
import useSetUser from 'hooks/useSetUser';
import moment from 'moment';
import Alert from '../../components/atom/alert';
import Loader from '../../components/atom/loader';
import { UserContext } from '../../contexts/user-context';
import { SCHEDULE_USER_TO_DELETE } from '../../graphql/mutations';
import '../layout.scss';

const HeaderAlert: FC = () => {
  //!-------------Helpers------------------
  const [getUser] = useSetUser('user');
  //!-------------Context------------------
  const { user } = useContext(UserContext);
  //!--------------State-------------------
  //Boolean
  const [showAlert, setShowAlert] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  //String
  const [shouldBeDeletedAt, setShouldBeDeletedAt] = useState<any>('');
  //!-------------Mutation--------------------
  const [cancelMutation, { loading: deleteLoading }] = useMutation(SCHEDULE_USER_TO_DELETE);
  //!-------------useEffect-------------------
  useEffect(() => {
    if (user) {
      let shouldBeDeletedAt = user.shouldBeDeletedAt || '';
      setShouldBeDeletedAt(shouldBeDeletedAt);
      setIsAlert(!!shouldBeDeletedAt);
    }
  }, [user]);
  //!------------------Functions-----------------
  const handleCancel = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setShowCancel(false);
    }, 3000);
  };

  const cancelAccountDelete = async () => {
    setShowCancel(true);
    const { data } = await cancelMutation({ variables: { cancel: true } });
    if (!data) return;
    const {
      UserOps: {
        scheduleToDelete: { error },
      },
    } = data;

    if (error) {
      setShowCancel(false);
      return;
    }
    setRefetchLoading(true);
    handleCancel();
    getUser();
    setRefetchLoading(false);
  };
  //!------------------------------------
  return (
    <div className="header-alert">
      {shouldBeDeletedAt && (
        <Alert className="alert-wrapper" type="danger">
          <div className="d-flex justify-content-center text-center align-items-center">
            Your account is scheduled to be deleted {moment(parseInt(shouldBeDeletedAt)).fromNow()}.
            <span
              className={`m-1 alert-danger alert-link cursor-pointer ${showCancel && 'd-none'}`}
              onClick={cancelAccountDelete}
            >
              Cancel Deletion
            </span>
            <span className="ml-2"> {!deleteLoading && !refetchLoading ? null : <Loader className="p-0" />}</span>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default memo(HeaderAlert);
