import { FC, useState } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { useContext } from 'react';
import { getDate, getDateAndTime } from 'helpers';
import Loader from 'components/atom/loader';
import CustomModal from 'components/molecule/customModal';
import PaymentHistory from './paymentHistory';
import { UserContext } from 'contexts/user-context';
import ModalComponent from 'components/molecule/modal';
import { CANCEL_SUBSCRIPTION } from 'graphql/mutations';
import Message from 'components/atom/alert';
import CancelFeedbackModal from '../components/cancel-feedback-modal';
import { useMutation } from '@apollo/client';

interface IPlan {
  logo?: any;
  whiteLogo?: any;
  children?: React.ReactNode;
  packageInfo: string;
  name: any;
  productName: string;
  href?: string;
  billingInfo?: any;
  showBillingInfo?: boolean;
  hexoscope?: boolean;
  loading?: boolean;
  premiumCredits?: boolean;
  history?: boolean;
  productType?: string;
  refetch?: any;
  cancel?: boolean;
  status?: 'active' | 'canceled' | 'trial';
  refetchAfterCancelation?: any;
  renewalDate?: any;
  showResume?: boolean;
  hidePlanType?: boolean;
}
const Plan: FC<IPlan> = ({
  logo,
  whiteLogo,
  children,
  packageInfo,
  name,
  productName,
  href,
  billingInfo,
  showBillingInfo,
  hexoscope,
  loading,
  premiumCredits,
  history,
  productType,
  refetch,
  cancel,
  status,
  refetchAfterCancelation,
  renewalDate,
  showResume,
  hidePlanType,
}) => {
  const { theme } = useContext(ThemeContext);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [view, setView] = useState(false);
  const { user } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [cancelSubsribtion, { loading: cancelLoading }] = useMutation(CANCEL_SUBSCRIPTION);
  const handleOpen = () => {
    if (status === 'active' || status === 'trial' || !status) {
      setFeedbackModalOpen(true);
    } else {
      setIsOpenCancelModal(true);
    }
  };
  const handleClose = () => setIsOpenCancelModal(false);
  const handleCancelSubscription = async (arr?: any) => {
    const res = await cancelSubsribtion({
      variables: {
        settings: {
          productType: productType,
          cancelAtPeriodEnd: status === 'active' || status === 'trial' || !status,
          feedback:
            (status === 'active' || status === 'trial' || !status) && arr && Array.isArray(arr) ? arr : undefined,
        },
      },
    });
    res?.data && setIsOpenCancelModal(false);
    res?.data && setFeedbackModalOpen(false);
    if (res?.data?.BillingOps?.cancelSubscription?.error_code) {
      const err = res?.data?.BillingOps?.cancelSubscription?.error_code.includes(
        'no-payment-method-to-resume-subscription',
      )
        ? 'Subscription can not be resumed, no payment method.'
        : 'Something went wrong.';
      setErrorMessage(err);
      setTimeout(() => setErrorMessage(''), 7000);
      return;
    }
    refetchAfterCancelation();
  };

  return (
    <>
      <div className="package-info-container mb-4">
        {loading ? (
          <div className="mx-auto my-5">
            <Loader />
          </div>
        ) : (
          <>
            <div className="plans-header">
              {((typeof name === 'string' && name) || premiumCredits) && (
                <h5 className="plans-title">
                  {premiumCredits ? 'Premium Credits' : 'Plan Name:'} {name}{' '}
                  {status === 'trial' && !name?.includes('Trial') ? 'Trial' : ''}
                </h5>
              )}
              {!premiumCredits && (
                <div className="logo-container">
                  <img
                    src={theme === 'light' ? logo : whiteLogo}
                    alt="Logo"
                    className={productType === 'hexofy' ? 'hexofy' : ''}
                  />
                </div>
              )}
            </div>
            <div className="plans-body">
              <h5 className="plans-details">Details</h5>
              {(name || packageInfo) && !hidePlanType && (
                <p className="plans-info-line">
                  <span className="info-name">Plan Type:</span>
                  <span className="info-value">{packageInfo}</span>
                </p>
              )}
              {hidePlanType && renewalDate && (
                <p className="plans-info-line">
                  <span className="info-name">Plan expiration date:</span>
                  <span className="info-value">{getDateAndTime(renewalDate)}</span>
                </p>
              )}
              {children}
              {showBillingInfo && billingInfo && !hidePlanType && (
                <>
                  {billingInfo.last_billing_date && (
                    <p className="plans-info-line">
                      <span className="info-name">Last Billing Date:</span>
                      <span className="info-value">{getDateAndTime(billingInfo.last_billing_date)}</span>
                    </p>
                  )}
                  {billingInfo.next_billing_date && (
                    <p className="plans-info-line">
                      <span className="info-name">Next Billing Date:</span>
                      <span className="info-value">{getDateAndTime(billingInfo.next_billing_date)}</span>
                    </p>
                  )}
                </>
              )}
            </div>
            {history && (
              <div className="cursor-pointer view-payment-history" onClick={() => setView(!view)}>
                {!view ? 'View ' : 'Hide '}payment history
              </div>
            )}
            {view && (
              <div className="cursor-pointer payment-history">
                <PaymentHistory productType={productType || ''} />
              </div>
            )}
            <div className={`plans-footer justify-content-end ${hexoscope ? 'hexoscope-temp' : ''}`}>
              {premiumCredits ? (
                user?.premium_credits_pricing_package !== 'PC_264000' &&
                user?.premium_credits_pricing_package !== 'PC_26400' && (
                  // <span className="upgrade-link cursor-pointer" onClick={() => setOpenPaymentModal(true)}>
                  //   + Add more
                  // </span>
                  <></>
                )
              ) : (
                <div>
                  {' '}
                  {hexoscope ? (
                    <a href="https://hexoscope.com/#pricing" className="clickable-text">
                      Upgrade Plan
                    </a>
                  ) : (
                    <a href={href} className="clickable-text">
                      Upgrade Plan
                    </a>
                  )}
                </div>
              )}
            </div>
            {cancel && (
              <div className="cancel_wrap text-right">
                {status === 'active' || status === 'trial' || !status ? (
                  <span className="cancel-text" onClick={handleOpen}>
                    Cancel subscription renewal
                  </span>
                ) : (
                  <span className="cancel_wrap">
                    Your subscription will be canceled on {getDate(renewalDate)}.{' '}
                    {showResume && (
                      <span className="cancel-text undo" onClick={handleOpen}>
                        Resume subscription
                      </span>
                    )}
                  </span>
                )}
              </div>
            )}
            {errorMessage && <Message type="danger" text={errorMessage} className="py-2 mr-2" />}
          </>
        )}
      </div>
      
      {feedbackModalOpen && (
        <div className="cancellation-feedback-modal">
          <CustomModal ShowPopup={feedbackModalOpen} noNavigate escButtonClick={setFeedbackModalOpen}>
            <CancelFeedbackModal
              handleCancelMyPlanClick={handleCancelSubscription}
              onClose={() => setFeedbackModalOpen(false)}
              productName={productName}
              loading={cancelLoading}
            />
          </CustomModal>
        </div>
      )}
      <ModalComponent
        isModalOpen={isOpenCancelModal}
        text={`Please confirm  ${
          status === 'active' || status === 'trial' || !status
            ? `that you want to cancel your subscription renewal`
            : 'that you would like to resume your subscription.'
        }.`}
        actionBtnText={'Confirm'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'danger'}
        cancelBtnColor={'secondary'}
        onActionClick={handleCancelSubscription}
        onCancelClick={handleClose}
        loading={cancelLoading}
        escButtonClick={handleClose}
      />
    </>
  );
};

export default Plan;
