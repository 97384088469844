import {gql} from '@apollo/client';

const mutationFields = `
  error
  message
`;

export const LOG_OUT = gql`
  mutation LOG_OUT($id: ID) {
    UserOps {
      logout(id: $id) {
        error
        message
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation USER_LOGIN($email: String!, $password: String!, $productType: String!) {
    UserOps {
      login(email: $email, password: $password, productType: $productType) {
        error
        message
        token
        hash
        twofa_status
        device_key
        refresh_token
      }
    }
  }
`;

export const SCHEDULE_USER_TO_DELETE = gql`
  mutation SCHEDULE_USER_TO_DELETE($cancel: Boolean) {
    UserOps {
      scheduleToDelete(cancel: $cancel) {
        error
        message
      }
    }
  }
`;

export const SEND_USER_FEEDBACK = gql`
  mutation SEND_USER_FEEDBACK($settings: CreateSuggestionFeedbackInput) {
    HexomaticUserOps {
      createSuggestionFeedback(settings: $settings) {
        error_code
      }
    }
  }
`;

export const ADD_SHORTCUT_LOG = gql`
  mutation ADD_SHORTCUT_LOG($type: String) {
    ShortcutLogOps {
      addShortcutLog(type: $type) {
        error
        message
      }
    }
  }
`;

export const PAYPAL_UPGRADE = gql`
  mutation PAYPAL_UPGRADE($settings: ExecutePaypalPostActionInput) {
    BillingOps {
      executePaypalPostAction(settings: $settings) {
        error_code
      }
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation RESEND_VERIFICATION_EMAIL {
    UserOps {
      resendVerification {
        error
        message
      }
    }
  }
`;

export const UPDATE_USER_INFORMATION = gql`
  mutation UpdateUserInformation($settings: UserInformationInput) {
    UserOps {
      updateInformation(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation UserResetPassword($password: String!, $verifyCode: String!, $device_key: String) {
    UserOps {
      resetPassword(password: $password, verifyCode: $verifyCode, device_key: $device_key) {
        ${mutationFields}
      }
    }
  }
`;

export const GET_DEVICES = gql`
  mutation userDeviceList($action: String!, $id: ID!, $status: Boolean!, $password: String, $product_type: String) {
    UserOps {
      userDeviceList(action: $action, id: $id, status: $status, password: $password, product_type: $product_type) {
        list {
          created_at
          updated_at
          user_agent
          product_type
          status
          id
          key
        }
        message
      }
    }
  }
`;

export const LOG_OUT_ALL_DEVICES = gql`
  mutation logoutFromAllDevices($password: String) {
    UserOps {
      logoutFromAllDevices(password: $password) {
        error
        message
      }
    }
  }
`;

export const ADD_CARD = gql`
  mutation addCard($settings: AddCardInput) {
    BillingOps {
      addCard(settings: $settings) {
        error_code
        cardId
      }
    }
  }
`;

export const DELETE_CREDIT_CARD = gql`
  mutation deleteCreditCard($settings: DeleteCardInput) {
    BillingOps {
      deleteCreditCard(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_STRIPE_CUSTEMER = gql`
  mutation updateStripeCustemer($settings: UpdateStripeCustomerInput) {
    BillingOps {
      updateStripeCustomer(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($settings: CancelSubscriptionInput) {
    BillingOps {
      cancelSubscription(settings: $settings) {
        error_code
      }
    }
  }
`;

export const LOG_OUT_TWO_FACTOR = gql`
  mutation twofaLogoutFromAllDevices {
    UserOps {
      twofaLogoutFromAllDevices {
        error
        message
      }
    }
  }
`;

export const TWO_FACTOR_APP = gql`
  mutation twofaRegister($productType: String, $password: String, $type: String) {
    UserOps {
      twofaRegister(productType: $productType, password: $password, type: $type) {
        error
        message
        token
        hash
        base32
      }
    }
  }
`;

export const TWO_FACTOR_SETTINGS = gql`
  mutation twofaSettings($status: Boolean, $type: String, $password: String) {
    UserOps {
      twofaSettings(status: $status, type: $type, password: $password) {
        error
        message
      }
    }
  }
`;
export const USER_LOGIN_TWO_FACTOR = gql`
  mutation twofaVerify($email: String!, $code: String!, $productType: String!, $base32: String) {
    UserOps {
      twofaVerify(email: $email, code: $code, productType: $productType, base32: $base32) {
        error
        message
        token
        hash
      }
    }
  }
`;

export const UPDATE_BUSINESS_TYPE = gql`
  mutation UPDATE_BUSINESS_TYPE($settings: UpdateHexoscopeTypeInput) {
    HexoscopeUserOps {
      updateHexoscopeType(settings: $settings) {
        success
      }
    }
  }
`;
