import {getDateAndTime} from 'helpers';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getAllScans} from 'requests';
import Badge from 'components/atom/badge';
import {longArrowRight} from 'images/svg-icons';
import NothingToShow from 'components/atom/nothing-to-show';
import './styles.scss';

const History = () => {
  const {scope_id} = useParams();
  const [historyList, setHistoryList] = useState([] as any);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const resp = await getAllScans(scope_id);
      //@ts-ignore
      if (resp?.data) {
        //@ts-ignore
        setHistoryList(resp?.data);
      }
      setLoading(false);
    };

    if (scope_id) {
      getData();
    }
  }, [scope_id]);

  return (
    <div>
      <div className="page-title">History</div>
      {loading ? (
        <>
         <div className="history-mock-item"></div>
         <div className="history-mock-item"></div>
         <div className="history-mock-item"></div>
         <div className="history-mock-item"></div>
         <div className="history-mock-item"></div>
        </>
      ) : !historyList?.length ? (
        <NothingToShow />
      ) : (
        historyList?.map((item: any) => (
          <div className="history-item" onClick={() => navigate(`/scope/${scope_id}/${item.id}`)}>
            <div className="scan-date d-flex primary-color my-auto mr-2">
              <div className="my-auto mr-2">{getDateAndTime(item.updated_at)}</div>
              <div className="my-auto ml-2">
                <Badge
                  messageType={
                    item.status === 'completed'
                      ? 'badge-success'
                      : item.status === 'scanning' || item.status === 'started'
                      ? 'badge-primary'
                      : 'badge-alert'
                  }
                  buttonType="light-background"
                >
                  <span>{item.status}</span>
                </Badge>
              </div>
            </div>
            <div className="clickable-text my-auto">See result {longArrowRight}</div>
          </div>
        ))
      )}
    </div>
  );
};

export default History;
