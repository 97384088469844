import {FC, useState, useContext, useEffect, Fragment, memo, useReducer, useCallback, useMemo} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {UserContext} from '../contexts/user-context';
import {SidebarToggleContext} from '../contexts/sidebar-toggle-context';

import Header from './components/header';
import Footer from './components/footer';
import RightSidebar from './components/right-sidebar';

import Button from 'components/atom/button';
import FeedbackModal from 'components/atom/feedback-modal';
import VerificationEmail from 'components/organism/verifcationEmail';
import Modal from 'components/molecule/modal';

import {ADD_SHORTCUT_LOG, PAYPAL_UPGRADE} from '../graphql/mutations';

import {getCookie, getItemFromLocalStorage} from '../helpers';

import {
  mailIcon,
  feedbackIcon,
  sidebarCloseIcon,
  sidebarOpenIcon,
  sidebarNewBtnIcon,
  dashboardIcon,
  settingsIcon,
  longArrowRight,
  refreshIcon,
  scansIcon,
} from 'images/svg-icons';
import {LayoutProps} from './types';
import './layout.scss';
import {ScopesContext} from 'contexts/scopes-context';

const getRightSidebarStatus = (): boolean => {
  const item = getItemFromLocalStorage('products-sidebar');
  localStorage.removeItem('r-sidebar');
  return !!item;
};

const Layout: FC<LayoutProps> = ({children}) => {
  //!---------------Context-------------------
  const {user} = useContext(UserContext);

  //!----------------Params-------------------
  const {pathname} = useLocation();
  const navigate = useNavigate();

  //!----------------State--------------------
  //Boolean
  const [toggle, setToggle] = useState(!!(window?.innerWidth > 575));
  const [rightToggle, setRightToggle] = useState(getRightSidebarStatus);
  const {selectedScope} = useContext(ScopesContext);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [isSuccessfullySent, setIsSuccessfullySent] = useReducer((val: boolean) => !val, false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [menuSettings, setMenuSettings] = useState([
    {
      icon: dashboardIcon,
      title: 'Dashboard',
      path: `/scope/${selectedScope?.id}/${selectedScope?.last_scan_id}`,
      arrow: false,
      disabled: !selectedScope?.id,
    },
    {
      icon: settingsIcon,
      title: 'Settings',
      path: `/scope/${selectedScope?.id}/settings`,
      arrow: false,
      disabled: !selectedScope?.id,
    },
  ]);

  useEffect(() => {
    setMenuSettings([
      {
        icon: dashboardIcon,
        title: 'Dashboard',
        path: `/scope/${selectedScope?.id}/${selectedScope?.last_scan_id}`,
        arrow: false,
        disabled: !selectedScope?.id,
      },
      {
        icon: settingsIcon,
        title: 'Settings',
        path: `/scope/${selectedScope?.id}/settings`,
        arrow: false,
        disabled: !selectedScope?.id,
      },
    ]);
  }, [selectedScope]);

  const [addShortCutLog] = useMutation(ADD_SHORTCUT_LOG, {
    variables: {
      type: 'hexoscope',
    },
  });
  const [paypalToken] = useMutation(PAYPAL_UPGRADE);
  //!-----------------useEffect-----------------

  useEffect(() => {
    check();
    if (window.location.href.includes('&ba_token=')) {
      const token = window.location.href.match('(?<=&ba_token=)(.*)(?=&token=)');
      token &&
        token[0] &&
        sendToken(token[0], window.location.search?.includes('premium_credit') ? 'premium_credits' : 'hexoscope');
    }
  }, []);

  useEffect(() => {
    if (!getCookie('user-token')) {
      window.location.href.indexOf('localhost') > -1
        ? window.location.replace('http://localhost:3000/dev-login')
        : window.location.href.indexOf('37.186.119.181') > -1
        ? window.location.replace('http://37.186.119.181:3279/dev-login')
        : window.location.href.indexOf('dashhexoscope.hexact.io') > -1
        ? window.location.replace('https://hexoscope.hexact.io')
        : window.location.replace('https://hexoscope.com');
    }
  }, [getCookie('user-token')]);
  //!----------------Function----------------------
  const check = useCallback(async () => {
    if (window.matchMedia('(display-mode: standalone)').matches && !sessionStorage.getItem('standalone')) {
      sessionStorage.setItem('standalone', 'true');
      await addShortCutLog();
    }
  }, [addShortCutLog]);

  const sendToken = useCallback(
    async (token: string, productType: string) => {
      const {data} = await paypalToken({variables: {settings: {baToken: token, productType}}});
      if (
        data &&
        data.BillingOps &&
        data.BillingOps.executePaypalPostAction &&
        !data.BillingOps.executePaypalPostAction.error_code
      ) {
        window.close();
      }
    },
    [paypalToken],
  );

  const handleSetFeedbackModal = useCallback(() => {
    user && setFeedbackModal(true);
  }, [user]);

  const handleCloseFeedbackModal = useCallback(() => {
    setFeedbackModal(false);
  }, []);

  window.addEventListener('resize', check);

  const headerEl = document.getElementById('header');
  const headerHeight = headerEl && headerEl.offsetHeight;

  const navEl = document.getElementById('nav');
  const navElH = navEl && navEl.offsetHeight;

  useEffect(() => {
    if (user && !user.verified) {
      setShowVerificationModal(true);
    }
  }, [user]);

  const closeModal = () => setShowVerificationModal(false);

  //!-----------------------------------
  return (
    <div className={`page-container ${toggle ? 'with-sidebar' : ''}`}>
      <Header toggle={toggle} setToggle={setToggle} user={user} logo={false} />
      <SidebarToggleContext.Provider value={{opened: toggle, rightOpened: rightToggle}}>
        <div
          className={`body ${toggle ? 'body-pd' : ''} ${rightToggle ? 'body-r-pd' : ''} ${
            user && user.shouldBeDeletedAt ? 'alert-space-padding' : ''
          } ${pathname === '/pricing' ? 'body-pricing' : ''} ${
            pathname.includes('/scope/create') || pathname.includes('/profile') ? 'no-padding' : ''
          }`}
        >
          {!pathname.includes('/scope/create') && !pathname.includes('/profile') && (
            <div
              className={`l-navbar ${toggle ? 'show-sidebar' : 'show-mobile'}`}
              id="nav-bar"
              style={{height: `calc(100vh - ${headerHeight || 60}px)`}}
            >
              <nav id="nav" className="nav">
                <div>
                  {/* <div className="sidebar-create-new">
                    {toggle ? (
                      <Button
                        name="New"
                        leftIcon={sidebarNewBtnIcon}
                        outline
                        className={`${toggle ? '' : 'pr-0 pl-1'}`}
                        onClick={() => navigate('/scope/create')}
                        disabled={!selectedScope?.id}
                      />
                    ) : (
                      <div className="closed-sidebar-new-btn">
                        <span onClick={() => navigate('/scope/create')} className="text-center">
                          {sidebarNewBtnIcon}
                        </span>
                      </div>
                    )}
                  </div> */}

                  <div className="nav__list" style={{marginTop: '56px'}}>
                    {menuSettings.map(obj => (
                      <Fragment key={obj.path}>
                        <NavLink
                          key={obj.path}
                          to={obj.path}
                          className={`nav__link ${toggle ? 'sidebar-open' : ''} ${obj.disabled ? 'disabled' : ''}`}
                        >
                          <span className="nav-link-left-icon">{obj.icon}</span>
                          <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>{obj.title}</span>
                          {obj.arrow && <span className="nav-link-right-icon">{longArrowRight}</span>}
                        </NavLink>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="sidebar-collapse d-flex">
                  <button onClick={() => setToggle(prev => !prev)} className={`sidebar-collapse-button`}>
                    {toggle ? sidebarCloseIcon : sidebarOpenIcon}
                  </button>
                </div>

                <div className="sidebar__block__bottom mt-0">
                  <a
                    role="button"
                    className={`${toggle ? 'toggle' : ''} nav__link__feedback`}
                    onClick={handleSetFeedbackModal}
                  >
                    {feedbackIcon} <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>Feedback</span>
                  </a>

                  <a className={`${toggle ? 'toggle' : ''} nav__link__email`} href="mailto:support@hexoscope.com">
                    {mailIcon}
                    <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>Contact Support</span>
                  </a>
                </div>
              </nav>
            </div>
          )}
          <div className="mt-3">{children}</div>

          <RightSidebar
            navElH={navElH}
            headerHeight={headerHeight}
            rightToggle={rightToggle}
            setRightToggle={setRightToggle}
          />
        </div>
      </SidebarToggleContext.Provider>

      <Footer toggle={toggle} noPadding={pathname.includes('/profile') || pathname.includes('/scope/create')} />
      {showVerificationModal && !localStorage.getItem('show-integration-popup') && (
        <Modal
          isModalOpen={showVerificationModal}
          actionBtnText=""
          actionBtnColor="transparent"
          text={<VerificationEmail />}
          onCloseModal={closeModal}
          onlyBtns
          className="p-0"
          escButtonClick={closeModal}
        />
      )}

      {feedbackModal && (
        <Modal
          isModalOpen={feedbackModal}
          actionBtnText=""
          onActionClick={setIsSuccessfullySent}
          actionBtnColor="transparent"
          text={<FeedbackModal setIsSuccessfullySent={setIsSuccessfullySent} onClose={handleCloseFeedbackModal} />}
          onCloseModal={handleCloseFeedbackModal}
          onlyBtns
          className="p-0"
          parentClass="feedback-modal"
          escButtonClick={handleCloseFeedbackModal}
        />
      )}

      {isSuccessfullySent && (
        <Modal
          isModalOpen={isSuccessfullySent}
          text="Feedback has been successfully sent"
          actionBtnText="Ok"
          actionBtnColor="success"
          cancelBtnColor="primary"
          onActionClick={setIsSuccessfullySent}
          escButtonClick={setIsSuccessfullySent}
        />
      )}
    </div>
  );
};

export default memo(Layout);
