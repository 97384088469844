import { FC, useCallback, memo } from 'react';
import { eventStop } from 'helpers';
import { checkedIcon, checkIcon } from 'images/svg-icons';
import './styles.scss';

const Checkbox: FC<{
  checked: boolean;
  disabled?: boolean;
  click: (arg0: boolean, element?: any) => void;
  isBlue?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  element?: any;
  label?: string;
  className?: string;
}> = ({ checked, disabled, click, isBlue, leftLabel, rightLabel, element, label, className }) => {
  const handelClick = useCallback(
    (e?: any) => {
      if (!disabled) {
        eventStop(e);
        click(!checked, element);
      }
    },
    [disabled, click, checked, element],
  );

  return (
    <div className={`noselect d-flex ${className ? className : ''}`}>
      {leftLabel && (
        <label className="mr-2 cursor-pointer" onClick={handelClick}>
          {leftLabel}
        </label>
      )}
      {checked ? (
        <div
          className={`custom-checkbox checked ${disabled ? 'disabled' : ''} ${isBlue ? 'blue' : ''}`}
          onClick={handelClick}
        >
          {disabled ? checkedIcon : checkIcon}
        </div>
      ) : (
        <div className={`custom-checkbox ${disabled ? 'disabled' : ''}`} onClick={handelClick} />
      )}
      {rightLabel && (
        <label className="ml-2 cursor-pointer" onClick={handelClick}>
          {rightLabel}
        </label>
      )}
      {label && (
        <span className="ml-2 secondary-color" onClick={handelClick}>
          {label}
        </span>
      )}
    </div>
  );
};

export default memo(Checkbox);
