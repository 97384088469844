import { FC, useEffect, useState, useContext, memo, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { ThemeContext } from 'contexts/theme-context';
import { closePopupOnEsc } from 'helpers';
import { ModalProps } from './types';

import Button from 'components/atom/button';
import Loader from 'components/atom/loader';

import { removeCircleIcon, checkedCircleIcon, closeIcon, warningCircleIcon } from 'images/svg-icons';
import './styles.scss';

const ModalComponent: FC<ModalProps> = ({
  isModalOpen,
  text,
  title,
  escButtonClick,
  onActionClick,
  onCancelClick,
  onCancelClick2,
  actionBtnText,
  cancelBtnText,
  cancelBtnText2,
  actionBtnColor,
  onCloseModal,
  loading,
  elementContent,
  onlyBtns,
  className,
  children,
  parentClass,
  icon,
  cancelBtnDisabled,
  cancelBtnDisabled2,
  actionBtnDisabled,
  topInfo,
  hideIcon,
  hideOverflow,
  popupClick,
  uniquePopupId,
  wrapButtons,
}) => {
  const { theme } = useContext(ThemeContext);

  const body = useMemo(() => document.getElementsByTagName('body')[0], [document.getElementsByTagName('body')]);
  const el = useMemo(() => document.createElement('div'), []);

  //! uniquePopupId is important for avoiding popup name collisions
  const [element, setElement] = useState<HTMLDivElement>(el);
  const [popupId] = useState(() => uniquePopupId || 'popup');

  useEffect(() => {
    el.setAttribute('id', popupId);
    setElement(el);
  }, [el]);

  useEffect(() => {
    if (!isModalOpen) return;
    body.appendChild(element);
    document.body.style.overflow = 'hidden';
    return () => {
      const getEl = document.getElementById(popupId);
      getEl && body.removeChild(getEl);
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (!hideOverflow) return;
    const getEl = document.getElementById(popupId);
    getEl?.setAttribute('class', `overflow-hidden ${parentClass ? `${parentClass}-popup` : ''}`);

    return () => {
      getEl?.removeAttribute('class');
    };
  }, [hideOverflow]);

  useEffect(() => {
    const getEl = document.getElementById(popupId);
    if (getEl && popupClick)
      getEl.onclick = function (e: any) {
        if (e.target.id === popupId) {
          popupClick();
        }
      };
  }, [popupClick]);

  //! Close Popup on Esc button click

  useEffect(() => {
    const [addEvent, removeEvent] = closePopupOnEsc(() => escButtonClick && escButtonClick());
    if (isModalOpen) addEvent();
    return () => removeEvent();
  }, [isModalOpen]);

  const getLength = useCallback((text: string) => text.split(' ').find((item: any) => item.length > 30), []);

  const handleClick = useCallback(() => onActionClick && onActionClick(), [onActionClick]);

  return isModalOpen
    ? createPortal(
        <>
          <div id="custom-modal" className={`overlay theme-${theme} ${parentClass || ''}`}>
            <div className={`modal-dialog--default custom-alert-block theme-${theme} ${parentClass || ''}`}>
              <div className="modal-content">
                {loading ? (
                  <div className="modal__body">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="modal__body">
                      {onCloseModal && (
                        <div className="panel__btns">
                          <button className="panel__btn btn" onClick={onCloseModal} type="button">
                            {closeIcon}
                          </button>
                        </div>
                      )}
                      {topInfo && topInfo}
                      {!onlyBtns &&
                        !hideIcon &&
                        (icon ? (
                          <span className="modal-head-icon">{icon}</span>
                        ) : actionBtnColor === 'success' ? (
                          <span className="modal-head-icon">{checkedCircleIcon}</span>
                        ) : actionBtnColor === 'warning' ? (
                          <span className="modal-head-icon">{warningCircleIcon}</span>
                        ) : actionBtnColor === 'none' ? (
                          ''
                        ) : (
                          <span className="modal-head-icon">{removeCircleIcon}</span>
                        ))}
                      {title && <span className="text title">{title}</span>}
                      {elementContent ? (
                        elementContent
                      ) : (
                        <span
                          className={`text ${typeof text === 'string' && getLength(text) ? 'break' : ''} ${
                            className || ''
                          }`}
                        >
                          {text}
                        </span>
                      )}
                    </div>
                    {onlyBtns ? (
                      <div className={`modal__footer ${cancelBtnText ? 'cancel-is' : ''} d-block mt-0`}>
                        {actionBtnText && (
                          <div className="mb-2">
                            <Button
                              name={actionBtnText}
                              onClick={handleClick}
                              className="m-auto modal-long-btn"
                              disabled={actionBtnDisabled}
                            />
                          </div>
                        )}
                        {cancelBtnText2 && (
                          <div className="mb-2">
                            <Button
                              name={cancelBtnText2}
                              outline
                              onClick={onCancelClick2}
                              className="m-auto modal-long-btn"
                              disabled={cancelBtnDisabled2}
                            />
                          </div>
                        )}

                        {cancelBtnText && (
                          <div>
                            <Button
                              name={cancelBtnText}
                              outline
                              onClick={onCancelClick}
                              className="m-auto modal-long-btn"
                              disabled={cancelBtnDisabled}
                            />
                          </div>
                        )}
                      </div>
                    ) : cancelBtnText || cancelBtnText2 || actionBtnText ? (
                      <div
                        className={`modal__footer ${cancelBtnText ? 'cancel-is' : ''} ${
                          wrapButtons ? 'd-block d-xl-flex' : ''
                        }`}
                      >
                        {cancelBtnText && (
                          <div className={`${wrapButtons ? 'mb-2 mr-0 mr-xl-2 mb-xl-0 ' : 'mr-2'}`}>
                            <Button
                              name={cancelBtnText}
                              outline
                              onClick={onCancelClick}
                              disabled={cancelBtnDisabled}
                              className={`${wrapButtons ? 'mx-auto mx-0' : ''}`}
                            />
                          </div>
                        )}
                        {cancelBtnText2 && (
                          <div className="cancel-btn-wrapper mt-2">
                            <Button
                              name={cancelBtnText2}
                              outline
                              onClick={onCancelClick2}
                              disabled={cancelBtnDisabled2}
                            />
                          </div>
                        )}
                        {actionBtnText && (
                          <div>
                            <Button
                              name={actionBtnText}
                              onClick={handleClick}
                              disabled={actionBtnDisabled}
                              className={`${wrapButtons ? 'mx-auto mx-0' : ''}`}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                {children && <div className="mt-0 children-wrapper">{children}</div>}
              </div>
            </div>
          </div>
        </>,
        element,
      )
    : null;
};

export default memo(ModalComponent);
