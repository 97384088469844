import axios from 'axios';
import Cookies from 'js-cookie';

axios.interceptors.request.use(function (config) {
  const token = Cookies.get('__session');
  const pasredToken = token && JSON.parse(token);
  pasredToken && pasredToken['user-token'] && (config.headers.Authorization = pasredToken['user-token']);
  config.baseURL =
    window.location.hostname === 'hexoscope.com'
      ? 'https://api.hexoscope.com/request'
      : 'https://api.hexoscope.com/request';

  config.headers['Content-Type'] = 'application/json';
  return config;
});

export const isValidEmail = mail => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
};

export const getCategories = async () => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'categories',
        action: 'get_list',
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const addScope = async data => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'add',
        data,
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const updateScope = async (data, scope_id) => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'update_scope',
        scope_id,
        data,
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const deleteScope = async scope_id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'delete_scope',
        data: {
          scope_id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const cancelScopeDelete = async scope_id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'restore_deleted',
        data: {
          scope_id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const startScan = async id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'start_scan',
        data: {
          scope_id: id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getScanById = async id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'get_scan_by_id',
        data: {
          scan_id: id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getScopeById = async id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'get_by_id',
        data: {
          scope_id: id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getAllScopes = async () => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'get_list',
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getAllScans = async scope_id => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'get_scans_by_scope_id',
        data: {
          scope_id,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};

export const getCSV = async (scan_id, module) => {
  try {
    const result = await axios(``, {
      method: 'POST',
      data: {
        service: 'scopes',
        action: 'export',
        data: {
          scan_id,
          module,
        },
      },
    });
    return result;
  } catch (error) {
    return {error: error && error.response && error.response.data ? error.response.data.error : error};
  }
};
