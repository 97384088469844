import {FC, memo, useCallback, useEffect, useMemo, useRef} from 'react';
import Loader from '../loader/index';
import {ButtonTypes} from './types';
import './style.scss';

const Button: FC<ButtonTypes> = ({
  name,
  outline,
  outlineSecondary,
  secondary,
  success,
  danger,
  disabled,
  loading,
  leftIcon,
  rightIcon,
  children,
  className,
  onClick,
  gradient,
  active,
  width,
  setWidth,
  type = 'button',
  btn_name,
  id = '',
  round,
  sm,
}) => {
  //!------------------------ref------------------------
  const ref = useRef<any>(null);
  //!-----------------------useMemo---------------------
  const selectWidth = useMemo(() => {
    return {
      width: width ? width : '',
    };
  }, [width]);
  //!---------------------useEffect---------------------
  useEffect(() => {
    if (setWidth && ref?.current?.clientWidth) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);
  //!----------------------Function----------------------
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick && onClick(e);
    },
    [onClick],
  );
  //!----------------------------------------------------
  return (
    <button
      id={id}
      name={btn_name}
      disabled={disabled}
      style={selectWidth}
      onClick={handleClick}
      type={type}
      ref={ref}
      className={`custom-button ${outline ? 'outline' : ''} ${outlineSecondary ? 'outline-secondary' : ''} ${
        danger ? 'danger' : ''
      } ${secondary ? 'secondary' : ''} ${success ? 'success' : ''} ${loading ? 'loading' : ''} ${
        gradient ? 'gradient' : ''
      } ${className ? className : ''} ${disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${round ? 'round' : ''} ${
        sm ? 'sm' : ''
      }`}
    >
      {children ? (
        children
      ) : (
        <>
          <div className={`btn-loader`}>
            <Loader />
          </div>
          {leftIcon && <span className="left-icon mr-2">{leftIcon}</span>}
          <span>{name}</span>
          {rightIcon && <span className="right-icon">{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default memo(Button);
