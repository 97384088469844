import {useContext, useState, useEffect} from 'react';
import {useLazyQuery, useQuery} from '@apollo/client';
import {UserContext} from 'contexts/user-context';
import {getHexometerPackageName, newPackageDescriptions} from 'constants/index';
import {
  BILING_DATES,
  GET_HEXOMATIC_USER,
  GET_HEXOMETER_USER_SETTINGS,
  GET_USER_WATCH_SETTINGS_QUERY,
  HEXOSPARK_USER,
  HEXOFY_USER,
  LIST_PREMIUM_CREDITS,
  USER_QUERY,
  GET_RENEWAL_DATE,
  HEXOSCOPE_USER,
} from 'graphql/queries';
// import { Link } from 'react-router-dom';

import hexometerLogo from 'images/logos/hexometer-logo-rectangle.svg';
import hexometerLogoWhite from 'images/logos/hexometer-logo-rectangle-white.svg';

import hexowatchLogo from 'images/logos/hexowatch-logo-rectangle.svg';
import hexowatchLogoWhite from 'images/logos/hexowatch-logo-rectangle-white.svg';

import hexomaticLogo from 'images/logos/hexomatic-logo-rectangle.svg';
import hexomaticLogoWhite from 'images/logos/hexomatic-logo-rectangle-white.svg';

import hexosparkLogo from 'images/logos/hexospark-logo-rectangle.svg';
import hexosparkLogoWhite from 'images/logos/hexospark-logo-rectangle-white.svg';

import hexofyLogo from 'images/logos/hexofy-logo-rectangle.svg';
import hexofyLogoWhite from 'images/logos/hexofy-logo-rectangle-white.svg';

import hexoscopeLogo from 'images/logos/hexoscope-logo-rectangle.svg';
import hexoscopeLogoWhite from 'images/logos/hexoscope-logo-rectangle-white.svg';

import Plan from './plan-info';

import '../styles.scss';

const packInfo = [
  {
    name: 'FREE',
    maxInterval: '12 hours',
    integrations: false,
    reports: false,
    server: false,
    totalChecks: 75,
  },
  {
    name: 'STANDARD',
    maxInterval: '30 min',
    integrations: true,
    reports: true,
    server: false,
    totalChecks: 2000,
  },
  {
    name: 'PRO',
    maxInterval: '15 min',
    integrations: true,
    reports: true,
    server: false,
    totalChecks: 4500,
  },
  {
    name: 'BUSINESS',
    maxInterval: '5 min',
    integrations: true,
    reports: true,
    server: true,
    totalChecks: 10000,
  },
  {
    name: 'BUSINESS+',
    maxInterval: '5 min',
    integrations: true,
    reports: true,
    server: true,
    totalChecks: null,
  },
  {
    name: 'MICROSOFT',
    maxInterval: '5 min',
    integrations: true,
    reports: true,
    server: true,
    totalChecks: 150000,
  },
];

const MyPlans = () => {
  const [hexometerInfo, setHexometerInfo] = useState(null as any);
  const [hexometerMaxNumber, setHexometerMaxNumber] = useState(0);
  const {user, setUser} = useContext(UserContext);
  const [hexomaticUser, setHexomaticUser] = useState(null as any);
  const [userDataLocal, setUserDataLocal] = useState(user);

  const {
    data: hexosparkUser,
    loading: hexosparkLoading,
    refetch: hexosparkUserRefetch,
  } = useQuery(HEXOSPARK_USER, {
    fetchPolicy: 'no-cache',
  });

  const [pricingPackage, setPricingPackage] = useState('');
  const [hexosparkPlanInfo, setHexosparkPlanInfo] = useState<any>(null);
  const [hexofyPlanInfo, setHexofyPlanInfo] = useState<any>(null);
  const [hexoscopePlanInfo, setHexoscopePlanInfo] = useState<any>(null);

  const [pricingPackageInterval, setPricingPackageInterval] = useState('');
  const {
    data: settingsQuery,
    loading,
    refetch,
  } = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const currentPackage = packInfo.find(item => item.name === pricingPackage);
  const [leftLimits, setLeftLimits] = useState<string | number>('N/A');
  const [monthlyLimit, setMonthlyLimit] = useState(0);
  const [billingInfo, setBillingInfo] = useState<any>(null);
  const [additionalScans, setAdditionalScans] = useState(0);
  const {data: premiumCredits, loading: premiumCreditsLoading} = useQuery(LIST_PREMIUM_CREDITS);

  const {
    data: hexometerData,
    loading: hexometerLoadng,
    refetch: refetchHexometerUser,
  } = useQuery(GET_HEXOMETER_USER_SETTINGS, {
    skip: !(user?.id && +user?.id),
    variables: {user_id: user?.id && +user?.id},
  });

  const {
    data: renewalDate,
    loading: renewalDateLoading,
    refetch: refetchRenewalDate,
  } = useQuery(GET_RENEWAL_DATE, {
    variables: {
      settings: {
        hexomatic: true,
        hexowatch: false,
        hexometer: false,
        premium_credits: true,
      },
    },
  });

  const {
    data: billingData,
    loading: billingLoading,
    refetch: refetchBillingInfo,
  } = useQuery(BILING_DATES, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        productType: '',
      },
    },
  });
  const {
    data: hexofyUser,
    loading: hexofyLoading,
    refetch: hexofyUserRefetch,
  } = useQuery(HEXOFY_USER, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: hexoscopeUser,
    loading: hexoscopeLoading,
    refetch: hexoscopeUserRefetch,
  } = useQuery(HEXOSCOPE_USER, {
    fetchPolicy: 'no-cache',
  });
  const [billingDates, setBillingDates] = useState<null | {
    hexowatch: any;
    hexomatic: any;
    hexometer: any;
    hexospark: any;
    hexofy: any;
    hexoscope: any;
    premium_credits: any;
  }>(null);
  useEffect(() => {
    if (billingData?.Billing?.retrieveUpcomingInvoice?.packagesBillingInfo) {
      setBillingDates(billingData.Billing.retrieveUpcomingInvoice.packagesBillingInfo);
    }
  }, [billingData]);
  useEffect(() => {
    if (hexometerData?.HexometerUserSettings?.get) {
      setHexometerInfo(hexometerData.HexometerUserSettings.get);
      if (hexometerData.HexometerUserSettings.get.pricing_package === 'FREE') {
        setHexometerMaxNumber(1);
      } else {
        const currentPackage = newPackageDescriptions.find(
          (item: any) => item.name === hexometerData?.HexometerUserSettings?.get?.pricing_package,
        );
        currentPackage?.propertyLimit &&
          setHexometerMaxNumber(
            currentPackage?.propertyLimit * (hexometerData?.HexometerUserSettings?.get?.pricing_package_count || 1),
          );
      }
    }
  }, [hexometerData]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (settingsQuery?.UserWatchSettings?.get) {
      const settings = settingsQuery.UserWatchSettings.get;

      if (settings) {
        settings.config?.additional_scans_count && setAdditionalScans(settings.config.additional_scans_count);
        settings.billing_info && setBillingInfo(settings.billing_info);
        settings.left_monthly && setLeftLimits(settings.left_monthly);
        settings.monthly_limit && setMonthlyLimit(settings.monthly_limit);
      }

      if (settings?.pricing_package && settings?.pricing_package_interval) {
        settings.pricing_package.indexOf('BUSINESS_SCANS_10000') > -1 &&
        settings.pricing_package.indexOf('BUSINESS_SCANS_100000') === -1
          ? setPricingPackage('BUSINESS')
          : settings.pricing_package.indexOf('BUSINESS_SCANS') > -1
          ? setPricingPackage('BUSINESS+')
          : setPricingPackage(settings.pricing_package);
        settings.pricing_package.indexOf('FREE') > -1
          ? setPricingPackageInterval('Forever free')
          : setPricingPackageInterval(settings.pricing_package_interval);
      }
    }
  }, [settingsQuery]);

  useEffect(() => {
    if (
      hexosparkUser?.HexosparkUser?.getHexosparkUser?.hsUser?.pricing_package &&
      hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.pricing_package !== 'FREE'
    ) {
      setHexosparkPlanInfo({
        pricing_package: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.pricing_package,
        pricing_package_count: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.pricing_package_count,
        pricing_package_interval: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.pricing_package_interval,
        subscription_status: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.subscription_status,
        paypal_subscription_id: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.paypal_subscription_id,
        billing_info: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.billing_info,
        storage: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.storage,
        teamAccess: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.teamAccess,
        workspaces: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.workspaces,
        emailAccounts: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.emailAccounts,
        totalPromptsCount: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.totalPromptsCount,
        usedPromptsCount: hexosparkUser.HexosparkUser.getHexosparkUser.hsUser.settings.usedPromptsCount,
      });
    }
  }, [hexosparkUser]);

  useEffect(() => {
    if (hexofyUser?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package) {
      setHexofyPlanInfo({
        pricing_package: hexofyUser.HexofyUser.getHexofyUser.hfUser.pricing_package,
        pricing_package_count: hexofyUser.HexofyUser.getHexofyUser.hfUser.pricing_package_count,
        pricing_package_interval: hexofyUser.HexofyUser.getHexofyUser.hfUser.pricing_package_interval,
        subscription_status: hexofyUser.HexofyUser.getHexofyUser.hfUser.subscription_status,
        paypal_subscription_id: hexofyUser.HexofyUser.getHexofyUser.hfUser.paypal_subscription_id,
        billing_info: hexofyUser.HexofyUser.getHexofyUser.hfUser.billing_info,
      });
    }
  }, [hexofyUser]);

  useEffect(() => {
    if (hexoscopeUser?.HexoscopeUser?.getHexoscopeUser?.hsUser?.pricing_package) {
      setHexoscopePlanInfo({
        pricing_package: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.pricing_package,
        pricing_package_count: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.pricing_package_count,
        pricing_package_interval: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.pricing_package_interval,
        subscription_status: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.subscription_status,
        paypal_subscription_id: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.paypal_subscription_id,
        billing_info: hexoscopeUser.HexoscopeUser.getHexoscopeUser.hsUser.billing_info,
      });
    }
  }, [hexoscopeUser]);

  useEffect(() => {
    loadUserInfo();
  }, []);

  const [loadUserInfo, {data: userData}] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(userData) {
      if (userData?.User?.get) {
        setUser({...userData.User.get, loaded: true});
        setUserDataLocal({...userData.User.get, loaded: true});
      }
    },
  });
  const {data: hexomaticUserData, refetch: hexomaticUserInfo} = useQuery(GET_HEXOMATIC_USER);

  useEffect(() => {
    if (hexomaticUserData?.HexomaticUser?.getByLegacyId) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
  }, [hexomaticUserData]);

  return (
    <div className="col-12">
      {hexoscopePlanInfo?.pricing_package && (
        <Plan
          name={
            hexoscopePlanInfo?.pricing_package === 'FREE'
              ? 'Free'
              : hexoscopePlanInfo?.pricing_package
              ? `${hexoscopePlanInfo.pricing_package_count < 5 ? 'Standard' : hexoscopePlanInfo.pricing_package_count < 10 ? 'Advanced' : 'Agency'}`
              : 'N/A'
          }
          logo={hexoscopeLogo}
          whiteLogo={hexoscopeLogoWhite}
          packageInfo={hexoscopePlanInfo?.pricing_package_interval || 'N/A'}
          href="https://hexoscope.com/"
          loading={loading || hexometerLoadng || billingLoading || !user || !user.id || hexosparkLoading}
          showBillingInfo={billingDates?.hexoscope}
          billingInfo={billingDates?.hexoscope}
          history={
            !!(
              hexoscopePlanInfo?.pricing_package &&
              hexoscopePlanInfo?.pricing_package !== 'FREE' &&
              hexoscopePlanInfo?.pricing_package_interval !== 'LTD' &&
              !(hexoscopePlanInfo?.paypal_subscription_id && hexoscopePlanInfo?.subscription_status === 'trial')
            )
          }
          productType="hexoscope"
          productName="Hexoscope"
          cancel={
            !!(
              hexoscopePlanInfo?.pricing_package &&
              hexoscopePlanInfo?.pricing_package !== 'FREE' &&
              hexoscopePlanInfo?.pricing_package_interval !== 'LTD'
            )
          }
          status={hexoscopePlanInfo?.subscription_status}
          refetchAfterCancelation={hexoscopeUserRefetch}
          renewalDate={hexoscopePlanInfo?.billing_info?.next_billing_date}
          showResume={!hexoscopePlanInfo?.paypal_subscription_id}
          hexoscope
        >
          <>
            <p className="plans-info-line">
              <span className="info-name">Number of Scopes: </span>
              <span className="info-value">
                {hexoscopePlanInfo?.pricing_package === 'FREE' ? 0 : hexoscopePlanInfo?.pricing_package_count || 'N/A'}
              </span>
            </p>
          </>
        </Plan>
      )}
      <Plan
        name={hexomaticUser?.pricing_package || 'N/A'}
        logo={hexomaticLogo}
        whiteLogo={hexomaticLogoWhite}
        packageInfo={hexomaticUser?.pricing_package_interval || 'N/A'}
        href="https://hexomatic.com/pricing"
        showBillingInfo={billingDates?.hexomatic}
        billingInfo={billingDates?.hexomatic}
        loading={loading || hexometerLoadng || billingLoading || !user || !user.id}
        history={
          !!(
            (hexomaticUser?.pricing_package !== 'FREE' && hexomaticUser?.pricing_package_interval !== 'LTD')
            // && !hexomaticUser?.paypal_subscription_id
          )
        }
        productType="hexomatic"
        productName="Hexomatic"
        cancel={!!(hexomaticUser?.pricing_package !== 'FREE' && hexomaticUser?.pricing_package_interval !== 'LTD')}
        status={hexomaticUser?.subscription_status}
        refetchAfterCancelation={hexomaticUserInfo}
        renewalDate={hexomaticUser?.billing_info?.next_billing_date}
        showResume={!hexomaticUser?.paypal_subscription_id}
      >
        <>
          <p className="plans-info-line">
            <span className="info-name">Monthly automation credits:</span>
            <span className="info-value">
              {hexomaticUser?.automation_credit ? hexomaticUser.automation_credit : 'N/A'}
            </span>
          </p>
        </>
      </Plan>

      <Plan
        name={`${pricingPackage ? pricingPackage.toLowerCase() + (monthlyLimit === 500 ? 'Trial' : '') : 'N/A'}`}
        logo={hexowatchLogo}
        whiteLogo={hexowatchLogoWhite}
        packageInfo={pricingPackageInterval ? pricingPackageInterval : 'N/A'}
        href="https://hexowatch.com/pricing/"
        showBillingInfo={pricingPackageInterval !== 'LTD' && billingInfo && pricingPackage !== 'FREE'}
        billingInfo={billingDates?.hexowatch}
        loading={loading || hexometerLoadng || billingLoading || !user || !user.id}
        history={
          !!(
            (pricingPackage && pricingPackage !== 'FREE' && pricingPackageInterval !== 'LTD')
            // && !settingsQuery?.UserWatchSettings?.get.paypal_subscription_id
          )
        }
        productType="hexowatch"
        productName="Hexowatch"
        cancel={!!(pricingPackage && pricingPackage !== 'FREE' && pricingPackageInterval !== 'LTD')}
        status={settingsQuery?.UserWatchSettings?.get?.subscription_status}
        refetchAfterCancelation={refetch}
        renewalDate={settingsQuery?.UserWatchSettings?.get?.billing_info?.next_billing_date}
        showResume={!settingsQuery?.UserWatchSettings?.get?.paypal_subscription_id}
      >
        <>
          <p className="plans-info-line">
            <span className="info-name">Checks per month:</span>
            <span className="info-value">
              {leftLimits} left out of {monthlyLimit + additionalScans}
            </span>
          </p>
          <p className="plans-info-line">
            <span className="info-name">Page check frequency:</span>
            <span className="info-value">{currentPackage && currentPackage.maxInterval}</span>
          </p>
        </>
      </Plan>
      {hexofyPlanInfo?.pricing_package && (
        <Plan
          name={
            hexofyPlanInfo?.pricing_package === 'FREE'
              ? 'Free'
              : hexofyPlanInfo?.pricing_package === 'STARTER'
              ? `Starter`
              : hexofyPlanInfo?.pricing_package === 'UNLIMITED'
              ? 'Unlimited'
              : hexofyPlanInfo?.pricing_package === 'TEAM'
              ? `Team ${hexofyPlanInfo.pricing_package_count}X`
              : 'N/A'
          }
          logo={hexofyLogo}
          whiteLogo={hexofyLogoWhite}
          packageInfo={hexofyPlanInfo?.pricing_package_interval || 'N/A'}
          href="https://hexofy.com/"
          loading={loading || hexometerLoadng || billingLoading || !user || !user.id || hexosparkLoading}
          showBillingInfo={billingDates?.hexofy}
          billingInfo={billingDates?.hexofy}
          history={
            !!(
              hexofyPlanInfo?.pricing_package &&
              hexofyPlanInfo?.pricing_package !== 'FREE' &&
              hexofyPlanInfo?.pricing_package_interval !== 'LTD' &&
              !(hexofyPlanInfo?.paypal_subscription_id && hexofyPlanInfo?.subscription_status === 'trial')
            )
          }
          productType="hexofy"
          productName="Hexofy"
          cancel={
            !!(
              hexofyPlanInfo?.pricing_package &&
              hexofyPlanInfo?.pricing_package !== 'FREE' &&
              hexofyPlanInfo?.pricing_package_interval !== 'LTD'
            )
          }
          status={hexofyPlanInfo?.subscription_status}
          refetchAfterCancelation={hexofyUserRefetch}
          renewalDate={hexofyPlanInfo?.billing_info?.next_billing_date}
          showResume={!hexofyPlanInfo?.paypal_subscription_id}
        >
          <>
            <p className="plans-info-line">
              <span className="info-name">Capture data pages count: </span>
              <span className="info-value">
                {hexofyPlanInfo?.pricing_package === 'STARTER'
                  ? 500
                  : hexofyPlanInfo?.pricing_package === 'UNLIMITED' || hexofyPlanInfo?.pricing_package === 'TEAM'
                  ? 'Unlimited'
                  : 15}
              </span>
            </p>
            {hexofyPlanInfo?.pricing_package === 'TEAM' && hexofyPlanInfo?.pricing_package_count && (
              <p className="plans-info-line">
                <span className="info-name">Team access: </span>
                <span className="info-value">
                  {hexofyPlanInfo.pricing_package_count - 1} additional team seats (
                  {hexofyPlanInfo.pricing_package_count} total users)
                </span>
              </p>
            )}
          </>
        </Plan>
      )}
      {hexosparkPlanInfo?.pricing_package && (
        <Plan
          name={
            hexosparkPlanInfo?.pricing_package === 'FREE'
              ? 'Free'
              : hexosparkPlanInfo?.pricing_package === 'SOLO'
              ? 'Solo'
              : hexosparkPlanInfo?.pricing_package === 'TEAM'
              ? 'Team'
              : hexosparkPlanInfo?.pricing_package_count && hexosparkPlanInfo?.pricing_package_count <= 4
              ? 'Solo'
              : hexosparkPlanInfo?.pricing_package_count && hexosparkPlanInfo?.pricing_package_count > 4
              ? 'Team'
              : 'N/A'
          }
          logo={hexosparkLogo}
          whiteLogo={hexosparkLogoWhite}
          packageInfo={hexosparkPlanInfo?.pricing_package_interval || 'N/A'}
          href="https://hexospark.com/pricing/"
          loading={loading || hexometerLoadng || billingLoading || !user || !user.id || hexosparkLoading}
          showBillingInfo={billingDates?.hexospark}
          billingInfo={billingDates?.hexospark}
          history={
            !!(
              (hexosparkPlanInfo?.pricing_package !== 'FREE' && hexosparkPlanInfo?.pricing_package_interval !== 'LTD')
              // && !hexosparkPlanInfo?.paypal_subscription_id
            )
          }
          productType="hexospark"
          productName="Hexospark"
          cancel={
            !!(hexosparkPlanInfo?.pricing_package !== 'FREE' && hexosparkPlanInfo?.pricing_package_interval !== 'LTD')
          }
          status={hexosparkPlanInfo?.subscription_status}
          refetchAfterCancelation={hexosparkUserRefetch}
          renewalDate={hexosparkPlanInfo?.billing_info?.next_billing_date}
          showResume={!hexosparkPlanInfo?.paypal_subscription_id}
        >
          <>
            <p className="plans-info-line">
              <span className="info-name">Email inboxes: </span>
              <span className="info-value">{hexosparkPlanInfo?.emailAccounts || 0 || 'N/A'}</span>
            </p>
            <p className="plans-info-line">
              <span className="info-name">Account storage: </span>
              <span className="info-value">
                {hexosparkPlanInfo?.storage ? hexosparkPlanInfo?.storage / 1_000_000_000 : 0} GB
              </span>
            </p>
            {hexosparkPlanInfo?.teamAccess >= 2 ? (
              <p className="plans-info-line">
                <span className="info-name">Team seats: </span>
                <span className="info-value">
                  1+{hexosparkPlanInfo.teamAccess - 1} Team seats ({hexosparkPlanInfo.teamAccess} total users)
                </span>
              </p>
            ) : null}
            {hexosparkPlanInfo?.workspaces ? (
              <p className="plans-info-line">
                <span className="info-name">Workspaces: </span>
                <span className="info-value">{hexosparkPlanInfo?.workspaces || 'N/A'}</span>
              </p>
            ) : null}
            {hexosparkPlanInfo?.totalPromptsCount ? (
              <p className="plans-info-line">
                <span className="info-name">Icebreakers: </span>
                <span className="info-value">{hexosparkPlanInfo.totalPromptsCount || 'N/A'}</span>
              </p>
            ) : null}
          </>
        </Plan>
      )}
      <Plan
        name={getHexometerPackageName(hexometerInfo?.pricing_package)}
        logo={hexometerLogo}
        whiteLogo={hexometerLogoWhite}
        packageInfo={hexometerInfo?.pricing_package === 'FREE' ? 'N/A' : hexometerInfo?.pricing_package_interval}
        href={
          hexometerInfo && (!hexometerInfo.pricing_package || hexometerInfo.pricing_package === 'FREE')
            ? 'https://hexometer.com/pricing'
            : hexometerInfo && hexometerInfo.pricing_package && hexometerInfo.pricing_package.indexOf('AGENCY') > -1
            ? 'https://dash.hexometer.com/pricing-v2/agency'
            : 'https://dash.hexometer.com/pricing-v2'
        }
        showBillingInfo={
          hexometerInfo?.pricing_package_interval !== 'LTD' &&
          hexometerInfo?.billing_info &&
          hexometerInfo?.pricing_package !== 'FREE'
        }
        billingInfo={hexometerInfo?.billing_info}
        loading={loading || hexometerLoadng || billingLoading || !user || !user.id}
        history={
          hexometerInfo?.pricing_package !== 'FREE' && hexometerInfo?.pricing_package_interval !== 'LTD'
          // && !hexometerInfo.paypal_subscription_id
        }
        cancel={hexometerInfo?.pricing_package !== 'FREE' && hexometerInfo?.pricing_package_interval !== 'LTD'}
        productType="hexometer"
        productName="Hexometer"
        refetchAfterCancelation={refetchHexometerUser}
        status={hexometerInfo?.subscription_status}
        renewalDate={hexometerInfo?.billing_info?.next_billing_date}
        showResume={!hexometerInfo?.paypal_subscription_id}
      >
        <>
          {hexometerInfo && (
            <p className="plans-info-line">
              <span className="info-name">Plans Count:</span>
              <span className="info-value">
                {hexometerInfo?.pricing_package === 'FREE' ? 'N/A' : hexometerInfo.pricing_package_count}
              </span>
            </p>
          )}
          {hexometerInfo && (
            <p className="plans-info-line">
              <span className="info-name">Max. number of websites:</span>
              <span className="info-value">
                {hexometerInfo?.pricing_package === 'FREE' ? 'N/A' : hexometerMaxNumber}
              </span>
            </p>
          )}
        </>
      </Plan>

      <Plan
        name=""
        packageInfo={String(userDataLocal?.premium_credits_pricing_package_interval) || ''}
        showBillingInfo={billingDates?.premium_credits}
        billingInfo={billingDates?.premium_credits}
        loading={loading || hexometerLoadng || billingLoading || premiumCreditsLoading || !user || !user.id}
        premiumCredits
        history={
          // !userDataLocal?.premium_credits_paypal_subscription_id &&
          (billingDates?.premium_credits && billingDates?.premium_credits?.next_billing_date) ||
          userDataLocal?.premium_credit_subscription_status === 'canceled'
        }
        hidePlanType={
          userDataLocal?.premium_credits_pricing_package === 'PC_15900' ||
          userDataLocal?.premium_credits_pricing_package === 'PC_24900' ||
          userDataLocal?.premium_credits_pricing_package === 'PC_49900'
        }
        productType="premium_credits"
        productName="premium credits"
        refetch={refetchBillingInfo}
        refetchAfterCancelation={loadUserInfo}
        cancel={
          !!(
            userDataLocal?.premium_credits_pricing_package !== 'FREE' &&
            userDataLocal?.premium_credits_pricing_package_interval !== 'LTD'
          )
        }
        status={userDataLocal?.premium_credit_subscription_status}
        renewalDate={userDataLocal?.premium_credit_subscription_cancel_at}
        showResume={!userDataLocal?.premium_credits_paypal_subscription_id}
      >
        <>
          <p className="plans-info-line">
            <span className="info-name">Monthly premium credits:</span>
            {userDataLocal?.premium_credits_pricing_package === 'FREE' ? (
              <span className="info-value">0</span>
            ) : (
              <span className="info-value">
                {userDataLocal?.left_premium_credits?.toFixed(2) || 0} left out of{' '}
                {userDataLocal?.premium_credits_pricing_package &&
                  premiumCredits?.Billing?.listPremiumCredits?.premiumCredits?.find(
                    (item: any) =>
                      item.name === userDataLocal.premium_credits_pricing_package && item.productType === 'STRIPE',
                  )?.limits.premium_credits_count}
              </span>
            )}
          </p>
          <p className="plans-info-line">
            <span className="info-name">Bonus one-time credits:</span>
            <span className="info-value">
              {(hexomaticUser?.left_premium_automation_credit &&
                +hexomaticUser?.left_premium_automation_credit?.toFixed(2)) ||
                0}
            </span>
          </p>
          {renewalDate?.User?.getPackageRenewalDate?.premium_credits && (
            <>
              <p className="auto-credits-title title-secondary pt-1">
                Premium credits will renew{'  '}
                {!renewalDate?.User?.getPackageRenewalDate?.premium_credits
                  ? 'today, '
                  : ' ' +
                    'in ' +
                    renewalDate?.User?.getPackageRenewalDate?.premium_credits +
                    ` day${renewalDate?.User?.getPackageRenewalDate?.premium_credits !== 1 ? 's' : ''}`}
              </p>
              <hr className="m-0 mt-2 mb-2" />
            </>
          )}
        </>
      </Plan>
    </div>
  );
};

export default MyPlans;
