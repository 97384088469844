import {useState, useEffect, useContext} from 'react';
import {deleteIcon, editIconScope, lockOpenBtnIcon, settingsIcon} from 'images/svg-icons';
import ToolCard from 'components/molecule/tool-card';
import Button from 'components/atom/button';
import {useNavigate} from 'react-router-dom';
import {cancelScopeDelete, deleteScope, getScopeById} from 'requests';
import {getDateAndTime} from 'helpers/index';
import Modal from 'components/molecule/modal';
import {ScopesContext} from 'contexts/scopes-context';
import './styles.scss';
import {UserContext} from 'contexts/user-context';

// const getTimeDifference = (updatedAt: any) => {
//   if (!updatedAt) {
//     return '';
//   }
//   const updatedAtDate = new Date(updatedAt); // Parse the updatedAt date
//   const currentDate = new Date(); // Get the current date
//   // Calculate the time difference in milliseconds
//   //@ts-ignore
//   const timeDifference = currentDate - updatedAtDate;

//   // Convert the time difference to hours
//   const differenceInHours = timeDifference / (1000 * 60 * 60);
//   if (differenceInHours > 6) {
//     return ''; // More than 6 hours
//   } else if (differenceInHours >= 1) {
//     // Less than or equal to 6 hours but more than or equal to 1 hour
//     const remainingHours = Math.floor(6 - differenceInHours);
//     return `${remainingHours} hour${remainingHours === 1 ? '' : 's'}`;
//   } else {
//     // Less than 1 hour
//     const remainingMinutes = Math.ceil((6 - differenceInHours) * 60);
//     return `${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
//   }
// };

const DefaultSettings = () => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [scheduledToBeDeleted, setScheduledToBeDeleted] = useState('');
  // const [updatedAt, setUpdatedAt] = useState('');
  const [loading, setLoading] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const {selectedScope, setSelectedScope} = useContext(ScopesContext);
  const {hexoscopeUser} = useContext(UserContext);

  const navigate = useNavigate();
  const handleDelete = async () => {
    setConfirm(false);
    setLoading(true);
    const resp = await deleteScope(selectedScope.id);
    //@ts-ignore
    if (resp?.data?.status) {
      setSuccess('Scope successfully scheduled for deletion!');
      const scopeData = await getScopeById(selectedScope.id);
      //@ts-ignore
      if (scopeData?.data) {
        //@ts-ignore
        setSelectedScope(scopeData?.data);
      }
    } else {
      //@ts-ignore
      setError(resp?.data?.message || 'Something went wrong');
    }
    setLoading(false);
  };

  const handleCancelDelete = async () => {
    setConfirm(false);
    setLoading(true);
    const resp = await cancelScopeDelete(selectedScope.id);
    //@ts-ignore
    if (resp?.data?.status) {
      setSuccess('Scope deletion canceled successfully! The scope remains active.');
      const scopeData = await getScopeById(selectedScope.id);
      //@ts-ignore
      if (scopeData?.data) {
        //@ts-ignore
        setSelectedScope(scopeData?.data);
      }
    } else {
      //@ts-ignore
      setError(resp?.data?.message || 'Something went wrong');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedScope) {
      // const lastUpdated = selectedScope.updated_at || selectedScope.created_at;
      // const val = getTimeDifference(lastUpdated);
      // setUpdatedAt(val);
      const scheduledDelete = selectedScope.scheduled_to_be_deleted;
      scheduledDelete && typeof scheduledDelete === 'string'
        ? setScheduledToBeDeleted(getDateAndTime(selectedScope.scheduled_to_be_deleted))
        : setScheduledToBeDeleted('');
    }
  }, [selectedScope]);

  const handleEditScopeClick = () => {
    if (hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
      setUpgrade(true);
    } else {
      navigate(`/scope/${selectedScope?.id}/edit`);
    }
  };

  const hanldeDeleteScopeConfirm = () => {
    if (hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
      setUpgrade(true);
    } else {
      setConfirm(true);
    }
  };

  return (
    <ToolCard
      title="Scope Settings"
      icon={<div className="icon-wrapper-settings-default">{settingsIcon}</div>}
      openClose
      defaulOpen
    >
      <div className="default-settings-container">
        {/* <div className="edit-block">
          <p className="mb-2">
            {updatedAt
              ? `Scope was last edited in less than 6 hours. You can edit it again after ${updatedAt}.`
              : 'Editing scope will be allowed every 6 hours.'}
          </p>
          <Button
            name="Edit Scope"
            onClick={handleEditScopeClick}
            leftIcon={editIconScope}
            disabled={!!updatedAt || !selectedScope?.id}
            width={200}
          />
        </div>
        <hr /> */}
        <div className="delete-block">
          <p className="mb-2">
            {scheduledToBeDeleted
              ? `This scope is scheduled to be removed on ${scheduledToBeDeleted}`
              : 'After the deletion request, all data related to the scope will be erased after 24 hours, if you have anything to copy please do it before that.'}
          </p>
          <div className="d-flex">
            <Button
              name="Edit Scope"
              onClick={handleEditScopeClick}
              leftIcon={editIconScope}
              width={200}
              className="mr-2"
            />
            {scheduledToBeDeleted ? (
              <Button
                name="Cancel Deletion"
                onClick={() => setConfirm(true)}
                leftIcon={lockOpenBtnIcon}
                width={200}
                disabled={!selectedScope?.id}
              />
            ) : (
              <Button
                name="Delete Scope"
                onClick={hanldeDeleteScopeConfirm}
                leftIcon={deleteIcon}
                danger
                width={200}
                disabled={!selectedScope?.id}
              />
            )}
          </div>
        </div>
      </div>
      {success && (
        <Modal
          isModalOpen={!!success}
          text={success}
          actionBtnText="OK"
          actionBtnColor="success"
          onActionClick={() => setSuccess('')}
          escButtonClick={() => setSuccess('')}
        />
      )}

      {error && (
        <Modal
          isModalOpen={!!error}
          text={error}
          actionBtnText="OK"
          actionBtnColor="danger"
          onActionClick={() => setError('')}
          escButtonClick={() => setError('')}
        />
      )}
      {confirm && (
        <Modal
          isModalOpen={!!confirm}
          text={`Are you sure you want to ${scheduledToBeDeleted ? 'cancel scope deletion?' : 'delete the scope?'}`}
          actionBtnText="Confirm"
          cancelBtnText="Cancel"
          cancelBtnColor="secondary"
          actionBtnColor="warning"
          onActionClick={() => (scheduledToBeDeleted ? handleCancelDelete() : handleDelete())}
          onCancelClick={() => setConfirm(false)}
          escButtonClick={() => setConfirm(false)}
          loading={loading}
        />
      )}
      {upgrade ? (
        <Modal
          isModalOpen={upgrade}
          title="Upgrade to enable this feature"
          text="This feature is available for Standard or above plan"
          actionBtnText={'Upgrade'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'warning'}
          cancelBtnColor={'secondary'}
          onActionClick={() => (window.location.href = 'https://hexoscope.com/#pricing')}
          onCancelClick={() => setUpgrade(false)}
          escButtonClick={() => setUpgrade(false)}
        />
      ) : null}
    </ToolCard>
  );
};

export default DefaultSettings;
