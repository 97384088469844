import { closePopupOnEsc } from 'helpers';
import { closeIcon, longArrowLeft } from 'images/svg-icons';
import React, { useState, useEffect } from 'react';
import './styles.scss';

const Popup: React.FC<any> = React.memo(
  ({ children, theme, escButtonClick, onModalClose, className, onBackClick, ShowPopup, modalBodyPadding }) => {
    useEffect(() => {
      document.body.classList.add('overflow-hidden');
      return () => {
        document.body.classList.remove('overflow-hidden');
        localStorage.removeItem('packageInfo');
      };
    }, []);

    //Close Popup on Esc button click
    useEffect(() => {
      const [addEvent, removeEvent] = closePopupOnEsc(() => escButtonClick && escButtonClick());
      if (ShowPopup) addEvent();
      return () => {
        removeEvent();
      };
    }, [ShowPopup, escButtonClick]);

    const [height, setHeight] = useState(document.body.clientHeight);
    return (
      <div
        className={`customModal ${theme === 'light' ? 'light' : 'dark'} ${className ? className : ''}`}
        onScroll={() => setHeight(document.body.clientHeight)}
      >
        <div className={`modal-body ${modalBodyPadding ? modalBodyPadding : ''}`}>
          {onBackClick && (
            <div className="back-icon">
              <span onClick={onBackClick}>{longArrowLeft}</span>
            </div>
          )}
          {onModalClose && (
            <div className="close-icon">
              <span onClick={onModalClose}>{closeIcon}</span>
            </div>
          )}

          <div className="popup-alert">{children}</div>
        </div>
      </div>
    );
  },
);

export default Popup;
