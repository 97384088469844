import { FC, useState, useLayoutEffect, memo, useCallback, useMemo, useEffect, useContext } from 'react';
import { arrowDownIcon, closeIcon } from '../../../images/svg-icons';
import useClickOutside from '../../../hooks/useClickOutside';
import Option from './option';
import Input from '../input';
import Button from '../button';
import { eventStop } from 'helpers';
import { ISelect } from './types';
import { ThemeContext } from 'contexts/theme-context';
import './styles.scss';

const Select: FC<ISelect> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  selectWrapperClassName,
  placeholder,
  bottom,
  icon,
  showLeft,
  showRight,
  showBottom,
  fullWidth,
  noBorder,
  width,
  height,
  label,
  danger,
  w100,
  textDefault,
  textUppercase,
  searchable,
  addNewOption,
  isEventStop,
  leftIcon,
  openMenuOnTop,
  addNoSelectOption,
  placeholderLeft,
  query,
  ws_id,
  saveText,
  handleTransformData,
  createNew,
  handleNew,
  setDataNotFound,
  typeValue,
  tooltipClassName,
  tooltipText,
  onClick,
}) => {
  //!---------------------State----------------------
  //Boolean
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  //String
  const [searchText, setSearchText] = useState('');
  const [newValue, setNewValue] = useState('');
  const [inputVal, setInputVal] = useState('');

  //Number
  const [page, setPage] = useState(1);
  const [docHeight, setDocHeight] = useState(0);
  const [elOffsetHeight, setElOffsetHeight] = useState(0);
  const [count, setCount] = useState(0);
  //Array
  const [queryOptions, setQueryOptions] = useState([]);
  const [newOptions, setNewOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  //
  // const [getList] = [];
  //!----------------------Hook----------------------
  const [showOptions, selectRef, setShowOptions] = useClickOutside();
  const { theme } = useContext(ThemeContext);

  //!----------------------useEffect-------------------
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setSearchText('');
  }, [showOptions]);

  useEffect(() => {
    !searchText && !searchText.trim() && setFilteredOptions(options);
    if (page !== 1) setPage(1);
  }, [searchText]);

  useLayoutEffect(() => {
    setDocHeight(document.documentElement.clientHeight);
  }, [document.documentElement.clientHeight]);

  useLayoutEffect(() => {
    if (selectRef && selectRef.current) {
      const dimensions = selectRef.current.getBoundingClientRect();
      setElOffsetHeight(dimensions.y);
    }
  }, [selectRef]);

  useEffect(() => {
    if (query) return;
    const new_option = !searchable ? options : filteredOptions;
    setNewOptions(
      addNoSelectOption && (value?.value || (value && typeof value === 'string'))
        ? [{ label: 'No select', value: '' }, ...new_option]
        : new_option,
    );
  }, [options, filteredOptions, searchable, addNoSelectOption, value]);

  // useEffect(() => {
  //   getOptions();
  // }, [query, page, searchText, value]);

  //!-----------------------useMemo---------------------
  const res = useMemo(() => {
    return docHeight - elOffsetHeight;
  }, [docHeight, elOffsetHeight]);

  const selectWidth = useMemo(() => {
    return {
      width: width ? width : '',
      height: height ? height : '',
    };
  }, [width, height]);

  const manySelectValue = useMemo(() => {
    let showValuetext = '';
    if (manySelect && value?.length > 0) {
      value.forEach((element: { label: string }, idx: number) => {
        showValuetext += element.label + (value[idx + 1] ? ', ' : '');
      });
    }
    return showValuetext;
  }, [value, manySelect]);

  //!----------------------useCallback------------------
  // const getOptions = useCallback(async () => {
  //   if (!query || !getList) return;
  //   setLoading(true);
  //   const { data }: any = await getList({
  //     fetchPolicy: 'no-cache',
  //     variables: {
  //       settings: {
  //         filter: { search: { search_value: searchText }, page: page, limit: 50 },
  //         hs_workspace: ws_id || undefined,
  //       },
  //     },
  //   });
  //   if (data?.Options?.getOptions) {
  //     if (!searchText && data.Options.getOptions.list.length < 1 && setDataNotFound) setDataNotFound(true);
  //     if (handleTransformData) {
  //       const list: any = handleTransformData(data.Options.getOptions.list);
  //       const newList = page === 1 ? list || [] : [...queryOptions, ...(list || [])];
  //       const finList = addNoSelectOption && (value?.value || (value && typeof value === 'string'))
  //       ? [{ label: 'No select', value: '' }, ...newList]
  //       : newList;
  //       setQueryOptions(finList);
  //     } else {
  //       const newList = page === 1 ? data.Options.getOptions.list || [] : [...queryOptions, ...(data.Options.getOptions.list || [])];
  //       const finList = addNoSelectOption && (value?.value || (value && typeof value === 'string'))
  //       ? [{ label: 'No select', value: '' }, ...newList]
  //       : newList;
  //       setQueryOptions(finList);
  //     }
  //     setCount(data.Options.getOptions.count || 0);
  //   }
  //   setLoading(false);
  // }, [query, page, searchText, queryOptions, handleTransformData, addNoSelectOption, value]);

  const getClassNamesByHeight = useCallback(() => {
    return openMenuOnTop
      ? 'bottom short-options'
      : res < 330 && res > 150
      ? 'short-options'
      : res < 150
      ? bottom
        ? ''
        : 'bottom short-options'
      : '';
  }, [res]);

  const handleShowOptions = useCallback(() => {
    !disabled && setShowOptions(!showOptions);
  }, [disabled, showOptions]);

  const handleChangeInput = useCallback(
    (e: any) => {
      if (typeValue) {
        const val = typeValue === 'int-number' ? e.target.value.replace(/\D/g, '') : e.target.value;
        const new_val = Math.abs(+val);
        setNewValue(String(new_val > 500 && typeValue === 'int-number' ? 500 : new_val || ''));
        onChange(String(new_val > 500 && typeValue === 'int-number' ? 500 : new_val || ''));
        return;
      }
      setSearchText(e.target.value);
      if (query) return;
      const newOpt = [...options];
      const fil = newOpt.filter((op: { label: string }) =>
        op.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
      );
      setFilteredOptions(e.target.value && e.target.value.trim() ? fil : options);
      saveText && onChange(e.target.value);
    },
    [options, query, saveText, onChange],
  );

  const handleShowInput = useCallback(() => {
    !showInput && setShowInput(true);
    !showOptions && setShowOptions(true);
  }, [showInput]);

  const handleHideInput = useCallback(() => {
    searchText && setSearchText('');
    showInput && setShowInput(false);
  }, [showInput]);

  const handleChangeInputVal = useCallback((e: any) => {
    setInputVal(e.target.value);
  }, []);

  const handleAddOption = useCallback(
    (e: any) => {
      eventStop(e);
      setNewOptions([{ label: inputVal, value: inputVal, isNew: true }, ...newOptions]);
      onChange({ label: inputVal, value: inputVal, isNew: true });
      setShowOptions(false);
    },
    [newOptions, inputVal],
  );

  const handleScroll = (e: any) => {
    if (!query) return;
    if (count <= queryOptions.length) return;
    const el = e.target;
    if (loading && el) {
      el.scrollTop = el.scrollHeight - el?.offsetHeight - 70;
      return;
    }
    const scrollTop = el && el.scrollTop;
    el.scrollHeight - el?.offsetHeight - 160 <= Math.round(scrollTop) && setPage(page + 1);
    return;
  };

  const handleDeleteAllSelected = useCallback((e: any) => {
    eventStop(e);
    onChange([]);
    setShowOptions(false);
  }, []);

  const handleHideOptions = useCallback(() => setShowOptions(false), []);

  const handleChange = (val: any) => {
    onChange(val);
    setNewValue('');
  };
  //!----------------------------------------------
  return (
    <>
      <div id="select-wrapper" className={`d-block ${w100 ? 'w-100' : ''} ${selectWrapperClassName ? selectWrapperClassName : ''}`} onClick={onClick}>
        {label && <div className="select-label primary-color">{label}</div>}
        <div
          className={`custom-universal-select noselect ${className ? className : ''} ${
            showLeft ? 'show-left' : showRight ? 'show-right' : showBottom ? 'show-bottom' : ''
          } ${fullWidth ? 'full-width' : ''} ${noBorder ? 'no-border' : ''} ${disabled ? 'disabled-select' : ''}`}
          ref={selectRef}
          //onClick={handleSetIsShow}
        >
          {showLeft || showRight || showBottom ? (
            <span id="icon" onClick={handleShowOptions}>
              {icon}
            </span>
          ) : (
            <div
              className={`select d-flex ${showOptions ? 'border-color' : ''} ${danger ? 'border-danger' : ''} `}
              style={selectWidth}
              onClick={handleShowOptions}
            >
              {leftIcon && <span className="mr-2">{leftIcon ? leftIcon : ''}</span>}
              {(searchable || typeValue) && (
                <div className={`${showInput ? 'show' : 'hide'}`} onClick={handleShowInput}>
                  <Input
                    value={searchText || newValue}
                    onChange={handleChangeInput}
                    className="no-border-input"
                    placeholder={
                      typeof value === 'object' ? value?.label || placeholder : value || String(placeholder) || ''
                    }
                    onFocus={handleShowInput}
                    onBlur={handleHideInput}
                    onKeyDown={handleShowInput}
                    type={typeValue || 'text'}
                    onClick={handleShowInput}
                  />
                </div>
              )}

              {(!searchable || !showInput) && placeholder && !value ? (
                <span className={`select-placeholder ${placeholderLeft ? 'ml-auto' : 'mr-auto'}`}>{placeholder}</span>
              ) : (
                !showInput && (
                    <span
                      className={`mr-auto  ${textDefault ? 'text-transform-default' : ''}${
                        textUppercase ? 'text_upperCase' : ''
                      } selected-value noselect position-relative`}
                    >
                      {manySelect
                        ? value?.length < 1
                          ? placeholder
                          : manySelectValue
                        : typeof value === 'object'
                        ? value && value.label
                        : (!query ? options : queryOptions).find(
                            (option: any) => option.label === value || option.value === value,
                          )?.label || value}
                    </span>
                )
              )}
              {manySelect && value?.length > 0 && (
                <span className="delete-all-icon cursor-pointer px-2" onClick={handleDeleteAllSelected}>
                  {closeIcon}
                </span>
              )}
              {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
            </div>
          )}
          {showOptions && (
            <div
              id="drop-down-profile"
              className={`options ${getClassNamesByHeight()}`}
              style={selectWidth}
              onScroll={handleScroll}
            >
              {children ? (
                <div onClick={handleHideOptions}>{children}</div>
              ) : (
                <>
                  {(!query ? newOptions : queryOptions).map((item: any, index: number) => (
                    <Option
                      onChange={handleChange}
                      key={(item.name || item) + index}
                      value={value}
                      textDefault={textDefault}
                      textUppercase={textUppercase}
                      item={item}
                      manySelect={manySelect}
                      isEventStop={isEventStop}
                      disabled={item.disabled}
                      setShowOptions={setShowOptions}
                    />
                  ))}
                  {addNewOption && (
                    <div className="add-option-wrapper d-flex align-items-center justify-content-center">
                      <div onClick={eventStop}>
                        <Input
                          value={inputVal}
                          onChange={handleChangeInputVal}
                          noBorders
                          placeholder="Type your option..."
                          onEnterPress={handleAddOption}
                        />
                      </div>
                      <Button name="Add" disabled={!inputVal} success width={42} onClick={handleAddOption} />
                    </div>
                  )}
                  
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Select);
