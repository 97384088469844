import { FC, FormEvent, memo, useCallback } from 'react';
import { infoIcon } from 'images/svg-icons';
import { IAppProps } from './types';
import './styles.scss';

const TextArea: FC<IAppProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  infoText,
  maxLength,
  label,
  onClick,
}) => {
  const fieldChangeIndex = useCallback(
    (e: FormEvent) => {
      onChange(e);
    },
    [onChange],
  );
  const handleBlur = useCallback(
    (e: any) => {
      onBlur && onBlur(e);
    },
    [onBlur],
  );
  const handleFocus = useCallback(
    (e: any) => {
      onFocus && onFocus(e);
    },
    [onFocus],
  );
  const handleClick = useCallback(
    (e: any) => {
      onClick && onClick(e);
    },
    [onClick],
  );
  return (
    <>
      {label && <label className="label">{label}</label>}
      <textarea
        className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''}`}
        name={name || ''}
        placeholder={placeholder}
        value={value}
        rows={rows || 6}
        onChange={fieldChangeIndex}
        onBlur={handleBlur}
        onFocus={handleFocus}
        maxLength={maxLength}
        onClick={handleClick}
      />
      {infoText && (
        <div>
          <span className="info-icon">{infoIcon}</span>
          <span className="info-message">{infoText}</span>
        </div>
      )}
    </>
  );
};

export default memo(TextArea);
