import {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloWrapper} from './graphql';
import {UserContext} from 'contexts/user-context';
import {ScopesContext} from 'contexts/scopes-context';
import {ThemeContext} from 'contexts/theme-context';
import {useThemeDetector} from 'hooks/useThemeDetect';
import Authentication from './authentication';
import Layout from './layout';
import Routes from './routes';
import {getInitialTheme, getUserToken} from './helpers';
import {HexoscopeUserType, UserType} from './types';

import './App.scss';

const App = () => {
  const [theme, setTheme] = useState(getInitialTheme());
  const [scopes, setScopes] = useState<any>(null);
  const [selectedScope, setSelectedScope] = useState<any>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [hexoscopeUser, setHexoscopeUser] = useState<HexoscopeUserType | null>(null);
  const [accessToken, setAccessToken] = useState('');
  const [showRoutes, setShowRoutes] = useState(false);
  const isSystemDark = useThemeDetector();

  useEffect(() => {
    if (isSystemDark && theme === 'not_selected') {
      localStorage.setItem('theme', 'dark');
      localStorage.setItem('systemTheme', 'selected');
      setTheme('dark');
    }

    if (theme === 'selected') {
      localStorage.setItem('theme', theme);
    }
  }, [isSystemDark]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    if (getUserToken() === accessToken) {
      setShowRoutes(true);
    }
  }, [accessToken, getUserToken()]);

  return (
    <ApolloWrapper>
      {user?.id ? null : (
        <div className="vh-100 d-flex justify-content-center vw-100">
          <div className="m-auto">
            <div className="spinner-border text-secondary" style={{width: '3rem', height: '3rem'}} role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      )}
      <UserContext.Provider value={{user, setUser, hexoscopeUser, setHexoscopeUser}}>
        <ThemeContext.Provider value={{theme, setTheme}}>
          <ScopesContext.Provider value={{scopes, setScopes, selectedScope, setSelectedScope}}>
            <Authentication setAccessToken={(token: string) => setAccessToken(token)}>
              <div className={theme === 'dark' ? 'theme-dark' : 'theme-light'}>
                <Router>
                  <Layout>
                    <Routes />
                  </Layout>
                </Router>
              </div>
            </Authentication>
          </ScopesContext.Provider>
        </ThemeContext.Provider>
      </UserContext.Provider>
    </ApolloWrapper>
  );
};

export default App;
