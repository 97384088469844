/**
 * Generates API and socket urls depending
 * on environment variables.
 */

interface IgenericProp {
  url_protocol: string;
  ws_protocol: string;
  api_prefix: string;
  app_prefix: string;
  ws_prefix: string;
  api_domain_upload: string;
  api_domain: string;
}

interface Igeneric {
  [el: string]: IgenericProp;
}

interface IHostProps {
  URL: string;
  UPLOAD: string;
  APP: string;
  WS: string;
  AUTH_HEADER: string;
  AUTH_PREFIX: string;
}

interface IHost {
  [el: string]: IHostProps;
}

const generic: Igeneric = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_prefix: 'v2/ql',
    app_prefix: 'v2/app',
    ws_prefix: 'v2/ws',
    api_domain_upload: 'uploader.hexoscope.com/api',
    api_domain: 'api.hexoscope.com',
  },
  development: {
    url_protocol: 'http',
    ws_protocol: 'ws',
    api_prefix: 'api/ql',
    app_prefix: 'api/app',
    ws_prefix: 'api/ws',
    api_domain_upload: 'localhost:4005/api',
    api_domain: 'localhost:4000',
  },
  dev: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_prefix: 'v2/ql',
    app_prefix: 'v2/app',
    ws_prefix: 'v2/ws',
    api_domain_upload: 'uploader.hexoscope.com/api',
    api_domain: 'api.hexact.io',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_DASHBOARD || process.env.NODE_ENV;
  if (env === 'test') {
    env = 'development';
  } else if (env === 'dev') {
    env = 'dev';
  }
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST: IHost = {
  APP_API: {
    URL: `${main.url_protocol}://${main.api_domain}/${main.api_prefix}`,
    UPLOAD: `${main.url_protocol}://${main.api_domain_upload}`,
    APP: `${main.url_protocol}://${main.api_domain}/${main.app_prefix}`,
    WS: `${main.ws_protocol}://${main.api_domain}/${main.ws_prefix}`,
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Basic',
  },
};
