export const scopeCountries = [
  { label: 'United States', value: 'US', icon: '🇺🇸' },
  { label: 'Afghanistan', value: 'AF', icon: '🇦🇫' },
  { label: 'Aland Islands', value: 'AX', icon: '🇦🇽' },
  { label: 'Albania', value: 'AL', icon: '🇦🇱' },
  { label: 'Algeria', value: 'DZ', icon: '🇩🇿' },
  { label: 'American Samoa', value: 'AS', icon: '🇦🇸' },
  { label: 'Andorra', value: 'AD', icon: '🇦🇩' },
  { label: 'Angola', value: 'AO', icon: '🇦🇴' },
  { label: 'Anguilla', value: 'AI', icon: '🇦🇮' },
  { label: 'Antarctica', value: 'AQ', icon: '🇦🇶' },
  { label: 'Antigua And Barbuda', value: 'AG', icon: '🇦🇬' },
  { label: 'Argentina', value: 'AR', icon: '🇦🇷' },
  { label: 'Armenia', value: 'AM', icon: '🇦🇲' },
  { label: 'Aruba', value: 'AW', icon: '🇦🇼' },
  { label: 'Australia', value: 'AU', icon: '🇦🇺' },
  { label: 'Austria', value: 'AT', icon: '🇦🇹' },
  { label: 'Azerbaijan', value: 'AZ', icon: '🇦🇿' },
  { label: 'Bahamas The', value: 'BS', icon: '🇧🇸' },
  { label: 'Bahrain', value: 'BH', icon: '🇧🇭' },
  { label: 'Bangladesh', value: 'BD', icon: '🇧🇩' },
  { label: 'Barbados', value: 'BB', icon: '🇧🇧' },
  { label: 'Belarus', value: 'BY', icon: '🇧🇾' },
  { label: 'Belgium', value: 'BE', icon: '🇧🇪' },
  { label: 'Belize', value: 'BZ', icon: '🇧🇿' },
  { label: 'Benin', value: 'BJ', icon: '🇧🇯' },
  { label: 'Bermuda', value: 'BM', icon: '🇧🇲' },
  { label: 'Bhutan', value: 'BT', icon: '🇧🇹' },
  { label: 'Bolivia', value: 'BO', icon: '🇧🇴' },
  { label: 'Bosnia and Herzegovina', value: 'BA', icon: '🇧🇦' },
  { label: 'Botswana', value: 'BW', icon: '🇧🇼' },
  { label: 'Bouvet Island', value: 'BV', icon: '🇧🇻' },
  { label: 'Brazil', value: 'BR', icon: '🇧🇷' },
  { label: 'British Indian Ocean Territory', value: 'IO', icon: '🇮🇴' },
  { label: 'Brunei', value: 'BN', icon: '🇧🇳' },
  { label: 'Bulgaria', value: 'BG', icon: '🇧🇬' },
  { label: 'Burkina Faso', value: 'BF', icon: '🇧🇫' },
  { label: 'Burundi', value: 'BI', icon: '🇧🇮' },
  { label: 'Cambodia', value: 'KH', icon: '🇰🇭' },
  { label: 'Cameroon', value: 'CM', icon: '🇨🇲' },
  { label: 'Canada', value: 'CA', icon: '🇨🇦' },
  { label: 'Cape Verde', value: 'CV', icon: '🇨🇻' },
  { label: 'Cayman Islands', value: 'KY', icon: '🇰🇾' },
  { label: 'Central African Republic', value: 'CF', icon: '🇨🇫' },
  { label: 'Chad', value: 'TD', icon: '🇹🇩' },
  { label: 'Chile', value: 'CL', icon: '🇨🇱' },
  { label: 'China', value: 'CN', icon: '🇨🇳' },
  { label: 'Christmas Island', value: 'CX', icon: '🇨🇽' },
  { label: 'Cocos (Keeling) Islands', value: 'CC', icon: '🇨🇨' },
  { label: 'Colombia', value: 'CO', icon: '🇨🇴' },
  { label: 'Comoros', value: 'KM', icon: '🇰🇲' },
  { label: 'Congo', value: 'CG', icon: '🇨🇬' },
  { label: 'Congo The Democratic Republic Of The', value: 'CD', icon: '🇨🇩' },
  { label: 'Cook Islands', value: 'CK', icon: '🇨🇰' },
  { label: 'Costa Rica', value: 'CR', icon: '🇨🇷' },
  { label: "Cote D'Ivoire (Ivory Coast)", value: 'CI', icon: '🇨🇮' },
  { label: 'Croatia (Hrvatska)', value: 'HR', icon: '🇭🇷' },
  { label: 'Cuba', value: 'CU', icon: '🇨🇺' },
  { label: 'Cyprus', value: 'CY', icon: '🇨🇾' },
  { label: 'Czech Republic', value: 'CZ', icon: '🇨🇿' },
  { label: 'Denmark', value: 'DK', icon: '🇩🇰' },
  { label: 'Djibouti', value: 'DJ', icon: '🇩🇯' },
  { label: 'Dominica', value: 'DM', icon: '🇩🇲' },
  { label: 'Dominican Republic', value: 'DO', icon: '🇩🇴' },
  { label: 'East Timor', value: 'TL', icon: '🇹🇱' },
  { label: 'Ecuador', value: 'EC', icon: '🇪🇨' },
  { label: 'Egypt', value: 'EG', icon: '🇪🇬' },
  { label: 'El Salvador', value: 'SV', icon: '🇸🇻' },
  { label: 'Equatorial Guinea', value: 'GQ', icon: '🇬🇶' },
  { label: 'Eritrea', value: 'ER', icon: '🇪🇷' },
  { label: 'Estonia', value: 'EE', icon: '🇪🇪' },
  { label: 'Ethiopia', value: 'ET', icon: '🇪🇹' },
  { label: 'Falkland Islands', value: 'FK', icon: '🇫🇰' },
  { label: 'Faroe Islands', value: 'FO', icon: '🇫🇴' },
  { label: 'Fiji Islands', value: 'FJ', icon: '🇫🇯' },
  { label: 'Finland', value: 'FI', icon: '🇫🇮' },
  { label: 'France', value: 'FR', icon: '🇫🇷' },
  { label: 'French Guiana', value: 'GF', icon: '🇬🇫' },
  { label: 'French Polynesia', value: 'PF', icon: '🇵🇫' },
  { label: 'French Southern Territories', value: 'TF', icon: '🇹🇫' },
  { label: 'Gabon', value: 'GA', icon: '🇬🇦' },
  { label: 'Gambia The', value: 'GM', icon: '🇬🇲' },
  { label: 'Georgia', value: 'GE', icon: '🇬🇪' },
  { label: 'Germany', value: 'DE', icon: '🇩🇪' },
  { label: 'Ghana', value: 'GH', icon: '🇬🇭' },
  { label: 'Gibraltar', value: 'GI', icon: '🇬🇮' },
  { label: 'Greece', value: 'GR', icon: '🇬🇷' },
  { label: 'Greenland', value: 'GL', icon: '🇬🇱' },
  { label: 'Grenada', value: 'GD', icon: '🇬🇩' },
  { label: 'Guadeloupe', value: 'GP', icon: '🇬🇵' },
  { label: 'Guam', value: 'GU', icon: '🇬🇺' },
  { label: 'Guatemala', value: 'GT', icon: '🇬🇹' },
  { label: 'Guernsey and Alderney', value: 'GG', icon: '🇬🇬' },
  { label: 'Guinea', value: 'GN', icon: '🇬🇳' },
  { label: 'Guinea-Bissau', value: 'GW', icon: '🇬🇼' },
  { label: 'Guyana', value: 'GY', icon: '🇬🇾' },
  { label: 'Haiti', value: 'HT', icon: '🇭🇹' },
  { label: 'Heard Island and McDonald Islands', value: 'HM', icon: '🇭🇲' },
  { label: 'Honduras', value: 'HN', icon: '🇭🇳' },
  { label: 'Hong Kong S.A.R.', value: 'HK', icon: '🇭🇰' },
  { label: 'Hungary', value: 'HU', icon: '🇭🇺' },
  { label: 'Iceland', value: 'IS', icon: '🇮🇸' },
  { label: 'India', value: 'IN', icon: '🇮🇳' },
  { label: 'Indonesia', value: 'ID', icon: '🇮🇩' },
  { label: 'Iran', value: 'IR', icon: '🇮🇷' },
  { label: 'Iraq', value: 'IQ', icon: '🇮🇶' },
  { label: 'Ireland', value: 'IE', icon: '🇮🇪' },
  { label: 'Israel', value: 'IL', icon: '🇮🇱' },
  { label: 'Italy', value: 'IT', icon: '🇮🇹' },
  { label: 'Jamaica', value: 'JM', icon: '🇯🇲' },
  { label: 'Japan', value: 'JP', icon: '🇯🇵' },
  { label: 'Jersey', value: 'JE', icon: '🇯🇪' },
  { label: 'Jordan', value: 'JO', icon: '🇯🇴' },
  { label: 'Kazakhstan', value: 'KZ', icon: '🇰🇿' },
  { label: 'Kenya', value: 'KE', icon: '🇰🇪' },
  { label: 'Kiribati', value: 'KI', icon: '🇰🇮' },
  { label: 'Korea North', value: 'KP', icon: '🇰🇵' },
  { label: 'Korea South', value: 'KR', icon: '🇰🇷' },
  { label: 'Kuwait', value: 'KW', icon: '🇰🇼' },
  { label: 'Kyrgyzstan', value: 'KG', icon: '🇰🇬' },
  { label: 'Laos', value: 'LA', icon: '🇱🇦' },
  { label: 'Latvia', value: 'LV', icon: '🇱🇻' },
  { label: 'Lebanon', value: 'LB', icon: '🇱🇧' },
  { label: 'Lesotho', value: 'LS', icon: '🇱🇸' },
  { label: 'Liberia', value: 'LR', icon: '🇱🇷' },
  { label: 'Libya', value: 'LY', icon: '🇱🇾' },
  { label: 'Liechtenstein', value: 'LI', icon: '🇱🇮' },
  { label: 'Lithuania', value: 'LT', icon: '🇱🇹' },
  { label: 'Luxembourg', value: 'LU', icon: '🇱🇺' },
  { label: 'Macau S.A.R.', value: 'MO', icon: '🇲🇴' },
  { label: 'Macedonia', value: 'MK', icon: '🇲🇰' },
  { label: 'Madagascar', value: 'MG', icon: '🇲🇬' },
  { label: 'Malawi', value: 'MW', icon: '🇲🇼' },
  { label: 'Malaysia', value: 'MY', icon: '🇲🇾' },
  { label: 'Maldives', value: 'MV', icon: '🇲🇻' },
  { label: 'Mali', value: 'ML', icon: '🇲🇱' },
  { label: 'Malta', value: 'MT', icon: '🇲🇹' },
  { label: 'Man (Isle of)', value: 'IM', icon: '🇮🇲' },
  { label: 'Marshall Islands', value: 'MH', icon: '🇲🇭' },
  { label: 'Martinique', value: 'MQ', icon: '🇲🇶' },
  { label: 'Mauritania', value: 'MR', icon: '🇲🇷' },
  { label: 'Mauritius', value: 'MU', icon: '🇲🇺' },
  { label: 'Mayotte', value: 'YT', icon: '🇾🇹' },
  { label: 'Mexico', value: 'MX', icon: '🇲🇽' },
  { label: 'Micronesia', value: 'FM', icon: '🇫🇲' },
  { label: 'Moldova', value: 'MD', icon: '🇲🇩' },
  { label: 'Monaco', value: 'MC', icon: '🇲🇨' },
  { label: 'Mongolia', value: 'MN', icon: '🇲🇳' },
  { label: 'Montenegro', value: 'ME', icon: '🇲🇪' },
  { label: 'Montserrat', value: 'MS', icon: '🇲🇸' },
  { label: 'Morocco', value: 'MA', icon: '🇲🇦' },
  { label: 'Mozambique', value: 'MZ', icon: '🇲🇿' },
  { label: 'Myanmar', value: 'MM', icon: '🇲🇲' },
  { label: 'Namibia', value: 'NA', icon: '🇳🇦' },
  { label: 'Nauru', value: 'NR', icon: '🇳🇷' },
  { label: 'Nepal', value: 'NP', icon: '🇳🇵' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ', icon: '🇧🇶' },
  { label: 'Netherlands The', value: 'NL', icon: '🇳🇱' },
  { label: 'New Caledonia', value: 'NC', icon: '🇳🇨' },
  { label: 'New Zealand', value: 'NZ', icon: '🇳🇿' },
  { label: 'Nicaragua', value: 'NI', icon: '🇳🇮' },
  { label: 'Niger', value: 'NE', icon: '🇳🇪' },
  { label: 'Nigeria', value: 'NG', icon: '🇳🇬' },
  { label: 'Niue', value: 'NU', icon: '🇳🇺' },
  { label: 'Norfolk Island', value: 'NF', icon: '🇳🇫' },
  { label: 'Northern Mariana Islands', value: 'MP', icon: '🇲🇵' },
  { label: 'Norway', value: 'NO', icon: '🇳🇴' },
  { label: 'Oman', value: 'OM', icon: '🇴🇲' },
  { label: 'Pakistan', value: 'PK', icon: '🇵🇰' },
  { label: 'Palau', value: 'PW', icon: '🇵🇼' },
  { label: 'Palestinian Territory Occupied', value: 'PS', icon: '🇵🇸' },
  { label: 'Panama', value: 'PA', icon: '🇵🇦' },
  { label: 'Papua new Guinea', value: 'PG', icon: '🇵🇬' },
  { label: 'Paraguay', value: 'PY', icon: '🇵🇾' },
  { label: 'Peru', value: 'PE', icon: '🇵🇪' },
  { label: 'Philippines', value: 'PH', icon: '🇵🇭' },
  { label: 'Pitcairn Island', value: 'PN', icon: '🇵🇳' },
  { label: 'Poland', value: 'PL', icon: '🇵🇱' },
  { label: 'Portugal', value: 'PT', icon: '🇵🇹' },
  { label: 'Puerto Rico', value: 'PR', icon: '🇵🇷' },
  { label: 'Qatar', value: 'QA', icon: '🇶🇦' },
  { label: 'Reunion', value: 'RE', icon: '🇷🇪' },
  { label: 'Romania', value: 'RO', icon: '🇷🇴' },
  { label: 'Russia', value: 'RU', icon: '🇷🇺' },
  { label: 'Rwanda', value: 'RW', icon: '🇷🇼' },
  { label: 'Saint Helena', value: 'SH', icon: '🇸🇭' },
  { label: 'Saint Kitts And Nevis', value: 'KN', icon: '🇰🇳' },
  { label: 'Saint Lucia', value: 'LC', icon: '🇱🇨' },
  { label: 'Saint Pierre and Miquelon', value: 'PM', icon: '🇵🇲' },
  { label: 'Saint Vincent And The Grenadines', value: 'VC', icon: '🇻🇨' },
  { label: 'Saint-Barthelemy', value: 'BL', icon: '🇧🇱' },
  { label: 'Saint-Martin (French part)', value: 'MF', icon: '🇲🇫' },
  { label: 'Samoa', value: 'WS', icon: '🇼🇸' },
  { label: 'San Marino', value: 'SM', icon: '🇸🇲' },
  { label: 'Sao Tome and Principe', value: 'ST', icon: '🇸🇹' },
  { label: 'Saudi Arabia', value: 'SA', icon: '🇸🇦' },
  { label: 'Senegal', value: 'SN', icon: '🇸🇳' },
  { label: 'Serbia', value: 'RS', icon: '🇷🇸' },
  { label: 'Seychelles', value: 'SC', icon: '🇸🇨' },
  { label: 'Sierra Leone', value: 'SL', icon: '🇸🇱' },
  { label: 'Singapore', value: 'SG', icon: '🇸🇬' },
  { label: 'Slovakia', value: 'SK', icon: '🇸🇰' },
  { label: 'Slovenia', value: 'SI', icon: '🇸🇮' },
  { label: 'Solomon Islands', value: 'SB', icon: '🇸🇧' },
  { label: 'Somalia', value: 'SO', icon: '🇸🇴' },
  { label: 'South Africa', value: 'ZA', icon: '🇿🇦' },
  { label: 'South Georgia', value: 'GS', icon: '🇬🇸' },
  { label: 'South Sudan', value: 'SS', icon: '🇸🇸' },
  { label: 'Spain', value: 'ES', icon: '🇪🇸' },
  { label: 'Sri Lanka', value: 'LK', icon: '🇱🇰' },
  { label: 'Sudan', value: 'SD', icon: '🇸🇩' },
  { label: 'Suriname', value: 'SR', icon: '🇸🇷' },
  { label: 'Svalbard And Jan Mayen Islands', value: 'SJ', icon: '🇸🇯' },
  { label: 'Swaziland', value: 'SZ', icon: '🇸🇿' },
  { label: 'Sweden', value: 'SE', icon: '🇸🇪' },
  { label: 'Switzerland', value: 'CH', icon: '🇨🇭' },
  { label: 'Syria', value: 'SY', icon: '🇸🇾' },
  { label: 'Taiwan', value: 'TW', icon: '🇹🇼' },
  { label: 'Tajikistan', value: 'TJ', icon: '🇹🇯' },
  { label: 'Tanzania', value: 'TZ', icon: '🇹🇿' },
  { label: 'Thailand', value: 'TH', icon: '🇹🇭' },
  { label: 'Togo', value: 'TG', icon: '🇹🇬' },
  { label: 'Tokelau', value: 'TK', icon: '🇹🇰' },
  { label: 'Tonga', value: 'TO', icon: '🇹🇴' },
  { label: 'Trinidad And Tobago', value: 'TT', icon: '🇹🇹' },
  { label: 'Tunisia', value: 'TN', icon: '🇹🇳' },
  { label: 'Turkey', value: 'TR', icon: '🇹🇷' },
  { label: 'Turkmenistan', value: 'TM', icon: '🇹🇲' },
  { label: 'Turks And Caicos Islands', value: 'TC', icon: '🇹🇨' },
  { label: 'Tuvalu', value: 'TV', icon: '🇹🇻' },
  { label: 'Uganda', value: 'UG', icon: '🇺🇬' },
  { label: 'Ukraine', value: 'UA', icon: '🇺🇦' },
  { label: 'United Arab Emirates', value: 'AE', icon: '🇦🇪' },
  { label: 'United Kingdom', value: 'GB', icon: '🇬🇧' },
  { label: 'United States Minor Outlying Islands', value: 'UM', icon: '🇺🇲' },
  { label: 'Uruguay', value: 'UY', icon: '🇺🇾' },
  { label: 'Uzbekistan', value: 'UZ', icon: '🇺🇿' },
  { label: 'Vanuatu', value: 'VU', icon: '🇻🇺' },
  { label: 'Vatican City State (Holy See)', value: 'VA', icon: '🇻🇦' },
  { label: 'Venezuela', value: 'VE', icon: '🇻🇪' },
  { label: 'Vietnam', value: 'VN', icon: '🇻🇳' },
  { label: 'Virgin Islands (British)', value: 'VG', icon: '🇻🇬' },
  { label: 'Virgin Islands (US)', value: 'VI', icon: '🇻🇮' },
  { label: 'Wallis And Futuna Islands', value: 'WF', icon: '🇼🇫' },
  { label: 'Western Sahara', value: 'EH', icon: '🇪🇭' },
  { label: 'Yemen', value: 'YE', icon: '🇾🇪' },
  { label: 'Zambia', value: 'ZM', icon: '🇿🇲' },
  { label: 'Zimbabwe', value: 'ZW', icon: '🇿🇼' },
  { label: 'Kosovo', value: 'XK', icon: '🇽🇰' },
  { label: 'Curaçao', value: 'CW', icon: '🇨🇼' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX', icon: '🇸🇽' },
];
