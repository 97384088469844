import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {ScopesContext} from 'contexts/scopes-context';
import Input from 'components/atom/input';
import Button from 'components/atom/button';
import ToastLoader from 'components/molecule/toastLoader';
import NothingToShow from 'components/atom/nothing-to-show';
import {getAllScopes} from 'requests';
import {getDateAndTime} from 'helpers';
import Tooltip from 'components/molecule/tooltip';
import {arrowDownIcon, searchIcon, listIcon, sidebarNewBtnIcon, warningIconOutline} from 'images/svg-icons';
import './styles.scss';

const ScopesList = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({maxWidth: 991});
  const {scopes, setScopes, selectedScope, setSelectedScope} = useContext(ScopesContext);

  const [filteredScopes, setFilteredScopes] = useState<any>([]);
  const [searchScopeValue, setScopeSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [toasterInfo, setToasterInfo] = useState<{
    show: boolean;
    errorMessage?: string;
    loadingMessage?: string;
    successMessage?: string;
  }>({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  useEffect(() => {
    selectedScope?.id && localStorage.setItem('scope_id', selectedScope.id);
    selectedScope?.last_scan_id && localStorage.setItem('scan_id', selectedScope.last_scan_id);
  }, [selectedScope]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getAllScopes();
      setLoading(false);
      //@ts-ignore
      if (data?.data) {
        //@ts-ignore
        const scopesList = data?.data;
        Array.isArray(scopesList) ? setScopes(scopesList) : setScopes([]);
        //@ts-ignore
        scopesList && setFilteredScopes(scopesList);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (searchScopeValue) {
      const filteredList = scopes.filter((item: any) =>
        (item['full_name'] || item['company_name']).toLowerCase().includes(searchScopeValue.toLowerCase()),
      );
      setFilteredScopes(filteredList || []);
      return;
    }
    setFilteredScopes(scopes);
  }, [searchScopeValue, scopes]);

  const handleSearch = useCallback((e: any) => setScopeSearchValue(e.target.value), []);

  const handleClickNew = () => {
    setShowList(false);
    navigate('/scope/create');
  };

  const handleChange = (option: any, settings?: boolean) => {
    setShowList(false);
    if (selectedScope?.id === option.id) return;
    setSelectedScope(option);
    settings ? navigate(`/scope/${option.id}/settings`) : navigate(`/scope/${option.id}/${option.last_scan_id}`);
  };

  useEffect(() => {
    if (location.pathname.includes('create') || location.pathname.includes('profile')) {
      setSelectedScope(null);
    }
  }, [location]);

  return (
    <>
      <div className="select-wrapper cursor-pointer d-flex">
        {loading ? (
          <div className="pulse">
            <div className="scope-select-pulse" />
          </div>
        ) : (
          <div className="scopes-container" ref={ref}>
            <div className={`selected-scope ${isMobile ? 'bg-none' : ''}`} onClick={() => setShowList(!showList)}>
              {!isMobile && (
                <span className="text text-truncate">
                  {selectedScope?.company_name || selectedScope?.full_name || 'Select scope'}
                </span>
              )}
              <span className={`arrow ${isMobile ? 'm-auto' : ''}`}>{isMobile ? listIcon : arrowDownIcon}</span>
            </div>
            {showList && (
              <div className="scopes-list">
                <div className="search-row d-flex">
                  <Input
                    leftIcon={searchIcon}
                    value={searchScopeValue}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="mr-2"
                  />
                  <Button name="New" leftIcon={sidebarNewBtnIcon} onClick={handleClickNew} className="my-auto" />
                </div>
                <div className="scopes-list-container">
                  {showList && filteredScopes?.length ? (
                    filteredScopes.map((item: any) => (
                      <div className={`scope-item d-flex`} onClick={() => !item.disabled && handleChange(item)}>
                        <div>{item.company_name || item.full_name}</div>
                        {item?.scheduled_to_be_deleted ? (
                          <div
                            className="ml-auto d-flex"
                            onClick={e => {
                              e.stopPropagation();
                              handleChange(item, true);
                            }}
                          >
                            <Tooltip
                              trigger={['hover', 'focus']}
                              placement="left"
                              text={`Scheduled to be removed on ${getDateAndTime(item.scheduled_to_be_deleted)}`}
                            >
                              {warningIconOutline}
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <NothingToShow noIcon text="Nothing to show!" />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {selectedScope?.scheduled_to_be_deleted ? (
          <div className="ml-2 d-flex my-auto" onClick={() => navigate(`/scope/${selectedScope.id}/settings`)}>
            <Tooltip
              trigger={['hover', 'focus']}
              placement="bottom"
              text={`Scheduled to be removed on ${getDateAndTime(selectedScope.scheduled_to_be_deleted)}`}
            >
              {warningIconOutline}
            </Tooltip>
          </div>
        ) : null}
      </div>

      {toasterInfo.show && (
        <ToastLoader
          successMessage={toasterInfo.successMessage}
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          setToasterInfo={setToasterInfo}
        />
      )}
    </>
  );
};

export default ScopesList;
