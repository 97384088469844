import { FC, useState, ChangeEvent, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { UserContext } from '../../../contexts/user-context';
import { SEND_USER_FEEDBACK } from '../../../graphql/mutations';
import TextArea from '../textarea';
import Button from '../button';
import RadioButton from '../radio-button';
import './styles.scss';

interface FeedbackModalProps {
  onClose: (arg: boolean) => void;
  setIsSuccessfullySent: () => void;
}

interface RadioButton {
  label: string;
  value: boolean;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ onClose, setIsSuccessfullySent }) => {
  const { hexoscopeUser } = useContext(UserContext);
  const { user } = useContext(UserContext);

  const [inputValue, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [feedbackType, setFeedbackType] = useState('Suggestion');
  const [radioValues, setRadioValues] = useState<RadioButton[]>([
    { label: 'Suggestion', value: true },
    { label: 'Bug', value: false },
    { label: 'Other', value: false },
  ]);

  const [sendFeedback, { loading }] = useMutation(SEND_USER_FEEDBACK);

  const handleInputValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    if (e.target.value.trim().length <= 400 && e.target.value.trim().length >= 5) {
      setErrorMsg('');
    }
  };

  const handleCheck = (label: string) => {
    const newValue = radioValues.map((item: RadioButton) =>
      item.label === label ? { ...item, ['value']: true } : { ...item, ['value']: false },
    );
    setRadioValues(newValue);
    setFeedbackType(label);
  };

  const handleSubmit = async (): Promise<void> => {
    if (inputValue.length > 400) {
      setErrorMsg('Maximum allowed 400 characters.');
      return;
    }
    if (inputValue.trim().length < 5) {
      setErrorMsg('Please enter longer message.');
      return;
    }

    if (!inputValue.trim()) {
      setErrorMsg('Please write something');
      return;
    }
    if (!hexoscopeUser?.hsUser?.pricing_package || !user?.email || !hexoscopeUser?.hsUser?.pricing_package_interval) {
      return;
    }

    const res = await sendFeedback({
      variables: {
        settings: {
          message: inputValue.trim(),
          subject: `#${feedbackType} message received from Hexoscope report`,
          referralURL: window.location.href,
          plan: `${hexoscopeUser?.hsUser?.pricing_package} ${hexoscopeUser?.hsUser?.pricing_package_interval}`,
          email: user.email,
        },
      },
    });

    const error_code =
      res && res.data && res.data.HexoscopeUserOps && res.data.HexoscopeUserOps.createSuggestionFeedback;
    if (error_code) {
      setErrorMsg('Something went wrong');
      return;
    }
    setIsSuccessfullySent();
    onClose(true);
  };

  return (
    <div className="feedback-modal-main">
      {/* <button className="panel__btn" onClick={() => onClose(true)} type="button">
        {closeIcon}
      </button> */}
      <div className="feedback-header text-center mb-4">
        <h1>Send us some feedback!</h1>
        <p>Have Suggestions? Found a bug? </p>
        <p>Leave us a message below</p>
      </div>

      <TextArea
        name="feedback"
        className="feedback-textarea"
        value={inputValue || ''}
        placeholder={`${
          feedbackType === 'Bug'
            ? "Enter a bug that you've encountered."
            : feedbackType === 'Other'
            ? 'Enter your message.'
            : 'Enter your feature request.'
        }`}
        onChange={handleInputValueChange}
        maxLength={400}
      />
      {errorMsg && <label htmlFor="feedback">{errorMsg}</label>}
      <div className="d-flex my-2">
        {radioValues.map((item: RadioButton) => (
          <RadioButton
            className="mr-3 my-auto"
            key={item.label}
            enabled={item.value}
            label={item.label}
            onChange={() => handleCheck(item.label)}
            isBlue
          />
        ))}
      </div>

      <Button
        loading={loading}
        className="w-100 d-flex justify-content-center mt-4"
        name="Submit"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default FeedbackModal;
