import React, {FC, useState, useEffect, useContext} from 'react';
import {PromoCodeFormProps, UserPromoCodeBalanceQuery} from '../types';
import {Col, Row} from 'react-bootstrap';
import Loader from 'components/atom/loader';
import {
  ACTIVATE_PROMO_CODE,
  GET_HEXOMATIC_USER,
  GET_HEXOMETER_USER_SETTINGS,
  HEXOSPARK_USER,
  PROMO_CODE_BALANCE_QUERY,
} from 'graphql/queries';
import {GET_USER_WATCH_SETTINGS_QUERY} from 'graphql/queries';
import {getHexowatchPricingPackageName} from 'helpers';
import Input from 'components/atom/input';
import Button from 'components/atom/button';
import Message from 'components/atom/alert';
import {UserContext} from 'contexts/user-context';
import {newPackageDescriptions} from 'constants/index';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

const PromoCodeForm: FC<PromoCodeFormProps> = ({title}) => {
  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [hexowatchCurrenthPlan, setHexowatchCurrentPlan] = useState('');
  const [hexometerCurrenthPlan, setHexometerCurrenthPlan] = useState('');
  const [hexosparkUserData, setHexosparkUserData] = useState(null as any);
  const [hexomaticUser, setHexomaticUser] = useState(null as any);
  const {user, hexoscopeUser, setHexoscopeUser} = useContext(UserContext);
  const {data: hexosparkUserInfo, refetch: refetchHexosparkUserInfo} = useQuery(HEXOSPARK_USER, {
    fetchPolicy: 'no-cache',
  });

  const {data: hexomaticUserData, refetch: hexomaticrefetch} = useQuery(GET_HEXOMATIC_USER);
  const {
    data: promoBalanceDataHexometer,
    loading: hexometerPromoLoading,
    refetch: hexometerRefetch,
  } = useQuery<UserPromoCodeBalanceQuery>(PROMO_CODE_BALANCE_QUERY, {
    fetchPolicy: 'network-only',
  });

  const {
    data: userPromoCodesHexowatch,
    loading: hexowatchPromoLoading,
    refetch: hexowatchrefetch,
  } = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [activatePromoCode, {loading}] = useMutation(ACTIVATE_PROMO_CODE);

  useEffect(() => {
    if (hexosparkUserInfo?.HexosparkUser?.getHexosparkUser) {
      setHexosparkUserData(hexosparkUserInfo.HexosparkUser.getHexosparkUser);
    }
  }, [hexosparkUserInfo]);

  useEffect(() => {
    if (hexomaticUserData?.HexomaticUser?.getByLegacyId) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
  }, [hexomaticUserData]);

  const handlePromoCodeSubmit = async () => {
    setError('');
    const {data, errors} = await activatePromoCode({
      variables: {code: promoCode},
    });

    if (errors && errors.length > 0) {
      setError('Unable to send a request to our server, please try again later.');
      return;
    }

    if (data && data.PromoCodeOps && data.PromoCodeOps.activate) {
      if (data.PromoCodeOps.activate.error) {
        setError(
          data.PromoCodeOps.activate.message === 'CANT_SWITCH_INTERVAL'
            ? "Can't switch interval"
            : data.PromoCodeOps.activate.message,
        );
        return;
      }

      await hexometerRefetch();
      await hexowatchrefetch();
      await hexomaticrefetch();
      refetchHexosparkUserInfo();
      setSuccess(true);
      setPromoCode('');
    }
  };

  useEffect(() => {
    const settings =
      userPromoCodesHexowatch &&
      userPromoCodesHexowatch.UserWatchSettings &&
      userPromoCodesHexowatch.UserWatchSettings.get;
    if (settings && settings.pricing_package) {
      const planName = getHexowatchPricingPackageName(
        settings.pricing_package,
        settings.config && settings.config.additional_scans_count,
      );
      planName && setHexowatchCurrentPlan(planName);
    }
  }, [userPromoCodesHexowatch]);

  const {data: packagesData} = useQuery(GET_HEXOMETER_USER_SETTINGS, {
    skip: !(user && user.id),
    variables: {user_id: user && user.id && +user.id},
  });

  useEffect(() => {
    if (
      packagesData &&
      packagesData.HexometerUserSettings &&
      packagesData.HexometerUserSettings.get &&
      packagesData.HexometerUserSettings.get.pricing_package
    ) {
      const val = newPackageDescriptions.find(
        (item: any) => item.name === packagesData.HexometerUserSettings.get.pricing_package,
      );
      const interval =
        packagesData.HexometerUserSettings.get.pricing_package_count > 1
          ? ` x ${packagesData.HexometerUserSettings.get.pricing_package_count}`
          : '';
      val && setHexometerCurrenthPlan(`${val.UIName}${interval}`);
    }
  }, [packagesData]);

  return (
    <div className="promo-code-block col-12">
      <Row>
        <Col lg={6} xs={12} className="promo-input mb-2">
          <div>
            <Input
              label={'Your Code'}
              name="code"
              value={promoCode}
              placeholder="Enter code"
              onChange={(e: {target: HTMLInputElement}) => setPromoCode((e.target as HTMLInputElement).value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={12} className="mb-2">
          <div className="Redeem-btn">
            <Button onClick={handlePromoCodeSubmit} className="w-100">
              {loading ? (
                <span className="m-horizontal-auto">
                  <Loader />
                </span>
              ) : (
                <span className="m-horizontal-auto">Redeem</span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mb-2">
        {error && error.length > 0 && <Message type="danger" text={error} />}
        {success && <Message type="success" text={'Code successfully redeemed.'} />}
      </div>
      {hexomaticUser && hexomaticUser.promo_codes_count ? (
        <div className="promo-hexowatch-codes-block">
          <hr className="mb-2" />
          <h5 className="dark-title mb-2">Hexomatic Codes</h5>
          <div className="text-wrapper">
            <span className="balance-text d-block mb-1">Activated Codes Count: {hexomaticUser.promo_codes_count}</span>
            {hexomaticUser && (
              <span className="balance-text d-block">
                Current Plan: {hexomaticUser.pricing_package ? hexomaticUser.pricing_package : 'N/A'}
              </span>
            )}
          </div>
        </div>
      ) : null}
      {hexosparkUserData?.promo_codes_count && hexosparkUserData?.hsUser ? (
        <div className="promo-hexowatch-codes-block">
          <hr className="mb-2" />
          <h5 className="dark-title mb-2">Hexospark Codes</h5>
          <div className="text-wrapper">
            <span className="balance-text d-block mb-1">
              Activated Codes Count: {hexosparkUserData.promo_codes_count}
            </span>
            {hexosparkUserData && (
              <span className="balance-text d-block">
                Current Plan:{' '}
                {hexosparkUserData.hsUser.pricing_package_count <= 4
                  ? 'Solo'
                  : hexosparkUserData.hsUser.pricing_package_count > 4
                  ? 'Team'
                  : 'N/A'}
              </span>
            )}
          </div>
        </div>
      ) : null}
      {promoBalanceDataHexometer &&
        promoBalanceDataHexometer.User &&
        promoBalanceDataHexometer.User.promoBalance &&
        promoBalanceDataHexometer.User.promoBalance.count > 0 && (
          <div className="promo-balance-block">
            <hr className="mb-2" />
            <h5 className="dark-title mb-2">Hexometer Codes</h5>
            <div className="text-wrapper">
              {promoBalanceDataHexometer.User.promoBalance.count && (
                <span className="balance-text d-block mb-1">
                  Activated Codes Count: {promoBalanceDataHexometer.User.promoBalance.count}
                </span>
              )}
              {hexometerCurrenthPlan && (
                <span className="balance-text d-block">Current Plan: {hexometerCurrenthPlan}</span>
              )}
            </div>
          </div>
        )}
      {userPromoCodesHexowatch &&
        userPromoCodesHexowatch.UserWatchSettings &&
        userPromoCodesHexowatch.UserWatchSettings.get &&
        userPromoCodesHexowatch.UserWatchSettings.get.activated_promo_codes_count !== 0 && (
          <div className="promo-hexowatch-codes-block">
            <hr className="mb-2" />
            <h5 className="dark-title mb-2">Hexowatch Codes</h5>
            <div className="text-wrapper">
              <span className="balance-text d-block mb-1">
                Activated Codes Count: {userPromoCodesHexowatch.UserWatchSettings.get.activated_promo_codes_count}
              </span>
              {hexowatchCurrenthPlan && (
                <span className="balance-text d-block">Current Plan: {hexowatchCurrenthPlan}</span>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default PromoCodeForm;
