import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Select from '../../components/atom/select';
import { profileOptions } from '../../constants';
import { downArrow } from 'images/svg-icons';

const Profiles = () => (
  <div className="px-2 svg-theme">
    <Select showLeft icon={downArrow} noBorder>
      {profileOptions &&
        profileOptions.map((item: any) => (
          <NavLink className="option nav-link-component" key={item.title} to={item.path}>
            <span className="nav-link-icon">{item.icon}</span>
            <span className="ml-3">{item.title}</span>
          </NavLink>
        ))}
    </Select>
  </div>
);
export default memo(Profiles);
