import Cookies from 'js-cookie';
import moment from 'moment';
import {CountryInfoType} from '../types';
import CountryCodes from '../constants/countries';

const CookieDomains = ['37.186.119.181', 'localhost', '.hexoscope.com'];

// DANGER!!!! CHANGE THIS LATER ON
// CLEANUP default cookies if we have one
//@ts-ignore
if (window) {
  Cookies.remove('__session', {
    domain: undefined, // current default domain name
  });
  Cookies.remove('device_key', {
    domain: undefined, // current default domain name
  });
  Cookies.remove('ref_token', {
    domain: undefined, // current default domain name
  });
}

export const getCookies = (): any => {
  let cookiesStr: string | undefined;
  let cookies = {};
  for (const domain of CookieDomains) {
    // @ts-ignore
    cookiesStr = Cookies.get('__session', {domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(cookiesStr);
      } catch (e) {}
    }
  }
  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean, expires?: number) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: expires ? expires : 30,
      domain: domain,
    });
  });
};

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const removeRawCookie = (name: string) => Cookies.remove(name);

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain,
    });
    return null;
  });
};

export const removeRefreshToken = () => removeCookie('user-token');
export const removeAccessToken = removeRefreshToken;

export const getRefreshHash = (): string | undefined => getCookie('user-hash');
export const setRefreshHash = (hash: string) => setCookie('user-hash', hash);
export const removeRefreshHash = () => removeCookie('user-hash');

export const getRefreshToken = (): string | undefined => getCookie('user-token');

export const getAccessToken = getRefreshToken;

export const setUserToken = (token: string) => setCookie('user-token', token);
export const getUserToken = () => getCookie('user-token');

export const getInitialTheme = (): string => {
  return localStorage.getItem('theme') || 'light';
};

export const eventStop = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setRefToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

export const getUserSignedUpViaGoogle = (): string | undefined => getCookie('new-user');
export const setUserSignedUpViaGoogle = (val: boolean) => setCookie('new-user', val);
export const removeUserSignedUpViaGoogle = () => removeCookie('new-user');

//Date transformation functions

export const getDateAndTime = (date: string | number) => {
  return date ? moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)') : '';
};

export const getDateAndTimeWithOutMilliseconds = (date: string | number) => {
  return date ? moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm A (UTC)') : '';
};

export const getDateAndTimeWithoutUTC = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A');
};

export const getParsedDateAndTime = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate.toISOString()).format('MMM DD, YYYY h:mm:ss A') : '';
};

export const getDate = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY');
};

export const getDatePlusWeek = (date: string | number) => {
  let currentDate = new Date(date);

  // Add 7 days
  currentDate.setDate(currentDate.getDate() + 7);
  return currentDate && moment.utc(new Date(currentDate).toISOString()).format('MMM DD, YYYY');
};

export const getParsedDate = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate).format('MMM DD, YYYY') : '';
};
export const getParsedDateTime = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate).format('MMM DD, YYYY, h:mm:ss A (UTC)') : '';
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MM DD YYYY HH:mm:ss') : '';
};

export const cleanURL = (baseUrl: string): string => {
  let linkURL = baseUrl;
  if (baseUrl.indexOf('://') === -1) {
    linkURL = `http://${linkURL}`;
  }

  return linkURL || baseUrl;
};

export const setHotJarUser = (
  userId: number,
  pricing_status: string,
  pricing_plan: string | null,
  pricing_interval: string | null,
) => {
  window &&
    //@ts-ignore
    window.hj &&
    //@ts-ignore
    window.hj('identify', userId, {
      userId,
      pricing_status,
      pricing_plan,
      pricing_interval,
    });
};

export const closePopupOnEsc = (handlePopupStateChange: any) => {
  const handleEsc = (e: any) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();

      const elements = document.querySelectorAll(`[id^="popup-"]`);
      const elementsModal = document.querySelectorAll('.customModal');

      if (elements.length && elementsModal.length) {
        const bodyElement = document.getElementsByTagName('body')[0];
        const childEl = elements[elements.length - 1];
        bodyElement.removeChild(childEl);
      } else {
        handlePopupStateChange && handlePopupStateChange();
      }
    }
  };
  const addEvent = () => window.addEventListener('keydown', handleEsc);
  const removeEvent = () => window.removeEventListener('keydown', handleEsc);

  return [addEvent, removeEvent];
};

export const GetCountry = (countryCode: string): CountryInfoType => CountryCodes[countryCode];

export const getHexowatchPricingPackageName = (value: string, additionalScansCount?: number) => {
  if (value && value.indexOf('FREE') > -1) {
    return `Free ${
      additionalScansCount && additionalScansCount > 1000
        ? (additionalScansCount / 1000).toFixed(1)
        : additionalScansCount
        ? additionalScansCount
        : ''
    }${additionalScansCount && additionalScansCount > 1000 ? 'k' : ''} `;
  }
  if (value && value.indexOf('STANDARD') > -1) {
    return `Standard ${
      additionalScansCount
        ? ((additionalScansCount + 2000) / 1000).toFixed((additionalScansCount + 2000) % 1000 > 0 ? 1 : 0)
        : 2
    }k`;
  }
  if (value && value.indexOf('PRO') > -1) {
    return `Pro ${
      additionalScansCount
        ? ((additionalScansCount + 4500) / 1000).toFixed((additionalScansCount + 4500) % 1000 > 0 ? 1 : 0)
        : 4.5
    }k`;
  }
  if (value && value.indexOf('BUSINESS_SCANS_') > -1) {
    const limit = value.slice(value.lastIndexOf('_') + 1);
    const limitIn1000 = +limit;
    if (value !== 'BUSINESS_SCANS_10000') {
      return `Business+ ${
        additionalScansCount
          ? ((additionalScansCount + limitIn1000) / 1000).toFixed(
              (additionalScansCount + limitIn1000) % 1000 > 0 ? 1 : 0,
            )
          : limitIn1000 / 1000
      }k`;
    } else {
      return `Business ${
        additionalScansCount
          ? ((additionalScansCount + 10000) / 1000).toFixed((additionalScansCount + 10000) % 1000 > 0 ? 1 : 0)
          : 10
      }k`;
    }
  }
};

export const setBase32 = (base32: string) => {
  CookieDomains.map(domain => {
    Cookies.set('base32', base32, {
      expires: 30,
      domain,
    });
  });
};
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const cardsFirstNumbers: any = {
  VISA: '4242',
  MASTERCARD: '5555',
  'AMERICAN EXPRESS': '3782',
  DISCOVER: '6011',
  DINERSCLUB: '3056',
  JCB: '3566',
  UNIONPAY: '6200',
  'DINERS CLUB': '3056',
};

export const options = [
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6},
  {label: 7, value: 7},
  {label: 8, value: 8},
  {label: 9, value: 9},
  {label: 10, value: 10},
  {label: 20, value: 20},
  {label: 30, value: 30},
  {label: 40, value: 40},
  {label: 50, value: 50},
  {label: 60, value: 60},
  {label: 70, value: 70},
  {label: 80, value: 80},
  {label: 90, value: 90},
  {label: 100, value: 100},
];

export const getItemFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return null;
};

export const setItemToLocalStorage = (key: string, item: any) => localStorage.setItem(key, JSON.stringify(item));

export const sanitizeInput = (input: string) => {
  const val = input?.replace(/script/g, '');
  return val;
};
