import { FC, memo, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {jwtDecode} from 'jwt-decode';
import  {GET_ACCESS_TOKEN}  from 'graphql/queries';
import useSetUser from 'hooks/useSetUser';

import { getRawCookie, getUserSignedUpViaGoogle, getUserToken, setUserSignedUpViaGoogle, setUserToken } from 'helpers';
import { UserContext } from 'contexts/user-context';
import './styles.scss';

const Authentication: FC<{ children: JSX.Element; setAccessToken: (token: string) => void }> = ({
  children,
  setAccessToken,
}) => {
  const { user } = useContext(UserContext);
  const [getUser] = useSetUser();
  const [tokenUpdated, setTokenUpdated] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const [tokenExpDate, setTokenExpDate] = useState(900000);

  const { data: getAccessTokenData } = useQuery(GET_ACCESS_TOKEN, {
    pollInterval: tokenExpDate,
    skip: !getRawCookie('ref_token') || !getRawCookie('device_key'),
    variables: {
      settings: {
        refresh_token: getRawCookie('ref_token'),
        device_key: getRawCookie('device_key'),
        product_type: 'hexoscope',
      },
    },
  });

  useEffect(() => {
    setAccessDenied(false);
    if (getAccessTokenData && getAccessTokenData.User && getAccessTokenData.User.getAccessToken) {
      if (getAccessTokenData.User.getAccessToken && !getAccessTokenData.User.getAccessToken.token) {
        setAccessDenied(true);
      }
      if (getAccessTokenData.User.getAccessToken.token) {
        const jwtParsed = jwtDecode(getAccessTokenData.User.getAccessToken.token);
        //@ts-ignore
        const expDate = new Date((jwtParsed.exp || 0) * 1000) - new Date() - 55000;
        setTokenExpDate(expDate);
      }
      setTokenUpdated(getAccessTokenData.User.getAccessToken.token);
      setAccessToken(getAccessTokenData.User.getAccessToken.token);
      setUserToken(getAccessTokenData.User.getAccessToken.token);
    }
  }, [getAccessTokenData]);

  useEffect(() => {
    if (tokenUpdated === getUserToken() && !(user && user.id)) getUser();
  }, [getAccessTokenData, getUserToken(), tokenUpdated]);

  useEffect(() => {
    if (getUserSignedUpViaGoogle()) {
      if (window.location.href.includes('dash.hexoscope')) {
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-hexoscope`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-hexoscope`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
      }
      setUserSignedUpViaGoogle(false);
    }
  }, [user]);


  useEffect(() => {
    if (
      ((user && !user.id) || accessDenied || !getRawCookie('ref_token') || !getRawCookie('device_key')) &&
      !window.location.href.includes('dev-login')
    ) {
      setAccessDenied(false);
      window.location.href.indexOf('localhost') > -1
        ? window.location.replace('http://localhost:3000/dev-login')
        : window.location.href.indexOf('37.186.119.181') > -1
        ? window.location.replace('http://37.186.119.181:3279/dev-login')
        : window.location.href.indexOf('dashhexoscope.hexact.io') > -1
        ? window.location.replace('https://hexoscope.hexact.io')
        : window.location.replace('https://hexoscope.com');
    }
  }, [user, accessDenied]);


  return (
    <>
      {user && user.id ? children : null}
    </>
  );
};

export default memo(Authentication);
