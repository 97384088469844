import { FC, memo } from 'react';
import { IFooterProps } from '../types';
import '../layout.scss';

const Footer: FC<IFooterProps> = ({ toggle, noPadding }) => (
  <footer className={`${toggle && !noPadding ? 'footer-width' : ''}`} style={{ width: noPadding ? '100%' : '' }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
          &copy; {new Date().getFullYear()} <span>Hexact, Inc. All rights reserved.</span>
        </div>
      </div>
    </div>
  </footer>
);

export default memo(Footer);
