import { closeIcon, creditCardIcon } from 'images/svg-icons';
import { FC } from 'react';
import Tooltip from 'components/molecule/tooltip';

import './styles.scss';

interface ICardInfo {
  isDefault?: boolean;
  type: string;
  number: string;
  index: number;
  handleRemove: () => void;
  handleChangeDeafault: () => void;
  isDelete: boolean;
}
const CardItem: FC<ICardInfo> = ({ isDefault, type, number, index, handleRemove, handleChangeDeafault, isDelete }) => {
  return (
    <div className={`d-flex align-items-center card-item px-2 ${isDefault ? '' : 'no-active'} col-10 col-md-4`}>
      <div className={`${isDefault ? '' : 'cursor-pointer'}`}>
        <span
          id={`toggle-${index}`}
          className={`${isDefault ? '' : 'cursor-pointer no-active-card'}`}
          onClick={handleChangeDeafault}
        >
          <span className="hover-text">Click to change default payment method</span>
          {creditCardIcon}
        </span>
        {/*!isDefault && <>Click to change default payment method</>*/}
      </div>
      <div className="px-2 card-txt-number">
        {type.toUpperCase()} .. {number}
      </div>

      <div className="ml-auto">
        <Tooltip placement="right" text={`Delete`}>
          <span className="cursor-pointer" onClick={handleRemove}>
            {closeIcon}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
export default CardItem;
