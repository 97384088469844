import { FC, memo } from 'react';
import { ToggleButtonTypes } from '../types';
import Tooltip from '../../molecule/tooltip';
import './style.scss';

const ToggleBtn: FC<ToggleButtonTypes> = ({ checked, className, onChange, label1, label2, tooltipText }) => {
  const childComponent = (
    <div className={`toggle-checkbox m-vertical-auto ${className || ''}`}>
      {label1 && (
        <span className={`text-nowrap label mr-2 ${checked ? 'secondary-color' : 'active-color'}`} onClick={onChange}>
          {label1}
        </span>
      )}
      <input className="toggle-btn__input" type="checkbox" name="checkbox" onChange={onChange} checked={checked} />
      <button type="button" className={`toggle-btn__input-label`} onClick={onChange}></button>
      {label2 && (
        <span
          className={`text-nowrap label ml-0 pl-1 ${checked ? 'active-color' : 'secondary-color'}`}
          onClick={onChange}
        >
          {label2}
        </span>
      )}
    </div>
  );

  return tooltipText ? (
    <Tooltip placement="left" text={tooltipText}>
      {childComponent}
    </Tooltip>
  ) : (
    childComponent
  );
};

export default memo(ToggleBtn);
