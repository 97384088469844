import {FC, useContext, useEffect, useState} from 'react';
import Markdown from 'markdown-to-jsx';
import {useParams} from 'react-router-dom';
import DOMPurify from 'dompurify';
import Button from 'components/atom/button';
import {UserContext} from 'contexts/user-context';
import Modal from 'components/molecule/modal';
import NothingToShow from 'components/atom/nothing-to-show';
import {exportIcon, googleReviewIcon, tripadvisorIcon, trustpilotIcon} from 'images/svg-icons';
import {getCSV} from 'requests';
import {ScopesContext} from 'contexts/scopes-context';
import './styles.scss';

const ResultCard: FC<any> = ({
  icon,
  title,
  result,
  status,
  firstScan,
  module,
  csvName,
  hoverText,
  reviewTab,
  setReviewTab,
  disabledTabs,
}) => {
  const res = DOMPurify.sanitize(result);
  const {scan_id} = useParams();
  const {hexoscopeUser} = useContext(UserContext);
  const {selectedScope} = useContext(ScopesContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [upgrade, setUpgrade] = useState(false);
  const [cardUpgrade, setCardUpgrade] = useState(false);
  const [csvDownloadError, setCsvDownloadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const texts = ['Harvesting Data', 'Extracting Key Insights', 'Refining the Analysis', 'Delivering Results'];

  useEffect(() => {
    // Stop the interval if it's the last text
    if (currentIndex === texts.length - 1) return;

    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }, 10000);

    return () => clearInterval(interval); // Clean up interval on unmount or update
  }, [currentIndex]);

  const handleCSVExport = async () => {
    if (!hexoscopeUser?.hsUser?.pricing_package || hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
      setUpgrade(true);
    } else {
      setLoading(true);

      try {
        // Fetch data from backend
        const response = await getCSV(scan_id, module);
        //@ts-ignore
        if (!response?.data) {
          setCsvDownloadError(true);
          return;
        }
        // Get the CSV content as a blob
        //@ts-ignore
        const csvBlob = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});

        // Trigger download
        downloadCsv(csvBlob);
      } catch (error) {
        setCsvDownloadError(true);
        console.error('Error exporting CSV:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Helper function: Trigger download
  const downloadCsv = (blob: any) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    const cleanScopeName = (selectedScope?.company_name || selectedScope?.full_name).replace(/[^a-zA-Z0-9-]/g, '');
    link.setAttribute('download', `${cleanScopeName}-${csvName}-${selectedScope?.id}-${scan_id}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (hexoscopeUser?.hsUser?.pricing_package === 'FREE') {
      setCardUpgrade(true);
    } else {
      setCardUpgrade(false);
    }
  }, [title, hexoscopeUser]);

  return (
    <div className="result-card">
      {!result && status !== 'completed' ? (
        <div className="mock-result">
          {firstScan ? (
            <div className="status-message">
              <div className="spinner-border text-secondary" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="sr-only"></span>
              </div>
              <div className="text-center primary-color">{texts[currentIndex]}...</div>
            </div>
          ) : null}

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="mock-icon my-auto"></div>
              <div className="mock-title my-auto"></div>
            </div>
            <div className="mock-button my-auto"></div>
          </div>
          <div>
            <div className="mock-row mock-row-1"></div>
            <div className="mock-row mock-row-2"></div>
            <div className="mock-row mock-row-3"></div>
            <div className="mock-row mock-row-4"></div>
            <div className="mock-row mock-row-5"></div>
            <div className="mock-row mock-row-6"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="result">
            {title.includes('Reviews') ? (
              <div className="subtabs-container">
                <div
                  className={`subtab ${reviewTab === 1 ? 'active' : ''} ${
                    disabledTabs.includes(1) ? 'sub-disabled' : ''
                  }`}
                  onClick={() => setReviewTab(1)}
                >
                  {googleReviewIcon} Google Reviews
                </div>
                <div
                  className={`subtab ${reviewTab === 2 ? 'active' : ''} ${
                    disabledTabs.includes(2) ? 'sub-disabled' : ''
                  }`}
                  onClick={() => setReviewTab(2)}
                >
                  {trustpilotIcon} Trustpilot Reviews
                </div>
                <div
                  className={`subtab ${reviewTab === 3 ? 'active' : ''} ${
                    disabledTabs.includes(3) ? 'sub-disabled' : ''
                  }`}
                  onClick={() => setReviewTab(3)}
                >
                  {tripadvisorIcon} Tripadvisor Reviews
                </div>
              </div>
            ) : null}
            {result ? (
              result.includes('</') ? (
                <div
                  className="scope-result"
                  contentEditable="false"
                  dangerouslySetInnerHTML={{
                    __html: res.replace('```html', '').replace('```', ''),
                  }}
                />
              ) : (
                <div className="scope-result">
                  <Markdown>{result}</Markdown>
                </div>
              )
            ) : (
              <div className="my-auto text-center primary-color mb-4">
                <NothingToShow text="Reviews not found." />
              </div>
            )}
            {result && cardUpgrade ? (
              <div className="text-center cta-container">
                <div>
                  <p className="blurred-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id sem nec justo tempor viverra.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id sem nec justo tempor viverra.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id sem nec justo tempor viverra.
                  </p>
                  <p className="blurred-text">
                    Suspendisse potenti. Cras vehicula nunc vel felis porttitor, non pharetra magna pellentesque.
                    Suspendisse potenti. Cras vehicula nunc vel felis porttitor, non pharetra magna pellentesque.
                  </p>
                  <p className="blurred-text">
                    Suspendisse potenti. Cras vehicula nunc vel felis porttitor, non pharetra magna pellentesque.
                  </p>
                </div>
                <div className="cta-layer">
                  <Button
                    className="cta-button mx-auto my-2"
                    onClick={() => (window.location.href = 'https://hexoscope.com/#pricing')}
                    name="Upgrade to see full result"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="d-flex justify-content-end">
            {result && module !== 'scrape' ? (
              <div>
                <Button
                  name="Export CSV"
                  outlineSecondary
                  onClick={handleCSVExport}
                  leftIcon={exportIcon}
                  disabled={loading}
                  loading={loading}
                  secondary
                />
              </div>
            ) : null}
          </div>
        </>
      )}
      {upgrade ? (
        <Modal
          isModalOpen={upgrade}
          text="Upgrade to Standard or above plan to enable this feature"
          actionBtnText={'Upgrade'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'warning'}
          cancelBtnColor={'secondary'}
          onActionClick={() => (window.location.href = 'https://hexoscope.com/#pricing')}
          onCancelClick={() => setUpgrade(false)}
          escButtonClick={() => setUpgrade(false)}
        />
      ) : null}
      {csvDownloadError ? (
        <Modal
          isModalOpen={csvDownloadError}
          text="Failed to export CSV. Please try again later."
          actionBtnText={'Close'}
          actionBtnColor={'warning'}
          onActionClick={() => setCsvDownloadError(false)}
          escButtonClick={() => setCsvDownloadError(false)}
        />
      ) : null}
    </div>
  );
};

export default ResultCard;
