export type CountryInfoType = {
  code: string;
  emoji: string;
  name: string;
  unicode: string;
};
type CountryCodesDataType = {
  [code: string]: CountryInfoType;
};

const countriesData: CountryCodesDataType = {
  AC: { code: 'AC', unicode: 'U+1F1E6 U+1F1E8', name: 'Ascension Island', emoji: '🇦🇨' },
  AD: { code: 'AD', unicode: 'U+1F1E6 U+1F1E9', name: 'Andorra', emoji: '🇦🇩' },
  AE: { code: 'AE', unicode: 'U+1F1E6 U+1F1EA', name: 'United Arab Emirates', emoji: '🇦🇪' },
  AF: { code: 'AF', unicode: 'U+1F1E6 U+1F1EB', name: 'Afghanistan', emoji: '🇦🇫' },
  AG: { code: 'AG', unicode: 'U+1F1E6 U+1F1EC', name: 'Antigua & Barbuda', emoji: '🇦🇬' },
  AI: { code: 'AI', unicode: 'U+1F1E6 U+1F1EE', name: 'Anguilla', emoji: '🇦🇮' },
  AL: { code: 'AL', unicode: 'U+1F1E6 U+1F1F1', name: 'Albania', emoji: '🇦🇱' },
  AM: { code: 'AM', unicode: 'U+1F1E6 U+1F1F2', name: 'Armenia', emoji: '🇦🇲' },
  AO: { code: 'AO', unicode: 'U+1F1E6 U+1F1F4', name: 'Angola', emoji: '🇦🇴' },
  AQ: { code: 'AQ', unicode: 'U+1F1E6 U+1F1F6', name: 'Antarctica', emoji: '🇦🇶' },
  AR: { code: 'AR', unicode: 'U+1F1E6 U+1F1F7', name: 'Argentina', emoji: '🇦🇷' },
  AS: { code: 'AS', unicode: 'U+1F1E6 U+1F1F8', name: 'American Samoa', emoji: '🇦🇸' },
  AT: { code: 'AT', unicode: 'U+1F1E6 U+1F1F9', name: 'Austria', emoji: '🇦🇹' },
  AU: { code: 'AU', unicode: 'U+1F1E6 U+1F1FA', name: 'Australia', emoji: '🇦🇺' },
  AW: { code: 'AW', unicode: 'U+1F1E6 U+1F1FC', name: 'Aruba', emoji: '🇦🇼' },
  AX: { code: 'AX', unicode: 'U+1F1E6 U+1F1FD', name: 'Åland Islands', emoji: '🇦🇽' },
  AZ: { code: 'AZ', unicode: 'U+1F1E6 U+1F1FF', name: 'Azerbaijan', emoji: '🇦🇿' },
  BA: { code: 'BA', unicode: 'U+1F1E7 U+1F1E6', name: 'Bosnia & Herzegovina', emoji: '🇧🇦' },
  BB: { code: 'BB', unicode: 'U+1F1E7 U+1F1E7', name: 'Barbados', emoji: '🇧🇧' },
  BD: { code: 'BD', unicode: 'U+1F1E7 U+1F1E9', name: 'Bangladesh', emoji: '🇧🇩' },
  BE: { code: 'BE', unicode: 'U+1F1E7 U+1F1EA', name: 'Belgium', emoji: '🇧🇪' },
  BF: { code: 'BF', unicode: 'U+1F1E7 U+1F1EB', name: 'Burkina Faso', emoji: '🇧🇫' },
  BG: { code: 'BG', unicode: 'U+1F1E7 U+1F1EC', name: 'Bulgaria', emoji: '🇧🇬' },
  BH: { code: 'BH', unicode: 'U+1F1E7 U+1F1ED', name: 'Bahrain', emoji: '🇧🇭' },
  BI: { code: 'BI', unicode: 'U+1F1E7 U+1F1EE', name: 'Burundi', emoji: '🇧🇮' },
  BJ: { code: 'BJ', unicode: 'U+1F1E7 U+1F1EF', name: 'Benin', emoji: '🇧🇯' },
  BL: { code: 'BL', unicode: 'U+1F1E7 U+1F1F1', name: 'St. Barthélemy', emoji: '🇧🇱' },
  BM: { code: 'BM', unicode: 'U+1F1E7 U+1F1F2', name: 'Bermuda', emoji: '🇧🇲' },
  BN: { code: 'BN', unicode: 'U+1F1E7 U+1F1F3', name: 'Brunei', emoji: '🇧🇳' },
  BO: { code: 'BO', unicode: 'U+1F1E7 U+1F1F4', name: 'Bolivia', emoji: '🇧🇴' },
  BQ: { code: 'BQ', unicode: 'U+1F1E7 U+1F1F6', name: 'Caribbean Netherlands', emoji: '🇧🇶' },
  BR: { code: 'BR', unicode: 'U+1F1E7 U+1F1F7', name: 'Brazil', emoji: '🇧🇷' },
  BS: { code: 'BS', unicode: 'U+1F1E7 U+1F1F8', name: 'Bahamas', emoji: '🇧🇸' },
  BT: { code: 'BT', unicode: 'U+1F1E7 U+1F1F9', name: 'Bhutan', emoji: '🇧🇹' },
  BV: { code: 'BV', unicode: 'U+1F1E7 U+1F1FB', name: 'Bouvet Island', emoji: '🇧🇻' },
  BW: { code: 'BW', unicode: 'U+1F1E7 U+1F1FC', name: 'Botswana', emoji: '🇧🇼' },
  BY: { code: 'BY', unicode: 'U+1F1E7 U+1F1FE', name: 'Belarus', emoji: '🇧🇾' },
  BZ: { code: 'BZ', unicode: 'U+1F1E7 U+1F1FF', name: 'Belize', emoji: '🇧🇿' },
  CA: { code: 'CA', unicode: 'U+1F1E8 U+1F1E6', name: 'Canada', emoji: '🇨🇦' },
  CC: { code: 'CC', unicode: 'U+1F1E8 U+1F1E8', name: 'Cocos (Keeling) Islands', emoji: '🇨🇨' },
  CD: { code: 'CD', unicode: 'U+1F1E8 U+1F1E9', name: 'Congo - Kinshasa', emoji: '🇨🇩' },
  CF: { code: 'CF', unicode: 'U+1F1E8 U+1F1EB', name: 'Central African Republic', emoji: '🇨🇫' },
  CG: { code: 'CG', unicode: 'U+1F1E8 U+1F1EC', name: 'Congo - Brazzaville', emoji: '🇨🇬' },
  CH: { code: 'CH', unicode: 'U+1F1E8 U+1F1ED', name: 'Switzerland', emoji: '🇨🇭' },
  CI: { code: 'CI', unicode: 'U+1F1E8 U+1F1EE', name: 'Côte d’Ivoire', emoji: '🇨🇮' },
  CK: { code: 'CK', unicode: 'U+1F1E8 U+1F1F0', name: 'Cook Islands', emoji: '🇨🇰' },
  CL: { code: 'CL', unicode: 'U+1F1E8 U+1F1F1', name: 'Chile', emoji: '🇨🇱' },
  CM: { code: 'CM', unicode: 'U+1F1E8 U+1F1F2', name: 'Cameroon', emoji: '🇨🇲' },
  CN: { code: 'CN', unicode: 'U+1F1E8 U+1F1F3', name: 'China', emoji: '🇨🇳' },
  CO: { code: 'CO', unicode: 'U+1F1E8 U+1F1F4', name: 'Colombia', emoji: '🇨🇴' },
  CP: { code: 'CP', unicode: 'U+1F1E8 U+1F1F5', name: 'Clipperton Island', emoji: '🇨🇵' },
  CR: { code: 'CR', unicode: 'U+1F1E8 U+1F1F7', name: 'Costa Rica', emoji: '🇨🇷' },
  CU: { code: 'CU', unicode: 'U+1F1E8 U+1F1FA', name: 'Cuba', emoji: '🇨🇺' },
  CV: { code: 'CV', unicode: 'U+1F1E8 U+1F1FB', name: 'Cape Verde', emoji: '🇨🇻' },
  CW: { code: 'CW', unicode: 'U+1F1E8 U+1F1FC', name: 'Curaçao', emoji: '🇨🇼' },
  CX: { code: 'CX', unicode: 'U+1F1E8 U+1F1FD', name: 'Christmas Island', emoji: '🇨🇽' },
  CY: { code: 'CY', unicode: 'U+1F1E8 U+1F1FE', name: 'Cyprus', emoji: '🇨🇾' },
  CZ: { code: 'CZ', unicode: 'U+1F1E8 U+1F1FF', name: 'Czechia', emoji: '🇨🇿' },
  DE: { code: 'DE', unicode: 'U+1F1E9 U+1F1EA', name: 'Germany', emoji: '🇩🇪' },
  DG: { code: 'DG', unicode: 'U+1F1E9 U+1F1EC', name: 'Diego Garcia', emoji: '🇩🇬' },
  DJ: { code: 'DJ', unicode: 'U+1F1E9 U+1F1EF', name: 'Djibouti', emoji: '🇩🇯' },
  DK: { code: 'DK', unicode: 'U+1F1E9 U+1F1F0', name: 'Denmark', emoji: '🇩🇰' },
  DM: { code: 'DM', unicode: 'U+1F1E9 U+1F1F2', name: 'Dominica', emoji: '🇩🇲' },
  DO: { code: 'DO', unicode: 'U+1F1E9 U+1F1F4', name: 'Dominican Republic', emoji: '🇩🇴' },
  DZ: { code: 'DZ', unicode: 'U+1F1E9 U+1F1FF', name: 'Algeria', emoji: '🇩🇿' },
  EA: { code: 'EA', unicode: 'U+1F1EA U+1F1E6', name: 'Ceuta & Melilla', emoji: '🇪🇦' },
  EC: { code: 'EC', unicode: 'U+1F1EA U+1F1E8', name: 'Ecuador', emoji: '🇪🇨' },
  EE: { code: 'EE', unicode: 'U+1F1EA U+1F1EA', name: 'Estonia', emoji: '🇪🇪' },
  EG: { code: 'EG', unicode: 'U+1F1EA U+1F1EC', name: 'Egypt', emoji: '🇪🇬' },
  EH: { code: 'EH', unicode: 'U+1F1EA U+1F1ED', name: 'Western Sahara', emoji: '🇪🇭' },
  ER: { code: 'ER', unicode: 'U+1F1EA U+1F1F7', name: 'Eritrea', emoji: '🇪🇷' },
  ES: { code: 'ES', unicode: 'U+1F1EA U+1F1F8', name: 'Spain', emoji: '🇪🇸' },
  ET: { code: 'ET', unicode: 'U+1F1EA U+1F1F9', name: 'Ethiopia', emoji: '🇪🇹' },
  EU: { code: 'EU', unicode: 'U+1F1EA U+1F1FA', name: 'European Union', emoji: '🇪🇺' },
  FI: { code: 'FI', unicode: 'U+1F1EB U+1F1EE', name: 'Finland', emoji: '🇫🇮' },
  FJ: { code: 'FJ', unicode: 'U+1F1EB U+1F1EF', name: 'Fiji', emoji: '🇫🇯' },
  FK: { code: 'FK', unicode: 'U+1F1EB U+1F1F0', name: 'Falkland Islands', emoji: '🇫🇰' },
  FM: { code: 'FM', unicode: 'U+1F1EB U+1F1F2', name: 'Micronesia', emoji: '🇫🇲' },
  FO: { code: 'FO', unicode: 'U+1F1EB U+1F1F4', name: 'Faroe Islands', emoji: '🇫🇴' },
  FR: { code: 'FR', unicode: 'U+1F1EB U+1F1F7', name: 'France', emoji: '🇫🇷' },
  GA: { code: 'GA', unicode: 'U+1F1EC U+1F1E6', name: 'Gabon', emoji: '🇬🇦' },
  GB: { code: 'GB', unicode: 'U+1F1EC U+1F1E7', name: 'United Kingdom', emoji: '🇬🇧' },
  GD: { code: 'GD', unicode: 'U+1F1EC U+1F1E9', name: 'Grenada', emoji: '🇬🇩' },
  GE: { code: 'GE', unicode: 'U+1F1EC U+1F1EA', name: 'Georgia', emoji: '🇬🇪' },
  GF: { code: 'GF', unicode: 'U+1F1EC U+1F1EB', name: 'French Guiana', emoji: '🇬🇫' },
  GG: { code: 'GG', unicode: 'U+1F1EC U+1F1EC', name: 'Guernsey', emoji: '🇬🇬' },
  GH: { code: 'GH', unicode: 'U+1F1EC U+1F1ED', name: 'Ghana', emoji: '🇬🇭' },
  GI: { code: 'GI', unicode: 'U+1F1EC U+1F1EE', name: 'Gibraltar', emoji: '🇬🇮' },
  GL: { code: 'GL', unicode: 'U+1F1EC U+1F1F1', name: 'Greenland', emoji: '🇬🇱' },
  GM: { code: 'GM', unicode: 'U+1F1EC U+1F1F2', name: 'Gambia', emoji: '🇬🇲' },
  GN: { code: 'GN', unicode: 'U+1F1EC U+1F1F3', name: 'Guinea', emoji: '🇬🇳' },
  GP: { code: 'GP', unicode: 'U+1F1EC U+1F1F5', name: 'Guadeloupe', emoji: '🇬🇵' },
  GQ: { code: 'GQ', unicode: 'U+1F1EC U+1F1F6', name: 'Equatorial Guinea', emoji: '🇬🇶' },
  GR: { code: 'GR', unicode: 'U+1F1EC U+1F1F7', name: 'Greece', emoji: '🇬🇷' },
  GS: { code: 'GS', unicode: 'U+1F1EC U+1F1F8', name: 'South Georgia & South Sandwich Islands', emoji: '🇬🇸' },
  GT: { code: 'GT', unicode: 'U+1F1EC U+1F1F9', name: 'Guatemala', emoji: '🇬🇹' },
  GU: { code: 'GU', unicode: 'U+1F1EC U+1F1FA', name: 'Guam', emoji: '🇬🇺' },
  GW: { code: 'GW', unicode: 'U+1F1EC U+1F1FC', name: 'Guinea-Bissau', emoji: '🇬🇼' },
  GY: { code: 'GY', unicode: 'U+1F1EC U+1F1FE', name: 'Guyana', emoji: '🇬🇾' },
  HK: { code: 'HK', unicode: 'U+1F1ED U+1F1F0', name: 'Hong Kong SAR China', emoji: '🇭🇰' },
  HM: { code: 'HM', unicode: 'U+1F1ED U+1F1F2', name: 'Heard & McDonald Islands', emoji: '🇭🇲' },
  HN: { code: 'HN', unicode: 'U+1F1ED U+1F1F3', name: 'Honduras', emoji: '🇭🇳' },
  HR: { code: 'HR', unicode: 'U+1F1ED U+1F1F7', name: 'Croatia', emoji: '🇭🇷' },
  HT: { code: 'HT', unicode: 'U+1F1ED U+1F1F9', name: 'Haiti', emoji: '🇭🇹' },
  HU: { code: 'HU', unicode: 'U+1F1ED U+1F1FA', name: 'Hungary', emoji: '🇭🇺' },
  IC: { code: 'IC', unicode: 'U+1F1EE U+1F1E8', name: 'Canary Islands', emoji: '🇮🇨' },
  ID: { code: 'ID', unicode: 'U+1F1EE U+1F1E9', name: 'Indonesia', emoji: '🇮🇩' },
  IE: { code: 'IE', unicode: 'U+1F1EE U+1F1EA', name: 'Ireland', emoji: '🇮🇪' },
  IL: { code: 'IL', unicode: 'U+1F1EE U+1F1F1', name: 'Israel', emoji: '🇮🇱' },
  IM: { code: 'IM', unicode: 'U+1F1EE U+1F1F2', name: 'Isle of Man', emoji: '🇮🇲' },
  IN: { code: 'IN', unicode: 'U+1F1EE U+1F1F3', name: 'India', emoji: '🇮🇳' },
  IO: { code: 'IO', unicode: 'U+1F1EE U+1F1F4', name: 'British Indian Ocean Territory', emoji: '🇮🇴' },
  IQ: { code: 'IQ', unicode: 'U+1F1EE U+1F1F6', name: 'Iraq', emoji: '🇮🇶' },
  IR: { code: 'IR', unicode: 'U+1F1EE U+1F1F7', name: 'Iran', emoji: '🇮🇷' },
  IS: { code: 'IS', unicode: 'U+1F1EE U+1F1F8', name: 'Iceland', emoji: '🇮🇸' },
  IT: { code: 'IT', unicode: 'U+1F1EE U+1F1F9', name: 'Italy', emoji: '🇮🇹' },
  JE: { code: 'JE', unicode: 'U+1F1EF U+1F1EA', name: 'Jersey', emoji: '🇯🇪' },
  JM: { code: 'JM', unicode: 'U+1F1EF U+1F1F2', name: 'Jamaica', emoji: '🇯🇲' },
  JO: { code: 'JO', unicode: 'U+1F1EF U+1F1F4', name: 'Jordan', emoji: '🇯🇴' },
  JP: { code: 'JP', unicode: 'U+1F1EF U+1F1F5', name: 'Japan', emoji: '🇯🇵' },
  KE: { code: 'KE', unicode: 'U+1F1F0 U+1F1EA', name: 'Kenya', emoji: '🇰🇪' },
  KG: { code: 'KG', unicode: 'U+1F1F0 U+1F1EC', name: 'Kyrgyzstan', emoji: '🇰🇬' },
  KH: { code: 'KH', unicode: 'U+1F1F0 U+1F1ED', name: 'Cambodia', emoji: '🇰🇭' },
  KI: { code: 'KI', unicode: 'U+1F1F0 U+1F1EE', name: 'Kiribati', emoji: '🇰🇮' },
  KM: { code: 'KM', unicode: 'U+1F1F0 U+1F1F2', name: 'Comoros', emoji: '🇰🇲' },
  KN: { code: 'KN', unicode: 'U+1F1F0 U+1F1F3', name: 'St. Kitts & Nevis', emoji: '🇰🇳' },
  KP: { code: 'KP', unicode: 'U+1F1F0 U+1F1F5', name: 'North Korea', emoji: '🇰🇵' },
  KR: { code: 'KR', unicode: 'U+1F1F0 U+1F1F7', name: 'South Korea', emoji: '🇰🇷' },
  KW: { code: 'KW', unicode: 'U+1F1F0 U+1F1FC', name: 'Kuwait', emoji: '🇰🇼' },
  KY: { code: 'KY', unicode: 'U+1F1F0 U+1F1FE', name: 'Cayman Islands', emoji: '🇰🇾' },
  KZ: { code: 'KZ', unicode: 'U+1F1F0 U+1F1FF', name: 'Kazakhstan', emoji: '🇰🇿' },
  LA: { code: 'LA', unicode: 'U+1F1F1 U+1F1E6', name: 'Laos', emoji: '🇱🇦' },
  LB: { code: 'LB', unicode: 'U+1F1F1 U+1F1E7', name: 'Lebanon', emoji: '🇱🇧' },
  LC: { code: 'LC', unicode: 'U+1F1F1 U+1F1E8', name: 'St. Lucia', emoji: '🇱🇨' },
  LI: { code: 'LI', unicode: 'U+1F1F1 U+1F1EE', name: 'Liechtenstein', emoji: '🇱🇮' },
  LK: { code: 'LK', unicode: 'U+1F1F1 U+1F1F0', name: 'Sri Lanka', emoji: '🇱🇰' },
  LR: { code: 'LR', unicode: 'U+1F1F1 U+1F1F7', name: 'Liberia', emoji: '🇱🇷' },
  LS: { code: 'LS', unicode: 'U+1F1F1 U+1F1F8', name: 'Lesotho', emoji: '🇱🇸' },
  LT: { code: 'LT', unicode: 'U+1F1F1 U+1F1F9', name: 'Lithuania', emoji: '🇱🇹' },
  LU: { code: 'LU', unicode: 'U+1F1F1 U+1F1FA', name: 'Luxembourg', emoji: '🇱🇺' },
  LV: { code: 'LV', unicode: 'U+1F1F1 U+1F1FB', name: 'Latvia', emoji: '🇱🇻' },
  LY: { code: 'LY', unicode: 'U+1F1F1 U+1F1FE', name: 'Libya', emoji: '🇱🇾' },
  MA: { code: 'MA', unicode: 'U+1F1F2 U+1F1E6', name: 'Morocco', emoji: '🇲🇦' },
  MC: { code: 'MC', unicode: 'U+1F1F2 U+1F1E8', name: 'Monaco', emoji: '🇲🇨' },
  MD: { code: 'MD', unicode: 'U+1F1F2 U+1F1E9', name: 'Moldova', emoji: '🇲🇩' },
  ME: { code: 'ME', unicode: 'U+1F1F2 U+1F1EA', name: 'Montenegro', emoji: '🇲🇪' },
  MF: { code: 'MF', unicode: 'U+1F1F2 U+1F1EB', name: 'St. Martin', emoji: '🇲🇫' },
  MG: { code: 'MG', unicode: 'U+1F1F2 U+1F1EC', name: 'Madagascar', emoji: '🇲🇬' },
  MH: { code: 'MH', unicode: 'U+1F1F2 U+1F1ED', name: 'Marshall Islands', emoji: '🇲🇭' },
  MK: { code: 'MK', unicode: 'U+1F1F2 U+1F1F0', name: 'Macedonia', emoji: '🇲🇰' },
  ML: { code: 'ML', unicode: 'U+1F1F2 U+1F1F1', name: 'Mali', emoji: '🇲🇱' },
  MM: { code: 'MM', unicode: 'U+1F1F2 U+1F1F2', name: 'Myanmar (Burma)', emoji: '🇲🇲' },
  MN: { code: 'MN', unicode: 'U+1F1F2 U+1F1F3', name: 'Mongolia', emoji: '🇲🇳' },
  MO: { code: 'MO', unicode: 'U+1F1F2 U+1F1F4', name: 'Macau SAR China', emoji: '🇲🇴' },
  MP: { code: 'MP', unicode: 'U+1F1F2 U+1F1F5', name: 'Northern Mariana Islands', emoji: '🇲🇵' },
  MQ: { code: 'MQ', unicode: 'U+1F1F2 U+1F1F6', name: 'Martinique', emoji: '🇲🇶' },
  MR: { code: 'MR', unicode: 'U+1F1F2 U+1F1F7', name: 'Mauritania', emoji: '🇲🇷' },
  MS: { code: 'MS', unicode: 'U+1F1F2 U+1F1F8', name: 'Montserrat', emoji: '🇲🇸' },
  MT: { code: 'MT', unicode: 'U+1F1F2 U+1F1F9', name: 'Malta', emoji: '🇲🇹' },
  MU: { code: 'MU', unicode: 'U+1F1F2 U+1F1FA', name: 'Mauritius', emoji: '🇲🇺' },
  MV: { code: 'MV', unicode: 'U+1F1F2 U+1F1FB', name: 'Maldives', emoji: '🇲🇻' },
  MW: { code: 'MW', unicode: 'U+1F1F2 U+1F1FC', name: 'Malawi', emoji: '🇲🇼' },
  MX: { code: 'MX', unicode: 'U+1F1F2 U+1F1FD', name: 'Mexico', emoji: '🇲🇽' },
  MY: { code: 'MY', unicode: 'U+1F1F2 U+1F1FE', name: 'Malaysia', emoji: '🇲🇾' },
  MZ: { code: 'MZ', unicode: 'U+1F1F2 U+1F1FF', name: 'Mozambique', emoji: '🇲🇿' },
  NA: { code: 'NA', unicode: 'U+1F1F3 U+1F1E6', name: 'Namibia', emoji: '🇳🇦' },
  NC: { code: 'NC', unicode: 'U+1F1F3 U+1F1E8', name: 'New Caledonia', emoji: '🇳🇨' },
  NE: { code: 'NE', unicode: 'U+1F1F3 U+1F1EA', name: 'Niger', emoji: '🇳🇪' },
  NF: { code: 'NF', unicode: 'U+1F1F3 U+1F1EB', name: 'Norfolk Island', emoji: '🇳🇫' },
  NG: { code: 'NG', unicode: 'U+1F1F3 U+1F1EC', name: 'Nigeria', emoji: '🇳🇬' },
  NI: { code: 'NI', unicode: 'U+1F1F3 U+1F1EE', name: 'Nicaragua', emoji: '🇳🇮' },
  NL: { code: 'NL', unicode: 'U+1F1F3 U+1F1F1', name: 'Netherlands', emoji: '🇳🇱' },
  NO: { code: 'NO', unicode: 'U+1F1F3 U+1F1F4', name: 'Norway', emoji: '🇳🇴' },
  NP: { code: 'NP', unicode: 'U+1F1F3 U+1F1F5', name: 'Nepal', emoji: '🇳🇵' },
  NR: { code: 'NR', unicode: 'U+1F1F3 U+1F1F7', name: 'Nauru', emoji: '🇳🇷' },
  NU: { code: 'NU', unicode: 'U+1F1F3 U+1F1FA', name: 'Niue', emoji: '🇳🇺' },
  NZ: { code: 'NZ', unicode: 'U+1F1F3 U+1F1FF', name: 'New Zealand', emoji: '🇳🇿' },
  OM: { code: 'OM', unicode: 'U+1F1F4 U+1F1F2', name: 'Oman', emoji: '🇴🇲' },
  PA: { code: 'PA', unicode: 'U+1F1F5 U+1F1E6', name: 'Panama', emoji: '🇵🇦' },
  PE: { code: 'PE', unicode: 'U+1F1F5 U+1F1EA', name: 'Peru', emoji: '🇵🇪' },
  PF: { code: 'PF', unicode: 'U+1F1F5 U+1F1EB', name: 'French Polynesia', emoji: '🇵🇫' },
  PG: { code: 'PG', unicode: 'U+1F1F5 U+1F1EC', name: 'Papua New Guinea', emoji: '🇵🇬' },
  PH: { code: 'PH', unicode: 'U+1F1F5 U+1F1ED', name: 'Philippines', emoji: '🇵🇭' },
  PK: { code: 'PK', unicode: 'U+1F1F5 U+1F1F0', name: 'Pakistan', emoji: '🇵🇰' },
  PL: { code: 'PL', unicode: 'U+1F1F5 U+1F1F1', name: 'Poland', emoji: '🇵🇱' },
  PM: { code: 'PM', unicode: 'U+1F1F5 U+1F1F2', name: 'St. Pierre & Miquelon', emoji: '🇵🇲' },
  PN: { code: 'PN', unicode: 'U+1F1F5 U+1F1F3', name: 'Pitcairn Islands', emoji: '🇵🇳' },
  PR: { code: 'PR', unicode: 'U+1F1F5 U+1F1F7', name: 'Puerto Rico', emoji: '🇵🇷' },
  PS: { code: 'PS', unicode: 'U+1F1F5 U+1F1F8', name: 'Palestinian Territories', emoji: '🇵🇸' },
  PT: { code: 'PT', unicode: 'U+1F1F5 U+1F1F9', name: 'Portugal', emoji: '🇵🇹' },
  PW: { code: 'PW', unicode: 'U+1F1F5 U+1F1FC', name: 'Palau', emoji: '🇵🇼' },
  PY: { code: 'PY', unicode: 'U+1F1F5 U+1F1FE', name: 'Paraguay', emoji: '🇵🇾' },
  QA: { code: 'QA', unicode: 'U+1F1F6 U+1F1E6', name: 'Qatar', emoji: '🇶🇦' },
  RE: { code: 'RE', unicode: 'U+1F1F7 U+1F1EA', name: 'Réunion', emoji: '🇷🇪' },
  RO: { code: 'RO', unicode: 'U+1F1F7 U+1F1F4', name: 'Romania', emoji: '🇷🇴' },
  RS: { code: 'RS', unicode: 'U+1F1F7 U+1F1F8', name: 'Serbia', emoji: '🇷🇸' },
  RU: { code: 'RU', unicode: 'U+1F1F7 U+1F1FA', name: 'Russia', emoji: '🇷🇺' },
  RW: { code: 'RW', unicode: 'U+1F1F7 U+1F1FC', name: 'Rwanda', emoji: '🇷🇼' },
  SA: { code: 'SA', unicode: 'U+1F1F8 U+1F1E6', name: 'Saudi Arabia', emoji: '🇸🇦' },
  SB: { code: 'SB', unicode: 'U+1F1F8 U+1F1E7', name: 'Solomon Islands', emoji: '🇸🇧' },
  SC: { code: 'SC', unicode: 'U+1F1F8 U+1F1E8', name: 'Seychelles', emoji: '🇸🇨' },
  SD: { code: 'SD', unicode: 'U+1F1F8 U+1F1E9', name: 'Sudan', emoji: '🇸🇩' },
  SE: { code: 'SE', unicode: 'U+1F1F8 U+1F1EA', name: 'Sweden', emoji: '🇸🇪' },
  SG: { code: 'SG', unicode: 'U+1F1F8 U+1F1EC', name: 'Singapore', emoji: '🇸🇬' },
  SH: { code: 'SH', unicode: 'U+1F1F8 U+1F1ED', name: 'St. Helena', emoji: '🇸🇭' },
  SI: { code: 'SI', unicode: 'U+1F1F8 U+1F1EE', name: 'Slovenia', emoji: '🇸🇮' },
  SJ: { code: 'SJ', unicode: 'U+1F1F8 U+1F1EF', name: 'Svalbard & Jan Mayen', emoji: '🇸🇯' },
  SK: { code: 'SK', unicode: 'U+1F1F8 U+1F1F0', name: 'Slovakia', emoji: '🇸🇰' },
  SL: { code: 'SL', unicode: 'U+1F1F8 U+1F1F1', name: 'Sierra Leone', emoji: '🇸🇱' },
  SM: { code: 'SM', unicode: 'U+1F1F8 U+1F1F2', name: 'San Marino', emoji: '🇸🇲' },
  SN: { code: 'SN', unicode: 'U+1F1F8 U+1F1F3', name: 'Senegal', emoji: '🇸🇳' },
  SO: { code: 'SO', unicode: 'U+1F1F8 U+1F1F4', name: 'Somalia', emoji: '🇸🇴' },
  SR: { code: 'SR', unicode: 'U+1F1F8 U+1F1F7', name: 'Suriname', emoji: '🇸🇷' },
  SS: { code: 'SS', unicode: 'U+1F1F8 U+1F1F8', name: 'South Sudan', emoji: '🇸🇸' },
  ST: { code: 'ST', unicode: 'U+1F1F8 U+1F1F9', name: 'São Tomé & Príncipe', emoji: '🇸🇹' },
  SV: { code: 'SV', unicode: 'U+1F1F8 U+1F1FB', name: 'El Salvador', emoji: '🇸🇻' },
  SX: { code: 'SX', unicode: 'U+1F1F8 U+1F1FD', name: 'Sint Maarten', emoji: '🇸🇽' },
  SY: { code: 'SY', unicode: 'U+1F1F8 U+1F1FE', name: 'Syria', emoji: '🇸🇾' },
  SZ: { code: 'SZ', unicode: 'U+1F1F8 U+1F1FF', name: 'Swaziland', emoji: '🇸🇿' },
  TA: { code: 'TA', unicode: 'U+1F1F9 U+1F1E6', name: 'Tristan da Cunha', emoji: '🇹🇦' },
  TC: { code: 'TC', unicode: 'U+1F1F9 U+1F1E8', name: 'Turks & Caicos Islands', emoji: '🇹🇨' },
  TD: { code: 'TD', unicode: 'U+1F1F9 U+1F1E9', name: 'Chad', emoji: '🇹🇩' },
  TF: { code: 'TF', unicode: 'U+1F1F9 U+1F1EB', name: 'French Southern Territories', emoji: '🇹🇫' },
  TG: { code: 'TG', unicode: 'U+1F1F9 U+1F1EC', name: 'Togo', emoji: '🇹🇬' },
  TH: { code: 'TH', unicode: 'U+1F1F9 U+1F1ED', name: 'Thailand', emoji: '🇹🇭' },
  TJ: { code: 'TJ', unicode: 'U+1F1F9 U+1F1EF', name: 'Tajikistan', emoji: '🇹🇯' },
  TK: { code: 'TK', unicode: 'U+1F1F9 U+1F1F0', name: 'Tokelau', emoji: '🇹🇰' },
  TL: { code: 'TL', unicode: 'U+1F1F9 U+1F1F1', name: 'Timor-Leste', emoji: '🇹🇱' },
  TM: { code: 'TM', unicode: 'U+1F1F9 U+1F1F2', name: 'Turkmenistan', emoji: '🇹🇲' },
  TN: { code: 'TN', unicode: 'U+1F1F9 U+1F1F3', name: 'Tunisia', emoji: '🇹🇳' },
  TO: { code: 'TO', unicode: 'U+1F1F9 U+1F1F4', name: 'Tonga', emoji: '🇹🇴' },
  TR: { code: 'TR', unicode: 'U+1F1F9 U+1F1F7', name: 'Turkey', emoji: '🇹🇷' },
  TT: { code: 'TT', unicode: 'U+1F1F9 U+1F1F9', name: 'Trinidad & Tobago', emoji: '🇹🇹' },
  TV: { code: 'TV', unicode: 'U+1F1F9 U+1F1FB', name: 'Tuvalu', emoji: '🇹🇻' },
  TW: { code: 'TW', unicode: 'U+1F1F9 U+1F1FC', name: 'Taiwan', emoji: '🇹🇼' },
  TZ: { code: 'TZ', unicode: 'U+1F1F9 U+1F1FF', name: 'Tanzania', emoji: '🇹🇿' },
  UA: { code: 'UA', unicode: 'U+1F1FA U+1F1E6', name: 'Ukraine', emoji: '🇺🇦' },
  UG: { code: 'UG', unicode: 'U+1F1FA U+1F1EC', name: 'Uganda', emoji: '🇺🇬' },
  UM: { code: 'UM', unicode: 'U+1F1FA U+1F1F2', name: 'U.S. Outlying Islands', emoji: '🇺🇲' },
  UN: { code: 'UN', unicode: 'U+1F1FA U+1F1F3', name: 'United Nations', emoji: '🇺🇳' },
  US: { code: 'US', unicode: 'U+1F1FA U+1F1F8', name: 'United States', emoji: '🇺🇸' },
  UY: { code: 'UY', unicode: 'U+1F1FA U+1F1FE', name: 'Uruguay', emoji: '🇺🇾' },
  UZ: { code: 'UZ', unicode: 'U+1F1FA U+1F1FF', name: 'Uzbekistan', emoji: '🇺🇿' },
  VA: { code: 'VA', unicode: 'U+1F1FB U+1F1E6', name: 'Vatican City', emoji: '🇻🇦' },
  VC: { code: 'VC', unicode: 'U+1F1FB U+1F1E8', name: 'St. Vincent & Grenadines', emoji: '🇻🇨' },
  VE: { code: 'VE', unicode: 'U+1F1FB U+1F1EA', name: 'Venezuela', emoji: '🇻🇪' },
  VG: { code: 'VG', unicode: 'U+1F1FB U+1F1EC', name: 'British Virgin Islands', emoji: '🇻🇬' },
  VI: { code: 'VI', unicode: 'U+1F1FB U+1F1EE', name: 'U.S. Virgin Islands', emoji: '🇻🇮' },
  VN: { code: 'VN', unicode: 'U+1F1FB U+1F1F3', name: 'Vietnam', emoji: '🇻🇳' },
  VU: { code: 'VU', unicode: 'U+1F1FB U+1F1FA', name: 'Vanuatu', emoji: '🇻🇺' },
  WF: { code: 'WF', unicode: 'U+1F1FC U+1F1EB', name: 'Wallis & Futuna', emoji: '🇼🇫' },
  WS: { code: 'WS', unicode: 'U+1F1FC U+1F1F8', name: 'Samoa', emoji: '🇼🇸' },
  XK: { code: 'XK', unicode: 'U+1F1FD U+1F1F0', name: 'Kosovo', emoji: '🇽🇰' },
  YE: { code: 'YE', unicode: 'U+1F1FE U+1F1EA', name: 'Yemen', emoji: '🇾🇪' },
  YT: { code: 'YT', unicode: 'U+1F1FE U+1F1F9', name: 'Mayotte', emoji: '🇾🇹' },
  ZA: { code: 'ZA', unicode: 'U+1F1FF U+1F1E6', name: 'South Africa', emoji: '🇿🇦' },
  ZM: { code: 'ZM', unicode: 'U+1F1FF U+1F1F2', name: 'Zambia', emoji: '🇿🇲' },
  ZW: { code: 'ZW', unicode: 'U+1F1FF U+1F1FC', name: 'Zimbabwe', emoji: '🇿🇼' },
};

export default countriesData;
