import { FC, memo, useCallback } from 'react';
import './styles.scss';

interface IRadioButton {
  enabled: boolean;
  label?: any;
  onChange: (arg: any) => void;
  canDisable?: boolean;
  disabled?: boolean;
  className?: string;
  isBlue?: boolean;
}

const RadioButton: FC<IRadioButton> = ({ enabled, label, onChange, canDisable, disabled, className, isBlue }) => {
  const handleChange = useCallback(() => {
    !disabled && onChange(canDisable ? !enabled : label === 'Enabled' ? true : false);
  }, [disabled, onChange, canDisable, enabled, label]);
  return (
    <div className={`vk-custom-radio noselect ${className ? className : ''}`} onClick={handleChange}>
      <div className={`outer-circle ${disabled ? 'disabled' : ''} ${isBlue ? 'blue' : ''}`}>
        {enabled && <div className={`inner-circle ${disabled ? 'disabled' : ''}`}></div>}
      </div>
      {label && <span className="noselect label ml-2">{label}</span>}
    </div>
  );
};

export default memo(RadioButton);
