import { useMutation } from '@apollo/client';
import { UserContext } from 'contexts/user-context';
import { LOG_OUT } from 'graphql/mutations';
import { useContext, useEffect } from 'react';
import {
  removeRefreshHash,
  removeUserSignedUpViaGoogle,
  getRawCookie,
  removeRefreshToken,
  removeAccessToken,
  removeRawCookie,
} from 'helpers';

export default () => {
  const { user } = useContext(UserContext);
  const [log_out] = useMutation(LOG_OUT);

  const handleLogOut = async (id: any) => {
    const { data } = await log_out({ variables: { id, device_key: getRawCookie('device_key') } });
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      removeRawCookie('__session');
      removeRawCookie('device_key');
      removeRawCookie('ref_token');
      removeRefreshToken();
      removeRefreshHash();
      removeAccessToken();
      removeUserSignedUpViaGoogle();
      if (!window.location.href.includes('/dev-login')) {
        window.location.href.indexOf('localhost') > -1
          ? window.location.replace('http://localhost:3000/dev-login')
          : window.location.href.indexOf('37.186.119.181') > -1
          ? window.location.replace('http://37.186.119.181:3279/dev-login')
          : window.location.href.indexOf('dashhexospark.hexact.io') > -1
          ? window.location.replace('https://hexoscope.hexact.io')
          : window.location.replace('https://hexoscope.com');
      }
    }
  };
  useEffect(() => {
    if (user && user.id) {
      handleLogOut(+user.id);
    }
  }, [user]);

  return <></>;
};
