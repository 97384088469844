import { FC, useState, useEffect } from 'react';
import Loader from 'components/atom/loader';
import Badge from 'components/atom/badge';
import { downloadBtnIconGrey } from 'images/svg-icons';
import moment from 'moment';
import { LIST_CUSTOMER_INVOICES } from 'graphql/queries';
import { useQuery } from '@apollo/client';

interface IInvoice {
  amountPaid: number;
  createdAt: string;
  invoiceId: string;
  invoicePdf: string;
  status: string;
  total: string;
}

const PaymentHistory: FC<{ productType: string }> = ({ productType }) => {
  const [invoices, setInvoices] = useState([]);
  const { data, loading } = useQuery(LIST_CUSTOMER_INVOICES, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        productType: productType,
      },
    },
  });

  useEffect(() => {
    if (data && data.Billing && data.Billing.listCustomerInvoices && data.Billing.listCustomerInvoices.invoices) {
      setInvoices(data.Billing.listCustomerInvoices.invoices);
    }
    if (
      data &&
      data.Billing &&
      data.Billing.listCustomerPaymentIntents &&
      data.Billing.listCustomerPaymentIntents.paymentIntents
    ) {
      setInvoices(data.Billing.listCustomerPaymentIntents.paymentIntents);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="invoices-card">
          {invoices.length > 0 ? (
            <div className="invoices table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>DATE</td>
                    <td>AMOUNT</td>
                    <td>STATUS</td>
                    <td></td>
                  </tr>
                </thead>
                {invoices.map((invoice: IInvoice) => (
                  <tbody>
                    <tr>
                      <td>{moment(invoice.createdAt).format('DD-MM-YY hh:ss')}</td>
                      <td>$ {invoice.total} </td>
                      <td>
                        <Badge
                          messageType={
                            invoice.status === 'paid' || invoice?.status?.toLocaleLowerCase() === 'completed'
                              ? 'badge-success'
                              : 'badge-alert'
                          }
                          buttonType="outline"
                        >
                          <span>{invoice.status}</span>
                        </Badge>
                      </td>
                      <td>
                        {invoice.invoicePdf && (
                          <a href={invoice.invoicePdf} target="_blank" className="download-icon" rel="noopener">
                            {downloadBtnIconGrey}
                          </a>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          ) : (
            <div className="text-center secondary-color">No payment history</div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentHistory;
