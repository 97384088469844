import { FC, useEffect, useState, memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { blockTags } from '../../../constants';
import useClickOutside from '../../../hooks/useClickOutside';
import { ITooltip } from './types';
import { eventStop } from '../../../helpers';
import { closeIcon } from 'images/svg-icons';
import './styles.scss';

const Tooltip: FC<ITooltip> = ({
  placement = 'top',
  text,
  element,
  children,
  isHover = true,
  className,
  isSmall,
  content,
  trigger = ['hover'],
  oneLine,
  maxLengthInRow,
  marginTop,
  whatsNewId,
  whatsNewMessage,
  whatsNewIcon,
  addTop,
  handleCloseFromParent,
  topLocation,
  leftLocation,
  top,
  right,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  //!------------State------------------
  //Boolean
  const [show, setShow] = useState(false);
  const [inline, setInline] = useState(false);
  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const [isOnlyClick] = useState(trigger.length === 1 && trigger[0] === 'click');
  //Number
  const [toggle] = useState(Math.floor(Math.random() * 100000));
  const [height, setHeight] = useState(25);
  //
  const [showOptions, selectRef, setShowOptions] = useClickOutside(false, val => {
    setShow(val);
  });
  //!---------------------useMemo-------------
  const style = useMemo(() => {
    return { minHeight: height + 'px', top: placement === 'bottom' ? height + (addTop || 0) + 'px' : undefined };
  }, [height, placement]);
  //!--------------------useEffect------------
  useEffect(() => {
    if (
      (placement === 'bottom' || placement === 'top') &&
      document.querySelector(`.tooltip-text-${toggle}`) &&
      document.querySelector(`#tooltip-hover-${toggle}`)
    ) {
      //@ts-ignore
      document.querySelector(`#tooltip-hover-${toggle}`).classList.add('d-block');
      const height =
        //@ts-ignore
        document.querySelector(`.tooltip-text-${toggle}`) &&
        //@ts-ignore
        document.querySelector(`.tooltip-text-${toggle}`).offsetHeight;
      setHeight(height || 28);
      //@ts-ignore
      document.querySelector(`#tooltip-hover-${toggle}`).classList.remove('d-block');
    }
  }, [document.querySelector(`.tooltip-text-${toggle}`)]);

  useEffect(() => {
    //@ts-ignore
    const el = String(children && children.type);
    setInline(!blockTags.find((item: string) => item === el));
    if (!trigger.includes('focus')) return;
    //@ts-ignore
    const class_name = (children?.props?.className || '').trim().replaceAll(' ', '.').replaceAll('..', '.');
    class_name &&
      document.querySelector(`.${class_name}`) &&
      //@ts-ignore
      document.querySelector(`.${class_name}`).classList.add('tooltip-children');
    let isIncluded: boolean | null = false;
    isIncluded =
      class_name &&
      ((document.querySelector(`.tooltip-${toggle} .${class_name}`) &&
        //@ts-ignore
        document.querySelector(`.tooltip-${toggle} .${class_name}`).tagName === 'TEXTAREA') ||
        (document.querySelector(`.tooltip-${toggle} .${class_name}`) &&
          //@ts-ignore
          document.querySelector(`.tooltip-${toggle} .${class_name}`).tagName === 'INPUT') ||
        (document.querySelector(`.tooltip-${toggle} .${class_name}`) &&
          //@ts-ignore
          document.querySelector(`.tooltip-${toggle} .${class_name}`).tagName === 'BUTTON') ||
        (document.querySelector(`.tooltip-${toggle} .${class_name}`) &&
          //@ts-ignore
          document.querySelector(`.tooltip-${toggle} .${class_name}`).tagName === 'SELECT'));

    const elem =
      class_name &&
      (isIncluded
        ? document.querySelector(`.tooltip-${toggle} .${class_name}`)
        : document.querySelector(`.tooltip-${toggle} .${class_name} input`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} textarea`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} select`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} button`));

    elem &&
      elem.addEventListener('focus', () => {
        const a = document.getElementById(`tooltip-hover-${toggle}`);
        if (a) {
          a.classList.add('d-block');
        }
      });
    elem &&
      elem.addEventListener('blur', () => {
        const a = document.getElementById(`tooltip-hover-${toggle}`);
        if (a) {
          a.classList.remove('d-block');
        }
      });
  }, [children, toggle]);

  const handleClick = useCallback(() => {
    isOnlyClick && setShow(!show);
  }, [isOnlyClick, show]);

  const getClasses = useCallback(() => {
    let classes = '';
    trigger.forEach(item => {
      classes += `${item} `;
    });
    return classes;
  }, [trigger]);
  //!-------------------------------

  // let emojis = ['💎', '💡', '🚀', '✨', '💥', '⚡️'];

  useEffect(() => {
    if (whatsNewId) {
      const whatsNewClosed = localStorage.getItem(whatsNewId);
      isMobile ? setShowWhatsNew(false) : setShowWhatsNew(!whatsNewClosed);
    }
  }, [whatsNewId, isMobile]);

  const handleCloseWhatsNew = () => {
    if (whatsNewId) {
      setShowWhatsNew(false);
      localStorage.setItem(whatsNewId, 'closed');
    }
  };

  useEffect(() => {
    if (handleCloseFromParent) {
      handleCloseWhatsNew();
    }
  }, [handleCloseFromParent]);

  return (
    <div
      className={`tooltip-wrapper tooltip-${toggle} ${whatsNewId === 'outlook' || whatsNewId === 'dashboard-v2' || whatsNewId === 'campaign-stats-v2' ? '' : 'position-relative'} ${className ? className : ''} ${
        !isOnlyClick && inline ? 'd-inline' : ''
      } ${getClasses() ? getClasses() : ''} `}
      onClick={handleClick}
      ref={selectRef}
    >
      {text || showWhatsNew ? (
        <div
          id={`tooltip-hover-${toggle}`}
          className={`position-absolute ${
            isOnlyClick ? 'tooltip-click' : isHover ? 'tooltip-hover' : 'd-none'
          }  tooltip-hover-${placement} ${
            text && (oneLine ? '' : typeof text === 'string' && text.length > (isSmall ? maxLengthInRow || 50 : 120))
              ? 'no-wrap'
              : ''
          } ${inline ? 'inline' : ''} ${content ? 'content' : ''} ${!show ? 'not-show' : ''} ${
            isSmall && text && typeof text === 'string' && text.length > (maxLengthInRow || 50) ? 'top_more' : ''
          } ${text || showWhatsNew ? '' : 'no-background'} ${whatsNewId ? 'd-block color-new' : ''}`}
          style={{ ...style, marginTop: marginTop || '', top: top, right: right, left: leftLocation}}
          onClick={eventStop}
        >
          {((isOnlyClick && show) || !isOnlyClick) &&
            (!showWhatsNew ? (
              <>
                {text && <div className={`tooltip-text tooltip-text-${toggle}`}>{text}</div>}
                {element && <div className={`tooltip-text tooltip-text-${toggle}`}>{element}</div>}
                {content && (
                  <div className="content-wrapper">
                    <h3 className="title">{content.title}</h3>
                    <div className="content">{content.text}</div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <div className="whats-new-header">
                  <span className="whats-new-title">What's new? {whatsNewIcon}</span>
                  <button className="cross-icon" onClick={handleCloseWhatsNew}>
                    {closeIcon}
                  </button>
                </div>
                <div>
                  {whatsNewMessage && (
                    <span className={`whats-new-message tooltip-message-${toggle}`}>{whatsNewMessage}</span>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default memo(Tooltip);
